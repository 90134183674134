import axios, { HttpStatusCode } from "axios";
import { ApiEndpoints } from "../../config/constants";
import { ResponseModel } from "../../models/Response";
import { dashboardStore } from "../../app/stores/Dashboard";
import { AvgOrderValue, OrderStatusCount, TotalOrders } from "../../models/DB";
import { OrderStatus as OrderStatusEnum } from "../../models/enums/OrderStatus";

// Define interface for revenue data
interface RevenueDataPoint {
    day: string;
    daily_sales: number;
    daily_orders: number;
}

export const DashboardService = () => {
    // Get the state updaters from the dashboard store
    const setOrderStatuses = dashboardStore((state) => state.setOrderStatuses);
    const setAvgOrderValue = dashboardStore((state) => state.setAvgOrderValue);
    const setTotalOrders = dashboardStore((state) => state.setTotalOrders);
    const setTotalSales = dashboardStore((state) => state.setTotalSales);
    const selectedDateRange = dashboardStore((state) => state.selectedDateRange);

    /**
     * Get all order statuses and their counts
     */
    const getOrderStatuses = async () => {
        try {
            const response = await axios.get<ResponseModel<OrderStatusCount[]>>(ApiEndpoints.Dashboard.GetOrderStatuses);
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                setOrderStatuses(data);
                return data;
            }
            
            console.error("Error fetching order statuses:", errorMessage);
            return [];
        } catch (error) {
            console.error("Error fetching order statuses:", error);
            return [];
        }
    };

    /**
     * Get average order value for a date range
     * @param start - Start date in YYYY-MM-DD format
     * @param end - End date in YYYY-MM-DD format
     */
    const getAvgOrderValue = async (start?: string, end?: string) => {
        const startDate = start || selectedDateRange.startDate;
        const endDate = end || selectedDateRange.endDate;
        
        try {
            const response = await axios.get<ResponseModel<AvgOrderValue>>(
                ApiEndpoints.Dashboard.GetAvgOrderValue(startDate, endDate)
            );
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                setAvgOrderValue(data);
                return data;
            }
            
            console.error("Error fetching average order value:", errorMessage);
            return null;
        } catch (error) {
            console.error("Error fetching average order value:", error);
            return null;
        }
    };

    /**
     * Get total number of orders for a date range
     * @param start - Start date in YYYY-MM-DD format
     * @param end - End date in YYYY-MM-DD format
     */
    const getTotalOrders = async (start?: string, end?: string) => {
        const startDate = start || selectedDateRange.startDate;
        const endDate = end || selectedDateRange.endDate;
        
        try {
            const response = await axios.get<ResponseModel<TotalOrders>>(
                ApiEndpoints.Dashboard.GetTotalOrders(startDate, endDate)
            );
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                setTotalOrders(data);
                return data;
            }
            
            console.error("Error fetching total orders:", errorMessage);
            return null;
        } catch (error) {
            console.error("Error fetching total orders:", error);
            return null;
        }
    };

    /**
     * Get total sales amount for a date range
     * @param start - Start date in YYYY-MM-DD format
     * @param end - End date in YYYY-MM-DD format
     */
    const getTotalSales = async (start?: string, end?: string) => {
        const startDate = start || selectedDateRange.startDate;
        const endDate = end || selectedDateRange.endDate;
        
        try {
            const response = await axios.get<ResponseModel<number>>(
                ApiEndpoints.Dashboard.GetTotalSales(startDate, endDate)
            );
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                setTotalSales(data);
                return data;
            }
            
            console.error("Error fetching total sales:", {errorMessage, statusCode});
            return null;
        } catch (error) {
            console.error("Error fetching total sales:", error);
            return null;
        }
    };

    /**
     * Get all dashboard data at once
     * @param start - Start date in YYYY-MM-DD format
     * @param end - End date in YYYY-MM-DD format
     */
    const getDashboardData = async (start?: string, end?: string) => {
        const startDate = start || selectedDateRange.startDate;
        const endDate = end || selectedDateRange.endDate;
        
        try {
            await Promise.all([
                getOrderStatuses(),
                getAvgOrderValue(startDate, endDate),
                getTotalOrders(startDate, endDate),
                getTotalSales(startDate, endDate),
                getRevenueData(endDate)
            ]);
            return true;
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
            return false;
        }
    };

    /**
     * Get the count of orders for a specific status
     * @param status - The order status to count
     */
    const getOrderCountByStatus = (status: OrderStatusEnum) => {
        const orderStatuses = dashboardStore.getState().orderStatuses;
        const statusObj = orderStatuses.find(s => s.status === status);
        return statusObj ? statusObj.status_count : 0;
    };

    /**
     * Get daily revenue and orders data for a month
     * @param endDate - End date in YYYY-MM-DD format which defines the month
     */
    const getRevenueData = async (endDate?: string) => {
        const dateToUse = endDate || selectedDateRange.endDate;
        
        try {
            const response = await axios.get<ResponseModel<RevenueDataPoint[]>>(
                `${ApiEndpoints.Dashboard.GetRevenueData}?start_date=${dateToUse}`
            );
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                // Store the data in the dashboard store
                dashboardStore.getState().setRevenueData(data);
                return data;
            }
            
            console.error("Error fetching revenue data:", errorMessage);
            return [];
        } catch (error) {
            console.error("Error fetching revenue data:", error);
            return [];
        }
    };

    return {
        getOrderStatuses,
        getAvgOrderValue,
        getTotalOrders,
        getTotalSales,
        getDashboardData,
        getOrderCountByStatus,
        getRevenueData
    };
}; 