import { Typography, useTheme } from "@mui/material";
import { View } from "react-native";
import { CURRENCY } from "../config/constants";
import { calculateDiscountedPrice } from "../utils/customFunctions";

interface ProductPriceProps {
    price: number;
    discount?: number;
    mainSize?: number;
    secondarySize?: number;
    direction?: "row" | "column";
}

export const ProductPrice = ({
    price,
    discount = 0,
    mainSize = 14,
    secondarySize = 12,
    direction = "row"
}: ProductPriceProps) => {
    const theme = useTheme();
    const hasDiscount = discount > 0;
    const finalPrice = calculateDiscountedPrice(price, discount);

    return (
        <View style={{
            flexDirection: direction === "row" ? "row" : "column",
            alignItems: direction === "row" ? "center" : "flex-start",
        }}>
            <Typography
                variant={mainSize > 16 ? "h5" : "body2"}
                sx={{
                    fontSize: mainSize,
                    fontWeight: "bold",
                    letterSpacing: 0.5,
                    color: theme.palette.success.main,
                }}
            >
                {CURRENCY} {finalPrice}
            </Typography>

            {hasDiscount && (
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: secondarySize,
                        fontWeight: "bold",
                        letterSpacing: 0.5,
                        color: theme.palette.text.disabled,
                        marginLeft: direction === "row" ? 0.5 : 0,
                        textDecorationLine: "line-through",
                    }}
                >
                    {CURRENCY} {price.toFixed(2)}
                </Typography>
            )}
        </View>
    );
}; 