import { Collapse, Grid, Slide, useTheme } from "@mui/material";
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { ActivityIndicator, Animated, Image, ImageBackground, ScrollView, StyleSheet, View, Platform } from "react-native";
import { Categories, productStore } from "../../../app/stores/Product";
import { ProductsLayout } from "../../../components/ProductsLayout";
import { ProductsService } from "../../../services/supabase/ProductsService";
import { primaryColor } from "../../../config/constants";
import { CategoriesFilterScroller, CategoriesFilterSideMenu } from "./components/CategoryFilters";
import { navigationStore } from "../../../app/stores/Navigation";
import { useIsFocused } from "@react-navigation/native";
import { Footer } from "../../../components/enhancedfooter/Footer";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { isLarge } from "../../../navigation/HomeStack";

export const Shop = ({ navigation }) => {
	const productsService = ProductsService();
	const [categoriesAnimation] = useState(new Animated.Value(0));
	const [introImageAnimation] = useState(new Animated.Value(0));
	const [productsAnimation] = useState(new Animated.Value(0));
	const [marginTopAnimation] = useState(new Animated.Value(0));
	const [selectedCategories, setSelectedCategories] = useState<Categories[]>([]);
	const [categoriesTransition, setCategoriesTransition] = useState(false);

	const { updateShowNavigationBar } = navigationStore((state) => state);
	const { allProducts, allCategories } = productStore((state) => state);
	const darkMode = appConfigStore((state) => state.darkMode);

	const paddingX = 4;
	const scrollViewRef = useRef<ScrollView>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [_isCategoryChanging, setIsCategoryChanging] = useState(false);
	const isFocused = useIsFocused();
	const theme = useTheme();
	const [_imagePreloaded, setImagePreloaded] = useState(false);

	const isAllCategory = useMemo(() => {
		if (selectedCategories.length === 0) return false;
		return typeof selectedCategories[0] === "string"
			? selectedCategories[0] === "All"
			: selectedCategories[0].category === "All";
	}, [selectedCategories]);

	// Animate margin top when category changes
	useEffect(() => {
		Animated.timing(marginTopAnimation, {
			toValue: isAllCategory ? 1 : 0,
			duration: 600,
			useNativeDriver: false
		}).start();
	}, [isAllCategory, marginTopAnimation]);

	useEffect(() => {
		scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
	}, [selectedCategories])

	useEffect(() => {
		productsService.getAllProducts();
	}, []);

	useEffect(() => {
		if (isFocused) {
			updateShowNavigationBar(true);
		}
	}, [isFocused]);

	useEffect(() => {
		if (allCategories.length > 0) {
			setSelectedCategories([allCategories[0].category as unknown as Categories]);
			fadeIn();
		}
	}, [allCategories]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCategoriesTransition(isAllCategory);
		}, 300);

		return () => clearTimeout(timer);
	}, [isAllCategory]);

	const handleCategoryChange = useCallback((categories: Categories[]) => {
		setIsCategoryChanging(true);
		setSelectedCategories(categories);
	}, []);

	useEffect(() => {
		try {
			if (Platform.OS === "web") {
				const img = new window.Image();
				img.onload = () => setImagePreloaded(true);
				img.onerror = () => {
					console.error("Failed to preload image on web");
					setImagePreloaded(true);
				};
				img.src = require("../../../assets/bg-texture-paint.jpg");
			} else {
				Image.prefetch(Image.resolveAssetSource(require("../../../assets/bg-texture-paint.jpg")).uri)
					.then(() => setImagePreloaded(true))
					.catch(error => {
						console.error("Failed to preload image:", error);
						setImagePreloaded(true);
					});
			}
		} catch (error) {
			console.error("Failed to preload image:", error);
			setImagePreloaded(true);
		}
	}, []);

	const fadeIn = useCallback(() => {
		return Animated.timing(categoriesAnimation, {
			toValue: 1,
			duration: 600,
			delay: 250,
			useNativeDriver: false,
		}).start(() => {
			setIsLoading(false);
			Animated.timing(introImageAnimation, {
				toValue: 1,
				duration: 600,
				delay: 0,
				useNativeDriver: false,
			}).start(() => {
				Animated.timing(productsAnimation, {
					toValue: 1,
					duration: 600,
					delay: 0,
					useNativeDriver: false,
				}).start();
			});
		});
	}, [categoriesAnimation, introImageAnimation, productsAnimation]);

	const getProductsLength = useCallback((category) => {
		if (category === "All") return allProducts.length;
		return allProducts.filter((product) => product.category === category).length;
	}, [allProducts]);

	const CategoryProducts = useMemo(() => ({ category }) => {
		const filteredProducts = useMemo(() => {
			return category === "All"
				? allProducts
				: allProducts.filter((product) => product.category === category);
		}, [category, allProducts]);

		return (
			<React.Fragment key={category}>
				<Animated.View style={{
					alignItems: "center",
					opacity: productsAnimation
				}}>
					<Grid
						container
						flexDirection="row"
						maxWidth={"xl"}
						sx={{
							paddingX: { xs: 2, md: paddingX },
							alignContent: { xs: "center" },
						}}
						marginTop={4}
						spacing={1}
					>
						<CategoriesFilterSideMenu
							scrollViewRef={scrollViewRef}
							allCategories={allCategories}
							selectedCategories={selectedCategories}
							setSelectedCategories={handleCategoryChange}
							getProductsLength={getProductsLength}
						/>
						<Grid
							item
							xs={12}
							md={9}
							sx={{
								flexGrow: 1
							}}
						>
							<ProductsLayout
								selectedCategory={category}
								products={filteredProducts}
								navigation={navigation}
								onLoadingComplete={() => setIsCategoryChanging(false)}
							/>
						</Grid>
					</Grid>
				</Animated.View>
			</React.Fragment >
		);
	}, [allCategories, selectedCategories, getProductsLength, allProducts, navigation, productsAnimation, handleCategoryChange]);

	const IntroImage = useMemo(() => () => {
		return (
			<Slide
				direction="down"
				in={isAllCategory}
				timeout={{
					enter: 600,
					exit: 600
				}}
				mountOnEnter
				unmountOnExit
			>
				<div>
					<Collapse
						mountOnEnter
						unmountOnExit
						easing={{
							enter: "ease-in-out",
							exit: "ease-in-out"
						}}
						in={isAllCategory}
						timeout={"auto"}
						sx={{ backgroundColor: darkMode ? theme.customColors.background : theme.customColors.cardBackground }}
					>
						<Grid
							container
							marginTop={4}
							marginBottom={3}
						>
							<Grid
								item
								flex={1}
								marginX={{ xs: 2, md: paddingX }}
								boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.1)"}
								borderRadius={4}
								overflow={"hidden"}
								sx={{ aspectRatio: { xs: 1 / 0.38, sm: 1 / 0.30, md: 1 / 0.22, } }}
								position="relative"
								justifyContent="center"
								alignItems="center"
							>
								<ImageBackground
									source={require("../../../assets/background-video.gif")}
									resizeMode="cover"
									style={{
										width: "100%",
										height: "100%",
										backgroundColor: theme.customColors.background,
										justifyContent: "center",
									}}
								>
									<View style={{
										position: "absolute",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignItems: "center",
										zIndex: 2,
										opacity: 0.3,
										backgroundColor: "black"
									}} />
									<View style={{
										position: "absolute",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignItems: "center",
										zIndex: 3
									}}>
										<Image
											source={require("../../../assets/overlay-logo.png")}
											style={{
												width: isLarge() ? "26%" : "52%",
												aspectRatio: 1,
												resizeMode: "contain",
											}}
										/>
									</View>
								</ImageBackground>
							</Grid>
						</Grid>
					</Collapse>
				</div>
			</Slide>
		);
	}, [isAllCategory, paddingX, darkMode, theme.customColors]);

	return (
		<>
			{isLoading ? (
				<View style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: theme.customColors.background
				}}>
					<ActivityIndicator
						size="large"
						color={primaryColor}
					/>
				</View>
			) : (
					<Animated.ScrollView
						ref={scrollViewRef}
						style={{ backgroundColor: theme.customColors.background }}
						contentContainerStyle={styles.background}
						stickyHeaderIndices={[1]}
						stickyHeaderHiddenOnScroll={false}
						scrollEventThrottle={16}
						scrollToOverflowEnabled={true}>
						<IntroImage />
						<div style={{
							position: 'relative',
							zIndex: 10,
							transition: 'opacity 700ms cubic-bezier(0.4, 0, 0.2, 1), margin 700ms cubic-bezier(0.4, 0, 0.2, 1)',
							marginTop: categoriesTransition ? '0px' : '-4px',
						}}>
							<CategoriesFilterScroller
								scrollViewRef={scrollViewRef}
								allCategories={allCategories}
								selectedCategories={selectedCategories}
								setSelectedCategories={handleCategoryChange}
								getProductsLength={getProductsLength}
							/>
						</div>
						{/* Products container */}
						<Animated.View style={{
							...styles.container,
							backgroundColor: theme.customColors.background,
							marginTop: marginTopAnimation.interpolate({
								inputRange: [0, 1],
								outputRange: [-20, 0]
							})
						}}>
							{selectedCategories.map((category, index) => (
								<CategoryProducts
									key={`${category}-${index}`}
									category={category}
								/>
							))}
						</Animated.View>
						<Footer />
					</Animated.ScrollView>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	background: {
		backgroundColor: "transparent",
	},
	container: {
		flex: 1,
		backgroundColor: "transparent",
	},
	title: {
		flex: 1,
		fontSize: 26,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 1,
		color: "black",
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	headerTitle: {
		flex: 1,
		fontSize: 48,
		fontWeight: "800",
		fontFamily: "primaryFont",
		letterSpacing: 1.5,
		color: "white",
	},
	categoryTitle: {
		fontSize: 48,
		fontWeight: "800",
		fontFamily: "primaryFont",
		letterSpacing: 1.5,
		color: "white",
	},
	chipContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 10,
	},
	chip: {
		letterSpacing: 0.5,
		fontFamily: "primaryFont",
		fontWeight: "600",
		minHeight: 40,
		marginRight: 8,
		borderRadius: 20,
		borderWidth: 2,
	},
});