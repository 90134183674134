import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { appConfigStore } from "../app/stores/AppConfig";
import { useState } from "react";

interface ThemeToggleProps {
    variant?: "navbar" | "drawer";
}

export const ThemeToggle = ({ variant = "navbar" }: ThemeToggleProps) => {
    const theme = useTheme();
    const { darkMode, setShowOverlay } = appConfigStore((state) => state);
    const [hovered, setHovered] = useState(false);

    const isDrawer = variant === "drawer";

    const handleToggle = () => {
        setShowOverlay(true);
    };

    return (
        <Box
            onClick={handleToggle}
            sx={{
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                borderRadius: 20,
                overflow: "hidden",
                transition: "all 0.6s ease",
                backgroundColor: hovered ? theme.palette.action.hover : "transparent",
                pl: isDrawer ? 0 : (hovered ? 1 : 0),
                pr: isDrawer ? 0 : (hovered ? 2 : 0),
                width: isDrawer ? "100%" : "auto",
                justifyContent: isDrawer ? "space-between" : "flex-start",
                height: isDrawer ? "auto" : 40,
                py: isDrawer ? 1 : 0.5,
                cursor: "pointer",
                ...(isDrawer && { px: 3 })
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Typography
                variant={"caption"}
                sx={{
                    maxWidth: isDrawer ? "auto" : (hovered ? "120px" : "0px"),
                    opacity: isDrawer ? 1 : (hovered ? 1 : 0),
                    overflow: isDrawer ? "visible" : "hidden",
                    whiteSpace: "nowrap",
                    transition: isDrawer ? "none" : "all 0.3s ease",
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                    letterSpacing: 0.5,
                    order: isDrawer ? 0 : 1,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {darkMode ? "Enable Light Mode" : "Enable Dark Mode"}
            </Typography>

            <IconButton
                color="inherit"
                size="small"
                sx={{
                    color: theme.palette.text.primary,
                    transition: "transform 0.4s",
                    "&:hover": {
                        transform: "rotate(30deg)"
                    },
                    order: isDrawer ? 1 : 0
                }}
            >
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        </Box>
    );
}; 