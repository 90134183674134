import { Typography, Box, Grid, Stack, IconButton } from "@mui/material";
import { CompanyName, FooterAppName } from "../../config/constants";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Social Media Buttons component
const SocialMediaButtons = () => {
    return (
        <>
            {/* <IconButton
                aria-label="facebook"
                size="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => window.open("https://facebook.com", "_blank")}
            >
                <FacebookIcon fontSize="small" />
            </IconButton> */}
            {/* <IconButton
                aria-label="twitter"
                size="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => window.open("https://twitter.com", "_blank")}
            >
                <TwitterIcon fontSize="small" />
            </IconButton> */}
            <IconButton
                aria-label="instagram"
                size="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => window.open("https://www.instagram.com/toe_fade_it/")}
            >
                <InstagramIcon fontSize="small" />
            </IconButton>
            {/* <IconButton
                aria-label="linkedin"
                size="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => window.open("https://linkedin.com", "_blank")}
            >
                <LinkedInIcon fontSize="small" />
            </IconButton> */}
        </>
    );
};

// Copyright and Social Media section component
const CopyrightSection = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Box sx={{ mb: { xs: 10, md: 12 } }}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, sm: 0 }}
            >
                <Grid item xs={12} sm="auto">
                    <Typography
                        variant="body2"
                        fontWeight={"bold"}
                        letterSpacing={.5}
                        sx={{
                            color: "#FFFFFF",
                            textAlign: { xs: "center", sm: "left" }
                        }}
                    >
                        © {currentYear} {CompanyName}. All rights reserved.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={"bold"}
                            letterSpacing={.5}
                            sx={{ color: "#FFFFFF", mr: 1 }}
                        >
                            Follow Us
                        </Typography>
                        <SocialMediaButtons />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

// Background text component
export const BackgroundText = () => {
    return (
        <Typography
            variant="h1"
            sx={{
                position: "absolute",
                fontSize: { xs: "90px", sm: "170px", md: "200px" },
                bottom: { xs: "-28px", sm: "-55px", md: "-70px" },
                fontWeight: 900,
                opacity: 0.10,
                color: "#fff",
                left: "50%",
                transform: "translateX(-50%)",
                letterSpacing: "2px",
                pointerEvents: "none",
                whiteSpace: "nowrap",
                zIndex: 0,
                lineHeight: 1,
                fontFamily: "logoFont",
            }}
        >
            {FooterAppName.toUpperCase()}
        </Typography>
    );
};

export const FooterBottomSection = () => {
    return (
        <CopyrightSection />
    );
}; 