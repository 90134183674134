import { Grid, Typography, useTheme } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { OrderStatus, getOrderStatusColor, getOrderStatusText } from "../models/enums/OrderStatus";

interface OrderStatusBadgeProps {
    status: OrderStatus;
}

export const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
    const theme = useTheme();

    const getStatusStyleBorder = (status: OrderStatus) => ({
        backgroundColor: getOrderStatusColor(status, theme),
        borderRadius: 6,
        padding: 8,
        paddingX: 12,
        display: "inline-block",
    });

    return (
        <Grid
            item
            style={getStatusStyleBorder(status)}
            marginY={2}
        >
            <Typography
                variant={"body2"}
                style={{ color: theme.palette.common.white, fontWeight: "bold" }}
            >
                {getOrderStatusText(status)}
            </Typography>
        </Grid>
    );
};

export const OrderStatusBadgeCompact = ({ status }: OrderStatusBadgeProps) => {
    const theme = useTheme();

    return (
        <Grid
            item
            style={{ display: "flex", alignItems: "center", gap: 6 }}
        >
            <Circle
                sx={{
                    color: getOrderStatusColor(status, theme),
                    fontSize: 12,
                }}
            />
            <Typography
                variant={"caption"}
                style={{ color: theme.customColors.textPrimary }}
            >
                {getOrderStatusText(status, true)}
            </Typography>
        </Grid>
    );
};