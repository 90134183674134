import { Box, Container, Divider } from "@mui/material";
import { primaryColor } from "../../config/constants";
import { BackgroundText, FooterBottomSection } from "./FooterBottomSection";
import { FooterTopSection } from "./FooterTopSection";

export const Footer = () => {
    return (
        <Box
            component="footer"
            mt={32}
            sx={{
                backgroundColor: primaryColor,
                py: 8,
                position: "relative",
                overflow: "hidden",
                minHeight: { xs: "400px", md: "355px" },
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
            }}
        >
            <Container maxWidth="xl" sx={{ position: "relative", zIndex: 1 }}>
                <FooterTopSection />
                <Divider sx={{ my: 2.5, opacity: 0.2, borderColor: "rgba(255,255,255,1)" }} />
                <FooterBottomSection />
            </Container>

            {/* Large background text positioned at the bottom */}
            <BackgroundText />
        </Box>
    );
};