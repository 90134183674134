import { Box, Card, Typography, useTheme } from "@mui/material";
import React from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

interface StatsCardProps {
    icon: React.ReactNode;
    title: string;
    value: string | number;
    changePercentage?: number;
    changeValue?: string | number;
    changeText?: string;
    iconBgColor?: string;
}

const StatsCard = ({
    icon,
    title,
    value,
    changePercentage,
    changeValue,
    changeText = "today",
    iconBgColor,
}: StatsCardProps) => {
    const theme = useTheme();

    const isPositive = changePercentage && changePercentage > 0;
    const isNegative = changePercentage && changePercentage < 0;
    const iconSize = 32;
    return (
        <Card
            sx={{
                p: 2.5,
                height: "100%",
                boxShadow: theme.shadows[2],
                borderRadius: theme.shape.borderRadius,
                bgcolor: theme.customColors.cardBackground,
                display: "flex",
                alignItems: "flex-start",
            }}
        >
            <Box
                sx={{
                    width: iconSize,
                    height: iconSize,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    padding: 2,
                    backgroundColor: iconBgColor || theme.palette.primary.main,
                    marginRight: 2,
                    flexShrink: 0,
                }}
            >
                {icon}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="subtitle1" color="text.secondary" fontFamily="primaryFont">
                    {title}
                </Typography>

                <Typography variant="h5" fontWeight="bold" sx={{ mb: 0.5 }} fontFamily="primaryFont">
                    {value}
                </Typography>

                {/* {changePercentage !== undefined && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="body2"
                            component="span"
                            fontFamily="primaryFont"
                            sx={{
                                color: isPositive
                                    ? "success.main"
                                    : isNegative
                                        ? "error.main"
                                        : "text.secondary",
                                display: "flex",
                                alignItems: "center",
                                mr: 1
                            }}
                        >
                            {`${Math.abs(changePercentage)}%`}
                            {isPositive && <TrendingUpIcon fontSize="small" sx={{ ml: 0.5 }} />}
                            {isNegative && <TrendingDownIcon fontSize="small" sx={{ ml: 0.5 }} />}
                        </Typography>

                        {changeValue && (
                            <Typography variant="body2" color="text.secondary" fontFamily="primaryFont">
                                {"| "}{isPositive ? "+" : ""}{changeValue} {changeText}
                            </Typography>
                        )}
                    </Box>
                )} */}
            </Box>
        </Card>
    );
};

export default StatsCard; 