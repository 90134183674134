import { Box, Typography, SxProps, Theme, useTheme } from "@mui/material";
import { getDeliveryTypeSetup } from "../utils/customFunctions";

interface DeliveryTypeBlockProps {
    deliveryType: string;
    deliveryFee?: number;
    suburb?: string;
    sx?: SxProps<Theme>;
}

export const DeliveryTypeBlock = ({ deliveryType, deliveryFee, suburb, sx = {} }: DeliveryTypeBlockProps) => {
    const theme = useTheme();
    const { backgroundColor, borderColor, textColor, text } = getDeliveryTypeSetup(deliveryType, theme);

    // Base styling that will be consistent across all usages
    const baseStyles: SxProps<Theme> = {
        border: 2,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        borderRadius: 3,
        paddingX: 2,
        paddingY: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 1px 3px rgba(0,0,0,0.1)"
    };

    // Combine base styles with any custom styles passed in
    const combinedStyles = {
        ...baseStyles,
        ...sx
    };

    return (
        <Box sx={combinedStyles}>
            <Typography
                color={textColor}
                variant="caption"
                letterSpacing={0.5}
                fontWeight="bold"
                fontFamily="primaryFont"
            >
                {deliveryFee
                    ? `${text} - R ${deliveryFee}${suburb ? ` - ${suburb}` : ""}`
                    : text
                }
            </Typography>
        </Box>
    );
}; 