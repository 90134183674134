import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Fragment, forwardRef } from "react";
import uuid from "react-native-uuid";
import { cartStore } from "../../../app/stores/Cart";
import { ContactDetails, DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { getDeliveryTypeSetup, getOrderPaymentDetails } from "../../../utils/customFunctions";
import Divider from "@mui/material/Divider";
import { primaryColor, VAT_PERCENTAGE } from "../../../config/constants";
import { Box, useTheme, SxProps, Theme } from "@mui/material";
import { Image } from "react-native";
import { DeliveryTypeBlock } from "../../../components/DeliveryTypeBlock";

interface ReviewProps {
	shippingDetails: ShippingDetails & DeliveryDetails;
	contactDetails: ContactDetails;
	ref: React.Ref<any>;
}

export const Review = forwardRef(({ shippingDetails, contactDetails }: ReviewProps, ref) => {
	const { cartItems } = cartStore();
	const theme = useTheme();

	// Address details
	const addresses = [
		shippingDetails?.addressLine1,
		shippingDetails?.addressLine2,
		shippingDetails?.city,
		shippingDetails?.region,
		shippingDetails?.zip,
		shippingDetails?.country,
	];

	const deliveryDetails = {
		deliveryType: shippingDetails?.deliveryType,
		suburb: shippingDetails?.suburb,
		deliveryFee: shippingDetails?.deliveryFee,
	};

	const { baseCost, vat, totalCost, deliveryFee } = getOrderPaymentDetails(cartItems, deliveryDetails.deliveryFee);

	return (
		<Fragment>
			<Typography
				variant="h6"
				gutterBottom
				textAlign="center"
				mt={2}
				mb={4}
				color={theme.palette.text.primary}
			>
				Let's review your order
			</Typography>
			<List
				disablePadding
			>
				<Grid
					container
					justifyContent="space-between"
					mb={2}
				>
					<Typography
						variant="body1"
						alignSelf="center"
						color={theme.palette.text.primary}
					>
						Order Summary
					</Typography>
					<DeliveryTypeBlock 
						deliveryType={deliveryDetails.deliveryType}
						deliveryFee={deliveryDetails.deliveryFee}
						suburb={deliveryDetails.suburb}
						sx={{ alignSelf: "center" }}
					/>
				</Grid>
				{cartItems.map((cartItem, index) => (
					<ListItem
						key={`${index}-${cartItem.product.code}`}
						sx={{ py: 1, px: 0, gap: 2 }}
					>
						<Typography
							variant="caption"
							sx={{
								backgroundColor: primaryColor,
								borderRadius: "50%",
								aspectRatio: 1,
								width: 20,
								height: 20,
								textAlign: "center",
								mr: 1,
								color: theme.palette.text.primary
							}}>{`${index + 1}`}</Typography>
						<Image
							source={
								cartItem.product?.images?.["thumbnail"]?.url
									? { uri: cartItem.product.images["thumbnail"].url }
									: require("../../../assets/no-image.png")
							}
							style={{
								width: 50,
								aspectRatio: 1,
								resizeMode: "contain",
								borderRadius: 10,
							}}
						/>
						<ListItemText
							primary={<Typography variant="body2" color={theme.palette.text.primary}>{cartItem.product.name}</Typography>}
							secondary={<Typography variant="caption" color={theme.palette.text.secondary}>{`Code: ${cartItem.product.code.substring(0, 6).toUpperCase()}`}</Typography>}
						/>
						{
							cartItem.product.selectedVariant && (
								<ListItemText
									sx={{ textAlign: "right" }}
									primary={<Typography variant="body2" color={theme.palette.text.primary}>{`Selected ${cartItem.product.variantType}`}</Typography>}
									secondary={<Typography variant="body2" color={theme.palette.text.secondary}>{`${cartItem.product.variantType}: ${cartItem.selectedVariant}`}</Typography>}
								/>
							)
						}
						<ListItemText
							sx={{ textAlign: "right" }}
							primary={<Typography variant="body2" color={theme.palette.text.primary}>{`R${cartItem.product.price} x ${cartItem.quantity}`}</Typography>}
							secondary={<Typography variant="caption" color={theme.palette.text.secondary}>{`R ${(cartItem.product.price * cartItem.quantity).toFixed(2)}`}</Typography>}
						/>
					</ListItem>
				))}
				<Divider sx={{ my: 2 }} />
				<>
					<Grid container justifyContent="space-between" mt={1}>
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							Order Cost:
						</Typography>
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							R {baseCost.toFixed(2)}
						</Typography>
					</Grid>
					<Grid container justifyContent="space-between">
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							Delivery Fee:
						</Typography>
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							R {deliveryFee}.00
						</Typography>
					</Grid>
					<Grid container justifyContent="space-between">
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							VAT ({VAT_PERCENTAGE}%):
						</Typography>
						<Typography
							variant="caption"
							letterSpacing={0.5}
							fontFamily="primaryFont"
							color={theme.palette.text.secondary}
						>
							R {vat.toFixed(2)}
						</Typography>
					</Grid>
				</>

				<Grid container justifyContent="space-between" mt={1}>
					<Typography
						variant="body1"
						fontWeight="bold"
						color={theme.palette.text.primary}
					>
						Total
					</Typography>
					<Typography
						variant="body1"
						fontWeight="bold"
						color={theme.palette.text.primary}
					>
						R {totalCost.toFixed(2)}
					</Typography>
				</Grid>

				<Divider sx={{ my: 2 }} />
			</List>

			{/* Shipping and Contact Information - Side by Side */}
			<Grid
				container
				direction="row"
				mt={4}
			>
				{/* Left column - Shipping details */}
				<Grid item xs={12} sm={6}>
					<Typography
						variant="body1"
						gutterBottom
						sx={{ mb: 2 }}
						color={theme.palette.text.primary}
					>
						Shipping
					</Typography>
					{addresses.map((address, index) => (
						address && (
							<Typography
								key={`${index}-${uuid.v4()}`}
								gutterBottom
								variant="body2"
								color={theme.palette.text.secondary}
							>
								{address}
							</Typography>
						)
					))}
				</Grid>

				{/* Right column - Contact details */}
				<Grid item xs={12} sm={6}>
					<Typography
						variant="body1"
						gutterBottom
						sx={{ mb: 2 }}
						color={theme.palette.text.primary}
					>
						Contact Details
					</Typography>
					<Typography variant="body2" gutterBottom color={theme.palette.text.secondary}>
						{`${contactDetails?.firstName} ${contactDetails?.lastName}`}
					</Typography>
					<Typography variant="body2" gutterBottom color={theme.palette.text.secondary}>
						{`Email: ${contactDetails?.email}`}
					</Typography>
					<Typography variant="body2" gutterBottom color={theme.palette.text.secondary}>
						{`Mobile Number: ${contactDetails?.contactNo}`}
					</Typography>
				</Grid>
			</Grid>
		</Fragment>
	);
});
