import { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet } from "react-native";
import { Grid, Typography, useTheme } from "@mui/material";
import { AddShoppingCartOutlined, ArrowBack } from "@mui/icons-material";
import { BackHandler } from "react-native";
import { ProductsService } from "../../services/supabase/ProductsService";
import { Product } from "../../models/DB";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { cartStore } from "../../app/stores/Cart";
import { userStore } from "../../app/stores/User";
import { VariantSelector } from "../../components/VariantSelector";
import { BasicButton } from "../../components/BasicButton";
import { primaryColor, stateErrorColor } from "../../config/constants";
import { ImagePreviewer } from "../../components/ImagePreviewer";
import { navigationStore } from "../../app/stores/Navigation";
import { useIsFocused } from "@react-navigation/native";
import { appConfigStore } from "../../app/stores/AppConfig";
import { ProductPrice } from "../../components/ProductPrice";

export const ProductDetail = ({ route, navigation }) => {
	const [selectedVariant, setSelectedVariant] = useState<string>();
	const [loading, setLoading] = useState(true);
	const [product, setProduct] = useState<Product>();
	const productsService = ProductsService();
	const darkMode = appConfigStore((state) => state.darkMode);
	const theme = useTheme();

	const { updateShowNavigationBar } = navigationStore((state) => state);
	const isFocused = useIsFocused();

	const { addToCart, updateCartItemQuantity, removeFromCart, cartItems } = cartStore((state) => state);
	const { authenticated } = userStore((state) => state.user);

	const isVariantInCart = product && cartItems.some(item => item.product.code === product.code && item.selectedVariant === selectedVariant);
	const cartQuantity = isVariantInCart ? cartItems.find(item => item.product.code === product.code && item.selectedVariant === selectedVariant)?.quantity : 0;

	const params = route.params;

	useEffect(() => {
		const backAction = () => {
			navigation.navigate(ScreenComponent.Shop);
			return true;
		};
		const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);
		return () => backHandler.remove();
	}, [navigation]);

	useEffect(() => {
		if (params && params.id)
			fetchProductById(params.id)
		else
			setLoading(false);
	}, [params]);

	useEffect(() => {
		if (isFocused) {
			updateShowNavigationBar(true);
		}
	}, [isFocused]);

	const fetchProductById = async (productId: string) => {
		setLoading(true);
		const product = await productsService.getProductById(productId);
		if (product)
			setProduct(product);

		setLoading(false);
	}

	const handleAddToCart = () => {
		if (product) {
			addToCart(product, selectedVariant);
		}
	};

	const handleUpdateCartQuantity = (quantityChange) => {
		if (product) {
			const cartItem = cartItems.find(item => item.product.code === product.code && item.selectedVariant === selectedVariant);
			if (cartItem) {
				updateCartItemQuantity(product.code, cartItem.quantity + quantityChange, selectedVariant);
			}
		}
		// } else {
		// 	console.log("User not authenticated");
		// 	// Optionally, navigate to login screen or show a message
		// }
	};

	const handleRemoveFromCart = () => {
		if (product)
			removeFromCart(product.code, selectedVariant);
		// } else {
		// 	console.log("User not authenticated");
		// 	// Optionally, navigate to login screen or show a message
		// }
	};

	const QuantityController = ({
		isVariantInCart,
		cartQuantity,
		handleRemoveFromCart,
		handleUpdateCartQuantity,
		handleAddToCart,
	}) => (
		<Grid mt={8}>
			{product?.inStock ? (
				isVariantInCart ? (
					<Grid>
						<Grid mb={2.5}>
							<Typography variant="body2" color={"orange"} letterSpacing={1}>
								Product already added. You can still update the quantity.
							</Typography>
						</Grid>
						<Grid
							container
							style={{
								flexDirection: "row",
								alignItems: "center"
							}}
							sx={{
								maxWidth: "380px",
								justifyContent: "space-between"
							}}
						>
							{cartQuantity === 1 ? (
								<BasicButton
									variant="contained"
									title="Remove"
									onClick={handleRemoveFromCart}
									sx={{
										paddingX: { xs: 3, md: 5 },
										backgroundColor: stateErrorColor,
										width: 100,
									}}
								/>
							) : (
								<BasicButton
									variant="contained"
									title="-"
									onClick={() => handleUpdateCartQuantity(-1)}
									disabled={cartQuantity <= 1}
									style={{
										fontSize: 18,
									}}
									sx={{
										paddingX: { xs: 3, md: 5 },
										width: 100,
									}}
								/>
							)}
							<Typography
								variant="body1"
								sx={{
									marginX: "22px",
									border: "1px solid",
									borderColor: theme.customColors.border,
									borderRadius: "8px",
									paddingX: "18px",
									paddingY: "9px",
									color: theme.palette.text.primary
								}}
							>
								{cartQuantity}
							</Typography>
							<BasicButton
								variant="contained"
								title="+"
								onClick={() => handleUpdateCartQuantity(1)}
								disabled={!!(product?.variantType && !selectedVariant)}
								style={{
									fontSize: 18,
								}}
								sx={{
									paddingX: { xs: 3, md: 5 },
									width: 100,
								}}
							/>
						</Grid>
					</Grid>
				) : (
					<BasicButton
						variant="contained"
						title="Add to Cart"
						onClick={handleAddToCart}
						disabled={!!(product?.variantType && !selectedVariant)}
						sx={{
							paddingX: 5,
							paddingY: 1.5,
							fontSize: 12,
						}}
							style={{
								backgroundColor: !!(product?.variantType && !selectedVariant)
									? "lightgrey" : primaryColor,
							}}
							startIcon={<AddShoppingCartOutlined />}
					/>
				)
			) : (
					<Typography
						variant="body2"
						color={theme.palette.text.secondary}
						fontWeight={"bold"}
						fontFamily={"primaryFont"}
						letterSpacing={1}
					>
						{product?.inStock ? `Please log in to add items to the cart.` : ``}
				</Typography>
			)}
		</Grid>
	);

	return (
		<ScrollView
			style={{
				backgroundColor: theme.customColors.background,
			}}
			contentContainerStyle={{
				...styles.container,
			}}
			stickyHeaderIndices={[0]}
		>
			<Grid container m={1} mt={2}>
				<BasicButton
					title={"Back"}
					variant="contained"
					startIcon={<ArrowBack />}
					sx={{
						borderRadius: 20,
						fontSize: 12,
						fontFamily: "primaryFont",
						boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)"
					}}
					onClick={() => navigation.navigate(ScreenComponent.Shop)}
				/>
			</Grid>
			{loading ? (
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{
						...styles.spinnerContainer,
						backgroundColor: theme.customColors.background
					}}
				>
					<ActivityIndicator size="large" color={primaryColor} />
				</Grid>
			) : (
				product ? (
					<Grid container maxWidth={"lg"} alignSelf={"center"} pt={{ xs: 2, md: 12 }} pb={30}>
						<Grid item xs={12} md={6}>
								<ImagePreviewer images={product.images as unknown as { [key: string]: { url: string } }} />
						</Grid>
							<Grid item marginX={{ xs: 2, md: 0 }} xs={12} md={6} mt={{ xs: 10, md: 2 }}>
								<Typography
									variant="h4"
									fontFamily={"primaryFont"}
									letterSpacing={1}
									fontWeight={"bold"}
									color={theme.palette.text.primary}
								>
									{product.name}
								</Typography>
								<Typography
									variant="body2"
									gutterBottom
									color={theme.customColors.textMuted}
									fontFamily={"primaryFont"}
									fontWeight={"bold"}
									letterSpacing={1}
								>
									Code: {product.code.substring(0, 6).toUpperCase()}
								</Typography>
								{
									product.discount > 0 && product.inStock && (
										<Grid container>
											<Typography
												mt={2}
												fontSize={13}
												fontWeight={"bold"}
												fontFamily={"primaryFont"}
												letterSpacing={1}
												px={1.5}
												py={0.5}
												color={"white"}
												border={2}
												borderRadius={2}
												style={{ backgroundColor: stateErrorColor }}
											>
												{`On Sale`}
											</Typography>
										</Grid>
									)
								}
								<Grid container my={2}>
									<ProductPrice
										price={product.price}
										discount={product.discount}
										mainSize={product.discount > 0 ? 22 : 22}
										secondarySize={18}
										direction={product.discount > 0 ? "row" : "row"}
									/>
								</Grid>
								<Typography
									variant="body1"
									sx={{
										display: product.inStock ? "none" : "flex",
										color: stateErrorColor,
										mb: 2,
										fontFamily: "primaryFont",
										letterSpacing: 1,
										fontWeight: "bold"
									}}
								>
									{"Out of Stock"}
								</Typography>
								{
									product.variantType && product.inStock && (
										<VariantSelector
											variantType={product.variantType}
											variantOptions={product.variantOptions}
											selectedVariant={selectedVariant}
											onSelectVariant={(variant) => setSelectedVariant(variant)}
											darkMode={darkMode}
										/>
									)
								}
								<QuantityController
									isVariantInCart={isVariantInCart}
									cartQuantity={cartQuantity}
									handleRemoveFromCart={handleRemoveFromCart}
									handleUpdateCartQuantity={handleUpdateCartQuantity}
									handleAddToCart={handleAddToCart}
								/>
						</Grid>
					</Grid>
					) : (
					<Grid container maxWidth={"xs"} justifyContent={"center"} pt={{ xs: 2, md: 12 }} pb={30}>
								<Typography
									variant="body1"
									color={theme.palette.text.primary}
								>
									Product not found
								</Typography>
					</Grid>
						)
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 14,
	},
	image: {
		width: "80%",
		aspectRatio: 1 / 1,
		resizeMode: "cover",
		borderRadius: 32,
		alignSelf: "center",
		borderWidth: 2,
		borderColor: "#ececec",
	},
	spinnerContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	strikeThroughPrice: {
		textDecorationLine: "line-through",
	},
	discountedPrice: {
		color: "green",
	},
});
