import React from "react";
import { Box, Typography, Button, useTheme, Grid, Paper } from "@mui/material";

/**
 * This component demonstrates how to use the MUI theme in the application
 * using the useTheme hook.
 */
export const ThemeExample = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: 3,
                backgroundColor: theme.customColors.background,
                color: theme.palette.text.primary,
                minHeight: "100vh",
            }}
        >
            <Typography variant="h4" gutterBottom fontFamily="primaryFont" fontWeight="bold">
                Theme Usage Example
            </Typography>

            <Typography variant="body1" paragraph>
                This component demonstrates how to use the MUI theme with the useTheme hook.
                All colors come from the theme, making it easy to maintain consistent styling across the app.
            </Typography>

            <Box sx={{ marginY: 4 }}>
                <Typography variant="h6" gutterBottom color={theme.palette.primary.main} fontWeight="bold">
                    Text Colors from Theme
                </Typography>
                <Typography variant="body1" color={theme.palette.text.primary} paragraph>
                    Primary Text - theme.palette.text.primary
                </Typography>
                <Typography variant="body1" color={theme.palette.text.secondary} paragraph>
                    Secondary Text - theme.palette.text.secondary
                </Typography>
                <Typography variant="body1" color={theme.palette.primary.main} paragraph>
                    Primary Color - theme.palette.primary.main
                </Typography>
            </Box>

            <Box sx={{ marginY: 4 }}>
                <Typography variant="h6" gutterBottom color={theme.palette.primary.main} fontWeight="bold">
                    Custom Colors from Theme
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            p: 2,
                            backgroundColor: theme.customColors.background,
                            border: `1px solid ${theme.customColors.border}`,
                            borderRadius: 1
                        }}>
                            <Typography variant="body2" fontWeight="bold">
                                Background: theme.customColors.background
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            p: 2,
                            backgroundColor: theme.customColors.backgroundSecondary,
                            border: `1px solid ${theme.customColors.border}`,
                            borderRadius: 1
                        }}>
                            <Typography variant="body2" fontWeight="bold">
                                Background Secondary: theme.customColors.backgroundSecondary
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            p: 2,
                            backgroundColor: theme.palette.primary.main,
                            color: "#fff",
                            borderRadius: 1
                        }}>
                            <Typography variant="body2" fontWeight="bold">
                                Primary: theme.palette.primary.main
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ marginY: 4 }}>
                <Typography variant="h6" gutterBottom color={theme.palette.primary.main} fontWeight="bold">
                    Buttons with Theme Colors
                </Typography>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.dark,
                                }
                            }}
                        >
                            Primary Button
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: theme.customColors.border,
                                color: theme.palette.text.primary,
                                "&:hover": {
                                    backgroundColor: theme.customColors.backgroundSecondary,
                                    borderColor: theme.palette.primary.main,
                                }
                            }}
                        >
                            Outlined Button
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text"
                            sx={{
                                color: theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: theme.customColors.backgroundSecondary,
                                }
                            }}
                        >
                            Text Button
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Paper
                elevation={2}
                sx={{
                    p: 3,
                    mt: 4,
                    backgroundColor: theme.customColors.backgroundSecondary,
                    borderRadius: 2,
                    border: `1px solid ${theme.customColors.border}`
                }}
            >
                <Typography variant="h6" color={theme.palette.primary.main} fontWeight="bold" gutterBottom>
                    Card with Theme Colors
                </Typography>
                <Typography variant="body1" paragraph>
                    This card uses theme colors for background, border, and text. Using the theme
                    ensures consistency across the application.
                </Typography>
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                >
                    Learn More
                </Button>
            </Paper>
        </Box>
    );
}; 