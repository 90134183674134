import { AddCircle } from "@mui/icons-material";
import { Container, Grid, Box, CircularProgress, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { productStore } from "../../../../app/stores/Product";
import { BasicButton } from "../../../../components/BasicButton";
import { primaryColor } from "../../../../config/constants";
import { AddProductForm } from "./components/AddProductForm";
import { ProductsTable } from "./components/ProductsTable";
import PortalService from "../../../../services/supabase/PortalService";

const Products = () => {
    const theme = useTheme();
    const portalService = PortalService();
    const [openAddProductForm, setOpenAddProductForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { allProducts } = productStore(state => state);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            await portalService.getAllProducts();
            setIsLoading(false);
        };
        fetchProducts();
    }, []);

    const handleOpenAddProductForm = () => {
        setOpenAddProductForm(true);
    };

    const handleCloseAddProductForm = () => {
        setOpenAddProductForm(false);
    };

    return (
        <Container
            component="main"
            maxWidth="lg"
            sx={{
                height: "100%",
                paddingX: { xs: 0, md: 2 },
            }}>
            {
                isLoading ?
                    <Grid container justifyContent="center" mt={20}>
                        <Grid item>
                            <CircularProgress
                                size={40}
                                sx={{ color: theme.palette.primary.main }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Box display="flex" justifyContent="flex-end" marginY={2}>
                            <BasicButton
                                title="Add Product"
                                onClick={handleOpenAddProductForm}
                                startIcon={<AddCircle />}
                            />
                        </Box>
                        <ProductsTable
                            products={allProducts}
                        />
                        {
                            openAddProductForm &&
                            <AddProductForm
                                products={allProducts}
                                onClose={handleCloseAddProductForm}
                            />
                        }
                    </>
            }
        </Container>
    );
};

export default Products;
