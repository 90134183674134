import { VAT_PERCENTAGE } from "../config/constants";
import { AccessLevel } from "../models/enums/AccessLevel";
import _ from "lodash";
import { Theme } from "@mui/material/styles";

export const formatTimestamp = (timestampString) => {
	const timestamp = new Date(timestampString);
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long", // Abbreviated month
		day: "numeric",
	};
	return timestamp.toLocaleDateString("en-GB", options);
};

export const formatUpdatedTimestamp = (timestampString: string) => {
    const timestamp = new Date(timestampString);
    const now = new Date();
    const differenceInMilliseconds = now.getTime() - timestamp.getTime();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (differenceInMilliseconds < oneDayInMilliseconds) {
        const seconds = Math.floor(differenceInMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }

	return "-";
};

export const getStatusText = (status, compact = false) => {
	switch (status) {
		case 1:
			return compact ? "Pending" : "Pending Order Confirmation";
		case 2:
			return compact ? "Confirmed" : "Order Confirmed";
		case 3:
			return compact ? "In Transit" : "In Transit";
		case 4:
			return compact ? "Delivered" : "Order Delivered";
		default:
			return compact ? "Unknown" : "Unknown Status";
	}
};

export const getReferenceValue = (reference) => {
	const indexOfFirstDash = reference.indexOf("-");
	if (indexOfFirstDash !== -1) {
		return reference.substring(0, indexOfFirstDash).toString().toUpperCase();
	}
	return reference;
};

export const getDeliveryTypeSetup = (deliveryType: string, theme?: Theme | null, isLarge: boolean = false) => {
	const text = {
		"nextDay": isLarge ? "Next Day Delivery" : "Next Day",
		"standard": isLarge ? "Standard Delivery" : "Standard",
	}

	// Use theme colors if available, otherwise fallback to original colors
	const backgroundColor = theme ? {
		"nextDay": `${theme.palette.success.main}25`, // Using hex alpha (25 = 15% opacity)
		"standard": theme.palette.mode === "dark" 
			? `${theme.palette.info.main}25` 
			: `${theme.palette.info.main}25`,
	} : {
		"nextDay": "rgba(0, 128, 0, 0.1)",
		"standard": "rgba(0, 0, 0, 0.1)",
	}

	const textColor = theme ? {
		"nextDay": theme.palette.success.main,
		"standard": theme.palette.info.main,
	} : {
		"nextDay": "green",
		"standard": "black",
	}

	const borderColor = theme ? {
		"nextDay": theme.palette.success.main,
		"standard": theme.palette.info.main,
	} : {
		"nextDay": "green",
		"standard": "black",
	}

	return {
		text: text[deliveryType],
		backgroundColor: backgroundColor[deliveryType],
		borderColor: borderColor[deliveryType],
		textColor: textColor[deliveryType],
	}
}

export const hasPortalAccess = (role: AccessLevel) => {
	return !_.isEmpty(role) && _.includes([AccessLevel.Moderator.toLowerCase(), AccessLevel.Admin.toLowerCase()], role.toLowerCase());
};

export const getOrderPaymentDetails = (orderItems, deliveryFee) => {
	const baseCost = orderItems.reduce((total, item) => total + item.product.price * item.quantity, 0);
	const vat = baseCost * (VAT_PERCENTAGE / 100);
	const totalCost = baseCost + vat + deliveryFee;
	return { baseCost, vat, totalCost, deliveryFee };
}

export const getBadgeText = ({ inStock, isOnSale, discountAmount }: { inStock: boolean, isOnSale: boolean, discountAmount: number }) => {
	if (!inStock) {
		return {
			text: "Out of Stock",
			textColor: "white",
			backgroundColor: "rgba(128, 128, 128, 1)",
		};
	}

	if (isOnSale) {
		return {
			// text: `R${discountAmount} OFF`,
			text: `Sale`,
			textColor: "white",
			backgroundColor: "rgba(205, 0, 0, 1)",
		};
	}

	return {
		text: undefined,
		textColor: "white",
		backgroundColor: "rgba(128, 128, 128, 1)",
	};
};

export const calculateDiscountedPrice = (price: number, discount: number = 0) => {
	return (price - (price * (discount / 100))).toFixed(2);
};
