import { View, StyleSheet } from "react-native";
import { PortalLogin } from "./PortalLogin";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";
import { navigationStore } from "../../../app/stores/Navigation";

export const PortalAuthStack = () => {
	// const { defaultPortalAuthRoute } = navigationStore((state) => state);
	const { updateShowNavigationBar } = navigationStore((state) => state);
	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			updateShowNavigationBar(true);
		}
	}, [isFocused]);

	const AuthScreens = () => {
		return <PortalLogin />;
	};

	return (
		<View style={styles.authContainer}>
			<AuthScreens />
		</View>
	);
};

const styles = StyleSheet.create({
	authContainer: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
	},
});
