import { Box, Card, Typography, useTheme, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import moment from "moment";
import { dashboardStore } from "../../../../../app/stores/Dashboard";
import { CURRENCY } from "../../../../../config/constants";

interface ChartDataPoint {
    day: string;
    revenue: number;
    orders: number;
    date: string; // Original date string for filtering
}

interface WeekRange {
    start: moment.Moment;
    end: moment.Moment;
    label: string;
}

interface RevenueChartProps {
    title?: string;
}

const RevenueChart = ({
    title = "Revenue vs Order",
}: RevenueChartProps) => {
    const theme = useTheme();
    const [allChartData, setAllChartData] = useState<ChartDataPoint[]>([]);
    const [filteredChartData, setFilteredChartData] = useState<ChartDataPoint[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedWeek, setSelectedWeek] = useState<number>(0); // Default to "All"

    // Use a more stable reference to store data to prevent unnecessary re-renders
    const { revenueData } = dashboardStore((state) => (state));
    const { startDate, endDate } = dashboardStore((state) => ({
        startDate: state.selectedDateRange.startDate,
        endDate: state.selectedDateRange.endDate
    }));

    // Generate subtitle from the selectedDateRange
    const getCurrentMonth = () => {
        return moment(endDate).format("MMMM YYYY");
    };

    // Generate week ranges for the selected month
    const weekRanges = useMemo(() => {
        const weeks: WeekRange[] = [];
        if (!startDate) return weeks;

        const monthStart = moment(startDate);
        const monthEnd = moment(endDate);

        // Add "All" option for the entire month
        weeks.push({
            start: monthStart.clone(),
            end: monthEnd.clone(),
            label: `All - ${monthEnd.format("MMMM")}`
        });

        // Start with first day of the month
        let currentDay = monthStart.clone();
        let weekIndex = 1;

        while (currentDay.isSameOrBefore(monthEnd)) {
            // Start of this week
            const weekStart = currentDay.clone();

            // Calculate end of week (max 6 days later or month end)
            const weekEnd = moment.min(
                weekStart.clone().add(6, "days"),
                monthEnd.clone()
            );

            weeks.push({
                start: weekStart,
                end: weekEnd,
                label: `Week ${weekIndex++}: ${weekStart.format("MMM D")} - ${weekEnd.format("MMM D")}`
            });

            // Move to start of next week
            currentDay = weekEnd.clone().add(1, "days");

            // Break if we've gone past the month end
            if (currentDay.isAfter(monthEnd)) break;
        }
        return weeks;
    }, [startDate, endDate]);

    // Use useRef to track whether this is the first render
    const isInitialRender = useRef(true);

    useEffect(() => {
        // Reset selected week when month changes
        setSelectedWeek(0); // Default to "All"

        // Skip the first render to avoid unnecessary loading state
        if (isInitialRender.current) {
            isInitialRender.current = false;
            if (revenueData && revenueData.length > 0) {
                processRevenueData();
            }
            return;
        }

        setLoading(true);
        processRevenueData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, revenueData]);

    // Filter data by week when selectedWeek changes
    useEffect(() => {
        if (allChartData.length === 0) return;

        if (selectedWeek === 0) {
            // Show all data
            setFilteredChartData(allChartData);
            return;
        }

        const selectedRange = weekRanges[selectedWeek];
        if (!selectedRange) return;

        // Format start and end dates of selected range to ISO string format for comparison
        const rangeStart = selectedRange.start.format('YYYY-MM-DD');
        const rangeEnd = selectedRange.end.format('YYYY-MM-DD');


        const filtered = allChartData.filter(point => {
            // Ensure we're comparing in the same format (YYYY-MM-DD)
            const pointDate = point.date;

            // Check if the point date is within the selected range (inclusive)
            return pointDate >= rangeStart && pointDate <= rangeEnd;
        });

        setFilteredChartData(filtered.length > 0 ? filtered : []);
    }, [selectedWeek, allChartData, weekRanges]);

    // Extract processing logic to a separate function to improve readability
    const processRevenueData = () => {
        if (revenueData && revenueData.length > 0) {
    // Format the data for the chart
            const formattedData = revenueData.map((item) => ({
                day: moment(item.day).format("DD"),
                revenue: Number(item.daily_sales),
                orders: Number(item.daily_orders),
                date: item.day // Keep original date string (should be in YYYY-MM-DD format)
            }));

            // Sort by date to ensure correct ordering
            formattedData.sort((a, b) => a.date.localeCompare(b.date));

            setAllChartData(formattedData);
            setFilteredChartData(formattedData); // Default to showing all data
        } else {
            // No data available
            setAllChartData([]);
            setFilteredChartData([]);
        }
        setLoading(false);
    };

    // Handle week selection change
    const handleWeekChange = (event: SelectChangeEvent<number>) => {
        setSelectedWeek(event.target.value as number);
    };

    // Extract data for chart
    const xAxis = filteredChartData.map(item => item.day);
    const revenueValues = filteredChartData.map(item => Number(item.revenue.toFixed(2)) || 0);
    const ordersValues = filteredChartData.map(item => item.orders || 0);

    // Define chart colors
    const revenueColor = theme.palette.primary.main;
    const ordersColor = theme.palette.info.main;

    return (
        <Card
            sx={{
                p: 3,
                height: "100%",
                boxShadow: theme.shadows[2],
                borderRadius: theme.shape.borderRadius,
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box sx={{
                mb: 3,
                display: "grid",
                gridTemplateAreas: {
                    xs: `"title" "filter"`,
                    sm: `"title filter"`
                },
                gridTemplateColumns: {
                    xs: "1fr",
                    sm: "1fr auto"
                },
                rowGap: 2,
                alignItems: "center"
            }}>
                <Box sx={{
                    gridArea: "title",
                    textAlign: "start",
                    width: "100%"
                }}>
                    <Typography variant="h6" fontFamily="primaryFont" fontWeight="bold">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {getCurrentMonth()}
                    </Typography>
                </Box>

                {/* Filter by week */}
                {filteredChartData.length > 0 && weekRanges.length > 1 && (
                    <FormControl
                        size="small"
                        sx={{
                            gridArea: "filter",
                            minWidth: 200,
                            justifySelf: "end",
                            backgroundColor: theme.customColors.cardBackground
                        }}
                    >
                        <InputLabel id="week-select-label" sx={{ fontFamily: "primaryFont" }}>Filter by Week</InputLabel>
                        <Select
                            labelId="week-select-label"
                            id="week-select"
                            value={selectedWeek}
                            size="small"
                            label="Filter by Week"
                            onChange={handleWeekChange}
                            sx={{ fontFamily: "primaryFont", fontSize: "0.875rem" }}
                        >
                            {weekRanges.map((week, index) => (
                                <MenuItem key={index} value={index} sx={{ fontFamily: "primaryFont", fontSize: "0.875rem" }}>
                                    {week.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>

            <Box sx={{ flexGrow: 1, height: 370, width: "100%", position: "relative" }}>
                {filteredChartData.length > 0 ? (
                    <LineChart
                        xAxis={[{ data: xAxis, scaleType: "point" }]}
                        series={[
                            {
                                data: revenueValues,
                                label: `Revenue (${CURRENCY})`,
                                color: revenueColor,
                                curve: "monotoneX",
                                showMark: true,
                                area: false,
                                yAxisKey: "defaultAxis",
                            },
                            {
                                data: ordersValues,
                                label: "Orders",
                                color: ordersColor,
                                curve: "monotoneX",
                                showMark: true,
                                area: false,
                                yAxisKey: "ordersAxis",
                            },
                        ]}
                        yAxis={[
                            { id: "defaultAxis" },
                            { id: "ordersAxis", scaleType: "linear" },
                        ]}
                        rightAxis="ordersAxis"
                        height={370}
                        margin={{ top: 70, right: 40, bottom: 100, left: 40 }}
                        // Move the legend to the bottom
                        slotProps={{
                            legend: {
                                position: {
                                    vertical: "bottom",
                                    horizontal: "middle"
                                },
                                itemGap: 20,
                                markGap: 5,
                                itemMarkWidth: 10,
                                itemMarkHeight: 10,
                            },
                        }}
                        sx={{
                            ".MuiLineElement-root": {
                                strokeWidth: 3
                            },
                            ".MuiMarkElement-root": {
                                strokeWidth: 1.5,
                                r: 5,
                                transition: "fill 0.2s ease-in-out",
                                "&:hover": {
                                    fill: revenueColor,
                                    strokeWidth: 0,
                                    r: 6
                                }
                            },
                            "& .MuiChartsAxis-tickLabel": {
                                fontWeight: "500",
                                fontSize: "0.875rem"
                            },
                            "& .MuiChartsAxis-label": {
                                fontWeight: "bold",
                                fontSize: "0.875rem"
                            },
                            "& .MuiChartsLegend-root": {
                                paddingTop: 8,
                                flexDirection: "row",
                                "& .MuiChartsLegend-label": {
                                    fontSize: "0.875rem",
                                    fontWeight: "500"
                                }
                            }
                        }}
                    />
                ) : (
                    <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                    >
                        <Typography variant="body2" color="text.secondary">
                                {loading ? "Loading data..." : "No revenue data available for the selected period"}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Card>
    );
};

export default RevenueChart; 