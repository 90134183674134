import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Typography, useTheme, Divider, InputAdornment } from "@mui/material";
import { AddCircle } from '@mui/icons-material';
import { Product, ProductImage } from "../../../../../models/DB";
import { Image } from "react-native";
import { BasicButton } from "../../../../../components/BasicButton";
import { BasicTextField } from "../../../../../components/BasicTextField";
import { productStore } from "../../../../../app/stores/Product";
import { CURRENCY, primaryColor } from "../../../../../config/constants";
import PortalService from "../../../../../services/supabase/PortalService";
import { BasicAutocomplete } from "../../../../../components/BasicAutocomplete";
import { calculateDiscountedPrice } from "../../../../../utils/customFunctions";

interface AddProductFormProps {
    products: Product[];
    onClose: () => void;
}

export const AddProductForm: React.FC<AddProductFormProps> = ({ products, onClose }) => {

    const { createProduct } = PortalService();
    const { updateProducts } = productStore(state => state);
    const theme = useTheme();
    const [productData, setProductData] = useState<Partial<Product>>({
        name: "",
        category: "",
        price: 0.00,
        stockOnHand: 0,
        discount: 0,
        inStock: true,
        variantType: "",
        variantOptions: [],
        images: []
    });

    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string }>({});
    const [imageFiles, setImageFiles] = useState<{ [key: string]: File | null }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
    const [uniqueVariants, setUniqueVariants] = useState<string[]>([]);
    const [uniqueVariantOptions, setUniqueVariantOptions] = useState<string[]>([]);

    /**
     *  Get unique categories and variants from the products
     */
    useEffect(() => {
        const categories = new Set<string>();
        const variants = new Set<string>();

        products.forEach(product => {
            if (product.category) {
                categories.add(product.category);
            }
            if (product.variantType) {
                variants.add(product.variantType);
            }
        });

        setUniqueCategories(Array.from(categories));
        setUniqueVariants(Array.from(variants));
    }, [products]);

    /**
     * Get unique variant options from the products
     */
    useEffect(() => {
        if (productData.variantType) {
            const options = new Set<string>();
            products.forEach(product => {
                if (product.variantType === productData.variantType) {
                    product.variantOptions.forEach(option => options.add(option));
                }
            });
            setUniqueVariantOptions(Array.from(options));
        } else {
            setUniqueVariantOptions([]);
        }
    }, [productData.variantType, products]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Handle integer-only input for stockOnHand
        if (name === "stockOnHand") {
            if (value === "") {
                setProductData({
                    ...productData,
                    [name]: "" as any
                });
                return;
            }
            const intValue = parseInt(value);
            setProductData({
                ...productData,
                [name]: isNaN(intValue) ? 0 : intValue
            });
            return;
        }

        // Handle price inputs
        if (name === "price") {
            if (value === "") {
                setProductData({
                    ...productData,
                    [name]: "" as any
                });
                return;
            }
            const numValue = parseFloat(value);
            setProductData({
                ...productData,
                [name]: isNaN(numValue) ? 0 : numValue
            });
            return;
        }

        // Special case for discount - allow empty value while typing
        if (name === "discount") {
            if (value === "") {
                setProductData({
                    ...productData,
                    [name]: "" as any
                });
                return;
            }
            const numValue = parseFloat(value);
            setProductData({
                ...productData,
                [name]: isNaN(numValue) ? 0 : numValue
            });
            return;
        }

        // Handle string inputs
        setProductData({
            ...productData,
            [name]: value
        });
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (value === "") {
            if (name === "discount" || name === "stockOnHand" || name === "price") {
                // Default numeric fields to 0 when empty
                setProductData({
                    ...productData,
                    [name]: 0
                });
            } else {
                setProductData({
                    ...productData,
                    [name]: value
                });
            }
        } else if (name === "price") {
            // Format price to 2 decimal places
            setProductData({
                ...productData,
                [name]: Number(parseFloat(value).toFixed(2))
            });
        } else if (name === "stockOnHand") {
            // Ensure stock is an integer
            setProductData({
                ...productData,
                [name]: Math.floor(Number(value))
            });
        } else if (name === "discount") {
            // Ensure discount is between 0 and 100
            const discountValue = Math.min(100, Math.max(0, Number(value)));
            setProductData({
                ...productData,
                [name]: discountValue
            });
        }
    };

    const handleImageUpload = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const renamedFile = new File([file], `${type}`, { type: file.type });
            setImageFiles({ ...imageFiles, [type]: renamedFile });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviews({ ...imagePreviews, [type]: reader.result as string });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        const trimTrailingWhitespace = (data: Partial<Product>) => {
            return Object.fromEntries(
                Object.entries(data).map(([key, value]) =>
                    typeof value === "string" ? [key, value.replace(/\s+$/, "")] : [key, value]
                )
            );
        };

        const trimmedProductData = trimTrailingWhitespace(productData);

        const images: ProductImage[] = Object.keys(imageFiles).map((type) => ({
            type,
            url: "" // The URL will be set by the server after upload
        }));

        setLoading(true);
        const validImageFiles = Object.values(imageFiles).filter((file): file is File => file !== null);
        const product = await createProduct({ ...trimmedProductData, images } as Product, validImageFiles);
        setLoading(false);
        if (product) {
            onClose();
            setTimeout(() => {
                updateProducts([...products, product]);
            }, 500);
        } else {
            console.error("Error creating product");
        }
    };



    return (
        <Dialog
            open
            fullScreen
            fullWidth
            onClose={onClose}
            maxWidth="lg"
            sx={{
                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: 12,
            }}
            PaperProps={{
                sx: {
                    backgroundColor: theme.customColors.background,
                },
                variant: "outlined",
                elevation: 0,
            }}
        >
            <DialogTitle
                sx={{
                    marginX: 1,
                    marginY: 1,
                    color: theme.customColors.textPrimary,
                }}
            >
                Add New Product
            </DialogTitle>
            <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 2, overflow: "auto" }}>
                <Box component="form" noValidate autoComplete="off" maxWidth={"md"} width="100%">
                    <Grid container spacing={3} sx={{ padding: 2 }}>
                        {/* Product Images Section */}
                        <Grid item xs={12}>
                            <Box maxWidth="md" mx="auto" sx={{ mb: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Product Images
                                </Typography>
                                <Typography
                                    variant="body2"
                                    mt={1}
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Select an image to add or replace
                                </Typography>
                                <Typography
                                    variant="body2"
                                    mb={3}
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    You can upload up to 3 images
                                </Typography>
                                <Grid container spacing={2} justifyContent="center" alignContent={"center"}>
                                    {/* Product Thumbnail */}
                                    {["thumbnail", "image1", "image2"].map((type) => (
                                        <Grid
                                            item
                                            xs={3}
                                            key={type}>
                                            <Button
                                                fullWidth
                                                variant="text"
                                                component="label"
                                                sx={{
                                                    aspectRatio: "1 / 1",
                                                    padding: 0,
                                                    borderRadius: 6,
                                                    overflow: "hidden",
                                                    borderStyle: "solid",
                                                    borderWidth: 1.75,
                                                    borderColor: primaryColor,
                                                    //hover opacity
                                                    "&:hover": {
                                                        opacity: 0.5
                                                    }
                                                }}
                                            >
                                                <Image
                                                    source={
                                                        imagePreviews[type]
                                                            ? { uri: imagePreviews[type] }
                                                            : require("../../../../../assets/no-image.png")
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        aspectRatio: "1 / 1",
                                                        resizeMode: "cover",
                                                    }}
                                                />
                                                <input
                                                    hidden
                                                    type="file"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    onChange={handleImageUpload(type)}
                                                />
                                            </Button>
                                            <Typography
                                                variant="body2"
                                                mt={1.5}
                                                sx={{
                                                    fontFamily: "primaryFont",
                                                    letterSpacing: 0.5,
                                                    textAlign: "center",
                                                }}
                                            >
                                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-zA-Z])(\d)/g, "$1 $2")}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                        <Divider sx={{ width: "100%", marginY: 4 }} />

                        {/* Product Details Section - Now Full Width */}
                        <Grid item xs={12}>
                            <Box maxWidth="md" mx="auto">
                                <Typography
                                    variant="body1"
                                    mb={1}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Product Details
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Provide the product details below. Enter the product name, select or add a category, and specify the price. Stock on hand and discount are optional fields.
                                </Typography>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    sx={{
                                        marginTop: 2,
                                    }}
                                >
                                    <Grid item>
                                        <BasicTextField
                                            required
                                            name="name"
                                            label="Name"
                                            size="small"
                                            fullWidth
                                            value={productData.name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicAutocomplete
                                            freeSolo
                                            required
                                            name="category"
                                            label="Category"
                                            fullWidth
                                            size="small"
                                            options={uniqueCategories}
                                            value={productData.category || ""}
                                            onChange={(newValue) => {
                                                if (typeof newValue === "string") {
                                                    setProductData({ ...productData, category: newValue });
                                                } else if (Array.isArray(newValue) && newValue.length > 0) {
                                                    // Handle the case where multiple values are returned
                                                    setProductData({ ...productData, category: newValue[0] });
                                                } else {
                                                    setProductData({ ...productData, category: "" });
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            required
                                            name="price"
                                            label="Price"
                                            fullWidth
                                            size="small"
                                            value={productData.price}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            numberOnly
                                            type="number"
                                            inputProps={{
                                                step: "0.01",
                                                min: "0"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            name="stockOnHand"
                                            label="Stock On Hand"
                                            fullWidth
                                            size="small"
                                            value={productData.stockOnHand}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            numberOnly
                                            type="number"
                                            inputProps={{
                                                step: "1",
                                                min: "0",
                                                pattern: "[0-9]*"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            name="discount"
                                            label="Discount"
                                            fullWidth
                                            size="small"
                                            value={productData.discount}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            disabled={!productData.price}
                                            numberOnly
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                            }}
                                            inputProps={{
                                                step: "1",
                                                min: "0",
                                                max: "100"
                                            }}
                                        />
                                        {productData?.price && (productData?.discount ?? 0) > 0 && (
                                            <Typography
                                                variant="caption"
                                                color="green"
                                                sx={{
                                                    display: "block",
                                                    mt: 0.5,
                                                    fontFamily: "primaryFont",
                                                    letterSpacing: 0.5
                                                }}
                                            >
                                                Price after discount: {CURRENCY} {calculateDiscountedPrice(productData.price, productData.discount)}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Divider sx={{ width: "100%", marginY: 4 }} />

                        {/* Variants Section - Now Full Width */}
                        <Grid item xs={12}>
                            <Box maxWidth="md" mx="auto">
                                <Typography
                                    variant="body1"
                                    mb={1}
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Variants
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Choose a variant type to unlock its options. You can also create new types and options as needed by just typing in a new variant type.
                                </Typography>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    sx={{
                                        marginTop: 2,
                                    }}
                                >
                                    <Grid item>
                                        <BasicAutocomplete
                                            freeSolo
                                            required
                                            fullWidth
                                            name="variantType"
                                            label="Variant Type"
                                            size="small"
                                            options={uniqueVariants}
                                            value={productData.variantType || ""}
                                            onChange={(newValue) => {
                                                if (typeof newValue === "string") {
                                                    setProductData({ ...productData, variantType: newValue });
                                                } else if (Array.isArray(newValue) && newValue.length > 0) {
                                                    setProductData({ ...productData, variantType: newValue[0] });
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item mt={2}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            mb={1}
                                            sx={{
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                            }}
                                        >
                                            {productData.variantType ? `Enter in variant options for type: ${productData.variantType}` : "Select a variant type above to add its options."}
                                        </Typography>
                                        <BasicAutocomplete
                                            freeSolo
                                            required
                                            multiple
                                            name="variantOptions"
                                            label="Variant Options"
                                            fullWidth
                                            size="medium"
                                            options={uniqueVariantOptions}
                                            value={productData.variantOptions || []}
                                            onChange={(newValue) => setProductData({
                                                ...productData,
                                                variantOptions: Array.isArray(newValue) ? newValue : [newValue]
                                            })}
                                            disabled={!productData.variantType}
                                        />
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            mb={1}
                                            mt={1}
                                            sx={{
                                                display: !productData.variantType ? "none" : "block",
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                            }}
                                        >
                                            {`Press enter after typing to add a new variant option`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 2, backgroundColor: theme.customColors.background }}>
                <BasicButton
                    title="Cancel"
                    variant="outlined"
                    onClick={onClose}
                    size="small"
                />
                <BasicButton
                    title={`Add Product`}
                    variant="contained"
                    onClick={handleSubmit}
                    isLoading={loading}
                    startIcon={<AddCircle />}
                    size="small"
                />
            </DialogActions>
        </Dialog>
    );
};