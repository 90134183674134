import { Container, Box, Grid } from "@mui/material";
import { useCallback, useEffect } from "react";
import { DashboardHeader, StatsTilesRow, RevenueChart, OrderStatusCard } from "./components";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { userStore } from "../../../../app/stores/User";
import { dashboardStore } from "../../../../app/stores/Dashboard";
import { DashboardService } from "../../../../services/supabase/DashboardService";

const Dashboard = () => {
    const { user } = userStore((state) => state);
    const { selectedDateRange } = dashboardStore((state) => state);
    const dashboardService = DashboardService();

    // Fetch dashboard data on component mount
    useEffect(() => {
        const fetchData = async () => {
            const startDate = moment().subtract(1, 'month').format("YYYY-MM-DD");
            const endDate = moment().format("YYYY-MM-DD");
            await dashboardService.getDashboardData(
                startDate,
                endDate
            );
        };
        fetchData();
    }, []);

    const handleDateChange = useCallback((date: Date) => {
        const startDate = moment(date).startOf('month').format("YYYY-MM-DD");
        const endDate = moment(date).endOf('month').format("YYYY-MM-DD");

        // Update the date range and refetch data
        dashboardStore.getState().setSelectedDateRange(startDate, endDate);
        dashboardService.getDashboardData(startDate, endDate);
    }, [dashboardService]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Container
                component="main"
                maxWidth="lg"
                sx={{
                    height: "100%",
                    paddingX: { xs: 2, sm: 3 },
                    paddingY: 3,
                }}>

                <Box sx={{ width: "100%" }}>
                    {/* Dashboard Header with Welcome message and Controls */}
                    <DashboardHeader
                        userName={user.info.user_metadata.firstName}
                        onDateChange={handleDateChange}
                        initialDate={moment(selectedDateRange.endDate).toDate()}
                    />

                    {/* Statistics Tiles Row */}
                    <StatsTilesRow />

                    {/* Charts & Analytics Section */}
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={8}>
                            <RevenueChart
                                title="Revenue vs Order"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <OrderStatusCard
                                title="Order Status Distribution"
                            />
                        </Grid>
                    </Grid>

                    {/* Space for additional future components */}
                    <Box sx={{ height: "200px", mb: 3 }}>
                        {/* Additional components would go here */}
                    </Box>
                </Box>

            </Container>
        </LocalizationProvider>
    );
};

export default Dashboard;
