import { Container, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";
import { navigationStore } from "../../app/stores/Navigation";
import OrdersService from "../../services/supabase/OrdersService";
import { formatTimestamp, getDeliveryTypeSetup, getOrderPaymentDetails, getReferenceValue } from "../../utils/customFunctions";
import { BasicButton } from "../../components/BasicButton";
import { OrderItemCard } from "../../components/OrderItemCard";
import { OrderDetails } from "../../models/DB";
import { VAT_PERCENTAGE } from "../../config/constants";
import { OrderStatusBadge } from "../../components/OrderStatusBadge";

const OrderItems = ({ orderItems }) => {
	const theme = useTheme();
	return (
		<Grid
			item
			xs={12}
			md={6}
			marginY={{ lg: 4 }}
		>
			<Typography
				letterSpacing={0.5}
				variant="h6"
				style={{ fontWeight: "bold" }}
				marginTop={2}
				marginBottom={4}
				color={theme.palette.text.primary}
			>
				{`${orderItems.length} Items Purchased: `}
			</Typography>
			{/* Render order items here */}
			{orderItems.map((item, index) => (
				// convert to a component called OrderItemCard
				<OrderItemCard
					key={`orderItemCard_${index}`}
					item={item}
				/>
			))}
		</Grid>
	);
};

const DeliverTypeBlock = ({ deliveryType }) => {
	const theme = useTheme();
	const { backgroundColor, borderColor, textColor, text } = getDeliveryTypeSetup(deliveryType, theme);

	return (
		<Grid container>
			<Grid
				item
				sx={{
					border: 2,
					borderColor: borderColor,
					backgroundColor: backgroundColor,
					borderRadius: 2,
					padding: 2,
					paddingY: 1.5,
					boxShadow: "0px 2px 4px rgba(0,0,0,0.05)"
				}}
			>
				<Typography
					color={textColor}
					fontSize={13}
					fontWeight={"bold"}
					letterSpacing={0.5}
				>{text}</Typography>
			</Grid>
		</Grid>
	)
}

const OrderInfo = ({ orderDetails }: { orderDetails: OrderDetails }) => {
	const theme = useTheme();
	return (
		<Grid
			item
			xs={12}
			md={6}
		>
			<Typography
				letterSpacing={0.5}
				variant="h5"
				style={{ fontWeight: "bold" }}
				marginY={1}
				color={theme.palette.text.primary}
			>
				{`Order #${getReferenceValue(orderDetails.reference)}`}
			</Typography>
			<Typography
				color={theme.palette.text.secondary}
				fontSize={14}
				letterSpacing={0.5}
				style={{ fontWeight: "bold" }}
			>
				{`${formatTimestamp(orderDetails.created)}`}
			</Typography>
			<OrderStatusBadge status={orderDetails.status} />
			<Divider sx={{ marginY: 2, width: 300, borderColor: theme.palette.divider }} />
			<Typography
				variant="h6"
				letterSpacing={0.5}
				style={{ fontWeight: "bold" }}
				marginY={1}
				mt={4}
				color={theme.palette.text.primary}
			>
				Delivery Details:
			</Typography>
			{/* Render delivery details */}
			<DeliverTypeBlock
				deliveryType={orderDetails.deliveryType}
			/>
			<Typography
				variant="h6"
				letterSpacing={0.5}
				style={{ fontWeight: "bold" }}
				marginY={1}
				mt={4}
				color={theme.palette.text.primary}
			>
				Shipping Details:
			</Typography>
			{/* Render shipping details */}
			{orderDetails.shippingDetails &&
				Object.entries(orderDetails.shippingDetails).map(([key, value]) => (
					<Typography
						color={theme.palette.text.secondary}
						fontSize={14}
						letterSpacing={0.5}
						key={key}
					>{`${value}`}</Typography>
				))}
			<Typography
				variant="h6"
				letterSpacing={0.5}
				style={{ fontWeight: "bold", marginTop: 16 }}
				marginTop={3}
				marginBottom={1}
				color={theme.palette.text.primary}
			>
				Contact Details:
			</Typography>
			{/* Render contact details */}
			<Grid
				container
				spacing={2}
				style={{ flexDirection: "column" }}
			>
				<Grid item>
					<Typography
						color={theme.palette.text.secondary}
						fontSize={14}
						letterSpacing={0.5}
					>
						{orderDetails.contactDetails.firstName} {orderDetails.contactDetails.lastName}
					</Typography>
					{/* Additional contact details */}
					{Object.entries(orderDetails.contactDetails)
						.filter(([key]) => key !== "firstName" && key !== "lastName")
						.map(([key, value]) => (
							<Typography
								fontSize={14}
								color={theme.palette.text.secondary}
								letterSpacing={0.5}
								key={key}
							>{`${value}`}</Typography>
						))}
				</Grid>
			</Grid>
			<OrderSummary
				orderItems={orderDetails.order}
				deliveryFee={orderDetails.deliveryFee}
			/>
		</Grid>
	);
};

const SearchOrder = ({ orderId, onOrderIdChange, onSearchClick, loading }) => {
	return (
		<Grid
			container
			flexDirection={"row"}
			justifyContent={"center"}
			spacing={2}
			marginY={2}
		>
			<Grid item>
				<TextField
					label="Enter Order ID"
					variant="outlined"
					size="small"
					value={orderId}
					onChange={(e) => onOrderIdChange(e.target.value)}
					style={{ marginBottom: 16, width: "100%" }}
				/>
			</Grid>
			<Grid item>
				<BasicButton
					variant="contained"
					title="Search"
					onClick={onSearchClick}
					isLoading={loading}
					disabled={orderId.length < 8 || loading}
					style={{ marginBottom: 16 }}
				/>
			</Grid>
		</Grid>
	);
};

const OrderSummary = ({ orderItems, deliveryFee }) => {
	const theme = useTheme();
	const { baseCost, vat, totalCost } = getOrderPaymentDetails(orderItems, deliveryFee);

	return (
		<Grid
			item
			xs={12}
			sm={6}
			mt={4}
			sx={{
				maxWidth: "380px"
			}}
		>
			<Divider sx={{ borderColor: theme.palette.divider }} />
			<Typography
				letterSpacing={0.5}
				variant="h6"
				style={{ fontWeight: "bold" }}
				mt={4}
				color={theme.palette.text.primary}
			>
				Order Summary
			</Typography>
			<Grid container justifyContent="space-between" mt={1}>
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					Order Cost:
				</Typography>
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					R {baseCost.toFixed(2)}
				</Typography>
			</Grid>
			<Grid container justifyContent="space-between">
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					Delivery Fee:
				</Typography>
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					R {deliveryFee}.00
				</Typography>
			</Grid>
			<Grid container justifyContent="space-between">
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					VAT ({VAT_PERCENTAGE}%):
				</Typography>
				<Typography
					color={theme.palette.text.secondary}
					fontSize={12}
					letterSpacing={0.5}
				>
					R {vat.toFixed(2)}
				</Typography>
			</Grid>
			<Grid container justifyContent="space-between" mt={1} mb={4}>
				<Typography
					color={theme.palette.text.primary}
					fontSize={16}
					letterSpacing={0.5}
					fontWeight={"bold"}
				>
					Total:
				</Typography>
				<Typography
					color={theme.palette.text.primary}
					fontSize={16}
					letterSpacing={0.5}
					style={{ fontWeight: "bold" }}
				>
					R {totalCost.toFixed(2)}
				</Typography>
			</Grid>
			<Divider sx={{ display: { xs: "flex", md: "none" }, borderColor: theme.palette.divider }} />
		</Grid>
	);
};

export const Orders = ({ route }) => {
	const searchIcon = require("../../assets/search.png");
	const { updateSplashLoaded, updateShowNavigationBar } = navigationStore((state) => state);
	const theme = useTheme();

	const [orderId, setOrderId] = useState("");
	const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
	const [searchStatus, setSearchStatus] = useState("");
	const [loading, setLoading] = useState(false);

	const orderService = OrdersService();

	useEffect(() => {
		updateSplashLoaded(true);
		updateShowNavigationBar(true);
	}, []);

	useEffect(() => {
		const params = route.params;
		handleDeeplink(params);
	}, []);

	const handleDeeplink = (params) => {
		if (params) {
			const trackingid = params.trackingid;
			if (trackingid) {
				setOrderId(trackingid);
				handleOrderTracking(trackingid);
			}
		}
	};

	const handleOrderTracking = async (id) => {
		setLoading(true);
		const orderRef = id ?? orderId;
		const result = await orderService.findOrderById(orderRef);
		if (result) {
			setOrderDetails(result);
			setSearchStatus("");
		} else {
			setOrderDetails(null);
			setSearchStatus("No order found with the given ID");
		}
		setLoading(false);
	};

	const handleOrderIdChange = (value) => {
		setOrderId(value.toUpperCase());
	};

	const handleSearchClick = () => {
		const cleanedValue = orderId.startsWith("#") ? orderId.slice(1) : orderId;
		handleOrderTracking(cleanedValue.toUpperCase());
	};

	return (
		<ScrollView
			style={{ backgroundColor: theme.customColors.background }}
			contentContainerStyle={{ backgroundColor: theme.customColors.background }}
		>
			<Container
				maxWidth="lg"
				style={{ height: "100%" }}
			>
				<Grid
					container
					spacing={2}
					direction="column"
					style={{ flex: 1 }}
					mb={{ xs: 20, md: 5 }}
				>
					<Grid
						item
						xs={12}
						textAlign="center"
						justifyContent="center"
						alignItems="center"
						marginTop={8}
					>
						<Grid
							container
							direction="column"
							alignItems="center"
						>
							<Grid item>
								<Image
									source={searchIcon}
									style={{ width: 42, aspectRatio: 1 / 1, resizeMode: "contain" }}
								/>
							</Grid>
							<Grid item>
								<Typography
									fontSize={14}
									letterSpacing={0.5}
									marginTop={2}
									color={theme.palette.text.secondary}
								>
									{searchStatus || "Search for your order using the Order ID"}
								</Typography>
								<SearchOrder
									orderId={orderId}
									onOrderIdChange={handleOrderIdChange}
									onSearchClick={handleSearchClick}
									loading={loading}
								/>
							</Grid>
						</Grid>
					</Grid>

					{orderDetails && (
						<Grid
							container
							spacing={2}
							style={{ marginTop: 16 }}
							paddingX={{ xs: 2 }}
						>
							<OrderInfo orderDetails={orderDetails} />
							<OrderItems orderItems={orderDetails.order} />
						</Grid>
					)}
				</Grid>
			</Container>
		</ScrollView>
	);
};
