import { useState, useEffect } from "react";
import { View, Platform, ActivityIndicator, Animated } from "react-native";
import { WebView } from "react-native-webview";
import { appConfigStore } from "../../app/stores/AppConfig";
import { primaryColor } from "../../config/constants";
import { userStore } from "../../app/stores/User";
import { Typography, Button } from "@mui/material";
import { AppLogo } from "../../components/AppLogo";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { BasicButton } from "../../components/BasicButton";

export const Bookings = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const bookingsUrl =
		"https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ03gH0cUD3uLLBFXlk5_d63rT_xZ_cEuVSyxTe-jA34LwEj_eJJyHZGkLYcuAur2aN7e47mO64g?gv=true";

	const [isLoading, setIsLoading] = useState(true);
	const [activityFadeAnimation] = useState(new Animated.Value(1));
	const [formFadeAnimation] = useState(new Animated.Value(0));
	const { authenticated } = userStore((state) => state.user);
	const { navigateToScreen } = useScreenComponent();
	const [webViewKey, setWebViewKey] = useState(Date.now());

	useEffect(() => {
		if (authenticated) {
			setTimeout(() => {
				Animated.timing(activityFadeAnimation, {
					toValue: 0,
					duration: 1000,
					useNativeDriver: Platform.OS !== "web" ? true : false,
				}).start(() => {
					console.log("isLoading set to false");
					setIsLoading(false);
					Animated.timing(formFadeAnimation, {
						toValue: 1,
						duration: 1000,
						useNativeDriver: Platform.OS !== "web" ? true : false,
					}).start();
				});
			}, 3000);
		}
	}, [authenticated]);

	// Handle iframe/WebView error
	const handleError = () => {
		console.log("WebView encountered an error, reloading...");
		setWebViewKey(Date.now()); // Force reload with a new key
	};

	const LoadingIndicator = () => {
		return (
			<Animated.View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					opacity: activityFadeAnimation,
				}}
			>
				<ActivityIndicator
					size="large"
					color={primaryColor}
				/>
			</Animated.View>
		);
	};

	const BookingsView = () => {
		return (
			<Animated.View
				style={{
					flex: 1,
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
					opacity: formFadeAnimation,
				}}
			>
				{
					Platform.OS === "web" ? (
						<iframe
							key={webViewKey}
							style={{
								flex: 1,
								height: "100%",
								width: "100%",
								borderWidth: 0,
							}}
							src={bookingsUrl}
							sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
							allow="camera; microphone; geolocation"
							referrerPolicy="no-referrer-when-downgrade"
							onError={handleError}
						/>
					) : (
						<WebView
							key={webViewKey}
							style={{
								flex: 1,
								height: "100%",
								width: "100%",
							}}
							source={{ uri: bookingsUrl }}
							onError={handleError}
							javaScriptEnabled={true}
							domStorageEnabled={true}
							allowsFullscreenVideo={true}
							allowsInlineMediaPlayback={true}
							mediaPlaybackRequiresUserAction={false}
						/>
					)
				}
			</Animated.View>
		);
	}

	const SignInPrompt = () => {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<AppLogo size={100} />
				<Typography variant="h6" mt={6}>Please sign in to make a booking.</Typography>
				<BasicButton
					title={"Go to Sign In"}
					variant={"contained"}
					sx={{ marginTop: 1 }}
					onClick={() => {
						navigateToScreen(ScreenComponent.Login);
					}}
				/>
			</View>
		);
	};

	return (
		<>
			<View
				style={{
					flex: 1,
					backgroundColor: "white",
				}}
			>
				{
					authenticated ?
						<>
							{isLoading && <LoadingIndicator />}
							<BookingsView />
						</> :
						<SignInPrompt />
				}
			</View>
		</>
	);
};
