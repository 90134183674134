import { Button, Typography, useTheme } from "@mui/material";
import { ActivityIndicator, StyleSheet } from "react-native";
import { primaryColor } from "../config/constants";

interface Props {
	onClick?: (e: any) => void;
	title: string;
	style?: {};
	variant?: "text" | "contained" | "outlined";
	type?: "submit" | "reset" | "button";
	sx?: {};
	size?: "small" | "medium" | "large";
	isLoading?: boolean;
	disabled?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	children?: React.ReactNode;
}

export const BasicButton = ({
	onClick = () => { },
	title,
	style,
	variant = "contained",
	type = "button",
	sx = {},
	size = "medium",
	isLoading = false,
	disabled = false,
	startIcon = null,
	endIcon = null,
	children = null,
}: Props) => {
	const theme = useTheme();
	const primaryButtonColor = theme.palette.primary.main;

	//contained style
	const containedStyle = {
		backgroundColor: primaryButtonColor,
		color: "white",
		boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
	};

	//outlined style
	const outlinedStyle = {
		border: `1px solid ${primaryButtonColor}`,
		color: primaryButtonColor,
		backgroundColor: "white",
	};

	//text style
	const textStyle = {
		backgroundColor: "transparent",
		color: primaryButtonColor,
	};

	const buttonStyle = {
		...styles.button,
		...(variant === "contained" ? containedStyle : variant === "outlined" ? outlinedStyle : textStyle),
		...style,
	};

	return (
		<Button
			type={type}
			sx={{
				...sx,
			}}
			style={buttonStyle}
			variant={variant}
			onClick={onClick}
			disabled={isLoading || disabled}
			startIcon={startIcon}
			endIcon={endIcon}
			size={size}
		>
			{
				isLoading ?
					<ActivityIndicator
						size="small"
						color={"white"}
					/>
					:
					<Typography variant="button" component="span">
						{title}
					</Typography>
			}
			{children}
		</Button>
	);
};

const styles = StyleSheet.create({
	button: {
		height: 45,
		minWidth: 100,
		borderRadius: 30,
		fontWeight: "600",
		fontSize: 10,
		letterSpacing: 1,
		fontFamily: "primaryFont",
	},
});
