import { Container, Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { Image } from "react-native";
import { appConfigStore } from "../../app/stores/AppConfig";
import { cartStore } from "../../app/stores/Cart";
import { BasicButton } from "../../components/BasicButton";
import { Checkout } from "./Checkout/Index";
import { navigationStore } from "../../app/stores/Navigation";
import { userStore } from "../../app/stores/User";
import { DefaultRoute } from "../../models/enums/DefaultRoute";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { OrderItemCard } from "../../components/OrderItemCard";
import { OrderItem } from "../../models/DB";
import { isLarge } from "../../navigation/HomeStack";

export const Cart = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { darkMode } = appConfigStore((state) => state);
	const { cartItems, cartTotal, clearCart, removeFromCart } = cartStore((state) => state);
	const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);
	const { updateDefaultRoute } = navigationStore((state) => state);
	const { user: { authenticated } } = userStore((state) => state);
	const { navigateToScreen } = useScreenComponent();
	const [showSwipeHint, setShowSwipeHint] = useState(true);

	useEffect(() => {
		// Reset swipe hint whenever cart is reopened
		if (cartItems.length > 0) {
			setShowSwipeHint(true);
		}
	}, [cartItems.length]);

	const handleCheckoutStart = () => {
		if (authenticated) {
			setIsConfirmingOrder(true);
		} else {
			updateDefaultRoute(DefaultRoute.Login);
			navigateToScreen(ScreenComponent.Login);
		}
	};

	const handleCheckoutComplete = () => {
		setIsConfirmingOrder(false);
		clearCart();
	};

	const handleCheckoutReset = () => {
		setIsConfirmingOrder(false);
	};

	const handleRemoveItem = (item: OrderItem) => {
		removeFromCart(item.product.code, item.selectedVariant);
	};

	const CartContent = () => {
		return (
			<>
				{ isConfirmingOrder ? (
					<Checkout
						cartItems={ cartItems }
						onComplete={handleCheckoutComplete}
						onReset={handleCheckoutReset}
					/>
				) : (
						<Box
							sx={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
								overflow: "hidden",
								position: "relative"
							}}
						>
							{/* Header */}
							<Box
								sx={{
									boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
									backgroundColor: theme.customColors.cardBackground,
									p: 2,
									px: { xs: 2, sm: 6 },
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: 1,
									zIndex: 2
								}}
							>
								<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
									<Image
										source={require("../../assets/cart-icon.png")}
										style={{
											width: 24,
											height: 24,
											tintColor: theme.palette.text.primary
										}}
										resizeMode="contain"
									/>
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
									<Typography
										variant="h6"
										sx={{
											fontWeight: "800",
											fontFamily: "primaryFont",
											letterSpacing: 1,
											color: theme.palette.text.primary,
										}}>
										Items in your Cart
									</Typography>
								</Box>
								<Box sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "flex-end",
									justifyContent: "flex-end",
									gap: 1,
									marginLeft: "auto"
								}}>
									<BasicButton
										title="Clear"
										variant="outlined"
										onClick={() => clearCart()}
										sx={{
											width: 100,
											maxHeight: 35,
										}}
									/>
								</Box>
							</Box>

							{/* Main content area */}
							<Box
								sx={{
									flex: 1,
									display: "flex",
									flexDirection: "column",
									overflow: "visible",
									position: "relative"
								}}
							>
								{/* Container for desktop max-width */}
								<Container
									maxWidth="md"
									disableGutters={isMobile}
									sx={{
										flex: 1,
										display: "flex",
										flexDirection: "column",
										overflow: "visible",
										position: "relative"
									}}
								>
									{/* Scrollable cart items */}
									<Box
										sx={{
											flex: 1,
											overflow: "visible",
											pb: { xs: "200px", sm: "220px" }, // Add padding to make room for summary - responsive
											px: { xs: 2, sm: 3 }
										}}
									>
										{showSwipeHint && cartItems.length > 0 && (
											<Typography
												variant="caption"
												sx={{
													color: theme.palette.text.secondary,
													fontStyle: "italic",
													display: "block",
													textAlign: "center",
													mt: 4
												}}
											>
												{isLarge()
													? "Click the X button to remove items from your cart"
													: "← Swipe items to reveal remove option"}
											</Typography>
										)}
										<Grid
											container
											flexDirection="column"
											gap={1}
											pt={2}
											overflow={"visible"}
										>
											{cartItems.map((item, index) => (
												<OrderItemCard
													key={`cartItemCard_${index}`}
													item={item}
													onRemove={handleRemoveItem}
													autoOpen={showSwipeHint}
													index={index}
												/>
											))}
										</Grid>
									</Box>

									{/* Summary footer that stays within the drawer */}
									<Box
										sx={{
											position: { xs: "fixed", md: "absolute" }, // Fixed on mobile, absolute on desktop
											bottom: 0,
											left: 0,
											right: 0,
											width: { xs: "100%", sm: "100%" },
											backgroundColor: theme.customColors.cardBackground,
											boxShadow: "0px -4px 10px 0px rgba(0, 0, 0, 0.1)",
											p: 2,
											pb: { xs: 2, sm: 4 },
											zIndex: 5
										}}
									>
										<Typography
											variant="h4"
											sx={{
												fontSize: 28,
												fontWeight: "800",
												fontFamily: "primaryFont",
												letterSpacing: 1,
												color: theme.palette.text.primary,
											}}
										>
											Summary
										</Typography>

										<Grid
											container
											direction="row"
											alignItems="center"
											marginTop={2}
											marginBottom={2}
											border={2}
											padding={2}
											borderRadius={4}
											borderColor={theme.palette.divider}
											boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
										>
											<Grid item xs={6} sm={8}>
												<Typography
													variant="subtitle1"
													sx={{
														fontSize: 18,
														fontWeight: "900",
														fontFamily: "primaryFont",
														letterSpacing: 0.5,
														color: theme.palette.text.primary,
													}}
												>
													Cart total
												</Typography>
											</Grid>
											<Grid item xs={6} sm={4}>
												<Typography
													variant="subtitle1"
													sx={{
														fontSize: 18,
														fontWeight: "600",
														fontFamily: "primaryFont",
														letterSpacing: 0.5,
														color: theme.palette.text.secondary,
														textAlign: "right",
													}}
												>
													R {cartTotal.toFixed(2)}
												</Typography>
											</Grid>
										</Grid>

										<Grid
											container
											justifyContent="center"
											mt={2}
											padding={1}
										>
											<BasicButton
												sx={{
													width: { xs: "100%", sm: "50%" },
													height: 45,
													minHeight: 45
												}}
												title={authenticated ? "Confirm Order" : "Login to Confirm Order"}
												onClick={handleCheckoutStart}
											/>
										</Grid>
									</Box>
								</Container>
							</Box>
						</Box>
				)}
			</>
		);
	};

	return (
		<Box
			sx={{
				flex: 1,
				height: "100%",
				display: "flex",
				flexDirection: "column",
				backgroundColor: theme.customColors.background
			}}
		>
			{cartItems.length > 0 ? (
				<CartContent />
			) : (
				<Grid
					container
						flex={1}
						flexDirection="column"
						justifyContent="center"
						alignContent="center"
						alignItems="center"
				>
					<Image
							style={{
								resizeMode: "cover",
							margin: 12,
							width: 40,
							height: 40,
								tintColor: darkMode ? theme.palette.text.primary : "grey",
							}}
							source={require("../../assets/cart-icon.png")}
					/>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: 18,
								fontWeight: "900",
								fontFamily: "primaryFont",
								letterSpacing: 0.5,
								color: theme.palette.text.primary,
							}}
						>
							Your cart is empty
						</Typography>
				</Grid>
			)}
		</Box>
	);
};

export default Cart;
