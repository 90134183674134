import { create } from "zustand";
import { AvgOrderValue, OrderStatusCount, RevenueDataPoint, TotalOrders } from "../../models/DB";

interface DashboardState {
    orderStatuses: OrderStatusCount[];
    avgOrderValue: AvgOrderValue | null;
    totalOrders: TotalOrders | null;
    totalSales: number | null;
    revenueData: RevenueDataPoint[];
    selectedDateRange: {
        startDate: string;
        endDate: string;
    };
    setOrderStatuses: (orderStatuses: OrderStatusCount[]) => void;
    setAvgOrderValue: (avgOrderValue: AvgOrderValue) => void;
    setTotalOrders: (totalOrders: TotalOrders) => void;
    setTotalSales: (totalSales: number) => void;
    setRevenueData: (revenueData: RevenueDataPoint[]) => void;
    setSelectedDateRange: (startDate: string, endDate: string) => void;
}

export const dashboardStore = create<DashboardState>((set) => ({
    orderStatuses: [],
    avgOrderValue: null,
    totalOrders: null,
    totalSales: null,
    revenueData: [],
    selectedDateRange: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0], // First day of current month
        endDate: new Date().toISOString().split('T')[0], // Today
    },
    setOrderStatuses: (orderStatuses) => set({ orderStatuses }),
    setAvgOrderValue: (avgOrderValue) => set({ avgOrderValue }),
    setTotalOrders: (totalOrders) => set({ totalOrders }),
    setTotalSales: (totalSales) => set({ totalSales }),
    setRevenueData: (revenueData) => set({ revenueData }),
    setSelectedDateRange: (startDate, endDate) => set({ selectedDateRange: { startDate, endDate } }),
})); 