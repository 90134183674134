import React from "react";
import { BasicButton } from "../../components/BasicButton";
import { ArrowBack } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";
import { ThemeExample } from "../../components/ThemeExample";
import { ScrollView } from "react-native";

export const ThemePreview = ({ navigation }) => {
    const theme = useTheme();

    return (
        <ScrollView style={{ backgroundColor: theme.customColors.background }}>
            <Grid container m={2} mb={4}>
                <BasicButton
                    title="Back"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    sx={{
                        borderRadius: 20,
                        fontSize: 12,
                        fontFamily: "primaryFont",
                        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => navigation.goBack()}
                />
            </Grid>

            <Grid container px={2} mb={4}>
                <Typography
                    variant="h4"
                    fontFamily="primaryFont"
                    fontWeight="bold"
                    color={theme.palette.text.primary}
                >
                    Theme Preview
                </Typography>
                <Typography
                    variant="body1"
                    mt={1}
                    color={theme.palette.text.secondary}
                >
                    This screen demonstrates how to use the MUI theme system with useTheme hook across the application.
                </Typography>
            </Grid>

            <ThemeExample />
        </ScrollView>
    );
}; 