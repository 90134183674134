import { Autocomplete, TextField, Chip } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import { withStyles } from "@mui/styles";
import { primaryColor } from "../config/constants";

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const GroupItems = styled("ul")({
    padding: 0,
});

// Only include custom autofill styling if primaryColor is not black
const autofillStyles = primaryColor !== "#000000" ? {
    "& .MuiInputBase-input:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0 100px ${primaryColor}25 inset !important`,
        "-webkit-text-fill-color": "inherit",
        caretColor: "inherit",
    }
} : {};

const StyledAutocomplete = withStyles({
    root: {
        "& .MuiInputLabel-root": {
            color: "grey",
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "grey",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "grey",
            },
            "&:hover fieldset": {
                borderColor: "grey",
            },
            "&.Mui-focused fieldset": {
                borderColor: "grey",
            },
        },
        "& .MuiChip-root": {
            backgroundColor: primaryColor,
            color: "white",
            "& .MuiChip-deleteIcon": {
                color: "white",
                "&:hover": {
                    color: "rgba(255, 255, 255, 0.7)",
                },
            },
        },
        ...autofillStyles,
    },
})(Autocomplete);

interface BasicAutocompleteProps {
    name?: string;
    options: string[];
    value: string | string[];
    onChange: (newValue: string | string[]) => void;
    label: string;
    size?: "small" | "medium";
    fullWidth?: boolean;
    freeSolo?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
}

export const BasicAutocomplete: React.FC<BasicAutocompleteProps> = ({
    options,
    value,
    onChange,
    label,
    size = "medium",
    fullWidth = false,
    freeSolo = false,
    disabled = false,
    multiple = false,
    required = false,
}) => {
    const groupedOptions = options.map((option) => ({
        firstLetter: option.charAt(0).toUpperCase(),
        option,
    })).sort((a, b) => a.firstLetter.localeCompare(b.firstLetter));

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && freeSolo && multiple) {
            const inputValue = (event.target as HTMLInputElement).value.trim();
            if (inputValue) {
                const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                const newValues = Array.isArray(value) ? [...value, capitalizedValue] : [capitalizedValue];
                onChange(newValues);
                (event.target as HTMLInputElement).value = "";
                event.preventDefault();
            }
        }
    };

    return (
        <StyledAutocomplete
            required={required}
            options={groupedOptions.map(x => x.option)}
            value={value}
            onChange={(_, newValue) => {
                if (multiple) {
                    onChange(newValue as string[]);
                } else {
                    onChange(newValue as string);
                }
            }}
            freeSolo={freeSolo}
            fullWidth={fullWidth}
            size={size}
            disabled={disabled}
            multiple={multiple}
            sx={{
                opacity: disabled ? 0.4 : 1
            }}
            groupBy={(option) => option.charAt(0).toUpperCase()}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    onKeyDown={handleKeyDown}
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader sx={{ backgroundColor: primaryColor, color: "white" }} >{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
        />
    );
}; 