import { Image, View } from "react-native";
import { appConfigStore } from "../app/stores/AppConfig";
import { AppHeaderName } from "../config/constants";
import { Typography, useTheme, Box } from "@mui/material";

interface AppLogoProps {
	showText?: boolean;
	size?: number;
	sx?: any;
}

export const AppLogo = ({ showText = true, size = 50, sx = {} }: AppLogoProps) => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const theme = useTheme();
	const heroImage = darkMode
		? require("../assets/faded-logo.png")
		: require("../assets/faded-logo.png");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				marginTop: showText ? 1.5 : 0,
				...sx
			}}
		>
			<Image
				style={{
					width: size,
					aspectRatio: 1,
					resizeMode: "contain",
					borderRadius: size / 2, // 50% of width for a circle
				}}
				source={heroImage}
			/>
			{showText && (
				<Typography
					variant="h5"
					sx={{
						marginTop: 2.25,
						fontFamily: "logoFont",
						letterSpacing: 0.5,
						fontWeight: 900,
						color: theme.palette.text.primary
					}}
				>
					{AppHeaderName}
				</Typography>
			)}
		</Box>
	);
};
