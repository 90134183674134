import { Button, Grid, Typography, useTheme } from "@mui/material";
import { primaryColor } from "../config/constants";

const ColorSelector = ({ variantType, selectedColor, onSelectColor, colors, darkMode = false }) => {
    const theme = useTheme();

    return (
        <Grid mt={2}>
            <Typography
                variant="body1"
                gutterBottom={!selectedColor}
                mt={2}
                color={theme.palette.text.primary}
            >
                {variantType}:
            </Typography>
            <Typography
                variant="body2"
                display={selectedColor ? "flex" : "none"}
                color={theme.palette.text.secondary}
                mb={2}
            >
                Selected {variantType}: {selectedColor}
            </Typography>
            <Grid container spacing={2} alignSelf={"center"}>
                {colors.map((color) => (
                    <Grid item key={color} >
                        <Button
                            variant={"contained"}
                            onClick={() => onSelectColor(color)}
                            style={{
                                backgroundColor: color,
                                minWidth: selectedColor === color ? 40 : 30,
                                minHeight: selectedColor === color ? 40 : 30,
                                borderRadius: selectedColor === color ? 20 : 15,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

const DefaultSelector = ({ options, variantType, selectedVariant, onSelectVariant, darkMode = false }) => {
    const theme = useTheme();

    return (
        <Grid mt={2}>
            <Typography
                variant="body1"
                gutterBottom={!selectedVariant}
                mt={2}
                color={theme.palette.text.primary}
            >
                {variantType}:
            </Typography>
            <Typography
                variant="body2"
                display={selectedVariant ? "flex" : "none"}
                color={theme.palette.text.secondary}
                mb={2}
            >
                Selected {variantType}: {selectedVariant}
            </Typography>
            <Grid container spacing={1}>
                {options.map((option) => (
                    <Grid item key={option}>
                        <Button
                            variant="outlined"
                            onClick={() => onSelectVariant(option)}
                            sx={{
                                borderColor: selectedVariant === option ? "transparent" : primaryColor,
                                color: selectedVariant === option ? "white" : darkMode ? theme.palette.text.primary : primaryColor,
                                backgroundColor: selectedVariant === option ? (darkMode ? primaryColor : "black") : "transparent",
                                "&:hover": {
                                    borderColor: theme.customColors?.hover || "lightgrey",
                                    backgroundColor: selectedVariant === option ? primaryColor : "transparent",
                                },
                            }}
                        >
                            {option}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export const VariantSelector = ({ variantType, variantOptions, selectedVariant, onSelectVariant, darkMode = false }) => {
    switch (variantType.toLowerCase()) {
        case "color":
            return (
                <ColorSelector
                    variantType={variantType}
                    colors={variantOptions}
                    selectedColor={selectedVariant}
                    onSelectColor={(color) => onSelectVariant(color)}
                    darkMode={darkMode}
                />
            );
        default:
            return (
                <DefaultSelector
                    variantType={variantType}
                    options={variantOptions}
                    selectedVariant={selectedVariant}
                    onSelectVariant={(variant) => onSelectVariant(variant)}
                    darkMode={darkMode}
                />
            );
    }
};
