import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";
import { Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Fade, SelectChangeEvent, FormHelperText, useTheme } from "@mui/material";
import { DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { cities, ShippingFormCity, states } from "../../../models/ShippingFormModel";
import { BasicTextField } from "../../../components/BasicTextField";

interface ShippingFormProps {
	ref: React.Ref<any>;
}

const ShippingFormComponent = forwardRef(({ }: ShippingFormProps, ref) => {
	const theme = useTheme();
	const [addressLine1, setAddress1] = useState("");
	const [addressLine2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [region, setRegion] = useState("");
	const [zip, setZip] = useState("");
	const [suburb, setSuburb] = useState("");
	const [country, setCountry] = useState("South Africa");
	const [extraCharge, setExtraCharge] = useState(0);
	const [selectedCity, setSelectedCity] = useState<ShippingFormCity | null>(null);
	const [showNextDayDelivery, setShowNextDayDelivery] = useState(false);
	const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
	const [deliveryOption, setDeliveryOption] = useState<"standard" | "nextDay" | null>(null);
	const [isAddressFieldFocused, setIsAddressFieldFocused] = useState(false);

	const [errors, setErrors] = useState({
		addressLine1: false,
		city: false,
		region: false,
		zip: false,
		deliveryOption: false,
		suburb: false
	});

	useImperativeHandle(ref, () => ({
		getFormData: () => {
			const newErrors = {
				addressLine1: !addressLine1,
				city: !city,
				region: !region,
				zip: !zip,
				deliveryOption: deliveryOption === null,
				suburb: deliveryOption === "nextDay" && !suburb
			};

			setErrors(newErrors);

			if (Object.values(newErrors).some(error => error)) {
				return null;
			}

			const details: ShippingDetails = {
				addressLine1,
				addressLine2,
				city,
				region,
				zip,
				country
			};

			const deliveryDetails: DeliveryDetails = {
				deliveryType: deliveryOption || "",
				deliveryFee: extraCharge,
				suburb: deliveryOption === "nextDay" ? suburb : "",
			};

			return { ...details, ...deliveryDetails };
		}
	}));

	const handleCityChange = useCallback((event: SelectChangeEvent<string>) => {
		const cityName = event.target.value;
		const city = cities.find(c => c.name === cityName) || null;
		setSelectedCity(city);
		setCity(cityName);
		setShowNextDayDelivery(!!city?.nextDayDelivery);
		setShowDeliveryOptions(!!city?.standardDelivery || !!city?.nextDayDelivery);
		setErrors(prev => ({ ...prev, city: false }));

		if (!city?.nextDayDelivery) {
			setDeliveryOption(null);
			setSuburb("");
		}
		if (!city?.standardDelivery && !city?.nextDayDelivery) {
			setDeliveryOption(null);
			setExtraCharge(0);
		}
	}, []);

	const handleDeliveryOptionChange = useCallback((option: "standard" | "nextDay") => {
		setDeliveryOption(option);
		setExtraCharge(option === "nextDay" ? 150 : 100);
		setErrors(prev => ({ ...prev, deliveryOption: false }));
	}, []);

	const handleSuburbChange = useCallback((event: SelectChangeEvent<string>) => {
		setSuburb(event.target.value as string);
		setErrors(prev => ({ ...prev, suburb: false }));
	}, []);

	const handleAddress1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setAddress1(newValue);
		setErrors(prev => ({ ...prev, addressLine1: false }));
	}, []);

	const handleAddress1Focus = useCallback(() => {
		setIsAddressFieldFocused(true);
	}, []);

	const handleAddress1Blur = useCallback(() => {
		setIsAddressFieldFocused(false);
	}, []);

	const handleAddress2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setAddress2(event.target.value);
	}, []);

	const handleRegionChange = useCallback((event: SelectChangeEvent<string>) => {
		setRegion(event.target.value as string);
		setErrors(prev => ({ ...prev, region: false }));
	}, []);

	const handleZipChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setZip(event.target.value);
		setErrors(prev => ({ ...prev, zip: false }));
	}, []);

	return (
		<Box>
			<Typography
				variant="h6"
				gutterBottom
				textAlign="center"
				mt={2}
				mb={4}
				color={theme.palette.text.primary}
			>
				Let's get your order to you
			</Typography>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={12}
				>
					<FormControl fullWidth variant="outlined" required error={errors.city}>
						<InputLabel id="city-label" sx={{ color: theme.palette.text.secondary }}>City</InputLabel>
						<Select
							labelId="city-label"
							id="city"
							value={city}
							onChange={handleCityChange}
							autoComplete="shipping address-level2"
							sx={{ color: theme.palette.text.primary }}
						>
							{cities.map((city) => (
								<MenuItem key={city.name} value={city.name}>
									{city.name}
								</MenuItem>
							))}
						</Select>
						{errors.city && <FormHelperText>City is required</FormHelperText>}
					</FormControl>
				</Grid>
				<Fade in={showDeliveryOptions} timeout={600}>
					<Grid
						container
						item
						xs={12}
						spacing={4}
						mb={1}
						sx={{ display: showDeliveryOptions ? "flex" : "none" }}
					>
						{showNextDayDelivery && (
							<Grid item sm={12} md={6} alignSelf="center">
								<FormControlLabel
									required={selectedCity?.nextDayDelivery && deliveryOption === null}
									control={
										<Checkbox
											value="nextDay"
											checked={deliveryOption === "nextDay"}
											onChange={() => handleDeliveryOptionChange("nextDay")}
											name="sameDayDelivery"
											color="primary"
										/>
									}
									label={
										<>
											<Typography color="green" fontWeight="bold" variant="body1">Next Day Delivery (R150)</Typography>
											<Typography color={theme.palette.text.secondary} variant="body2">To supported areas only</Typography>
										</>
									}
									sx={{ xs: { width: "100%", justifyContent: "space-evenly" }, sm: {} }}
								/>
							</Grid>
						)}
						{selectedCity?.standardDelivery && (
							<Grid item sm={12} md={6} alignSelf="center">
								<FormControlLabel
									required={selectedCity?.standardDelivery && deliveryOption === null}
									control={
										<Checkbox
											value="standard"
											checked={deliveryOption === "standard"}
											onChange={() => handleDeliveryOptionChange("standard")}
											name="standardDelivery"
											color="primary"
										/>
									}
									label={
										<>
											<Typography fontWeight="bold" variant="body1" color={theme.palette.text.primary}>Standard Delivery (R110)</Typography>
											<Typography color={theme.palette.text.secondary} variant="body2">Estimated 2-3 days</Typography>
										</>
									}
									sx={{ xs: { width: "100%", justifyContent: "space-evenly" }, sm: {} }}
								/>
							</Grid>
						)}
						{errors.deliveryOption && (
							<Grid item xs={12}>
								<FormHelperText error>Please select a delivery option</FormHelperText>
							</Grid>
						)}
						<Fade in={deliveryOption === "nextDay"} timeout={600}>
							<Grid
								item
								xs={12}
								sm={6}
								mt={1}
								sx={{ display: deliveryOption === "nextDay" ? "flex" : "none" }}
							>
								<FormControl fullWidth variant="outlined" required={deliveryOption === "nextDay"} error={errors.suburb && deliveryOption === "nextDay"}>
									<InputLabel id="suburb-label" sx={{ color: theme.palette.text.secondary }}>Suburb</InputLabel>
									<Select
										labelId="suburb-label"
										id="suburb"
										value={suburb}
										onChange={handleSuburbChange}
										label="Suburb"
										sx={{ color: theme.palette.text.primary }}
									>
										<MenuItem value="" disabled>
											Select a suburb
										</MenuItem>
										{selectedCity?.suburbs.map((suburb) => (
											<MenuItem
												key={suburb.name}
												value={suburb.name}
												disabled={!suburb.selectable}
											>
												{suburb.name}
											</MenuItem>
										))}
									</Select>
									{errors.suburb && deliveryOption === "nextDay" && <FormHelperText>Please select a suburb</FormHelperText>}
								</FormControl>
							</Grid>
						</Fade>
					</Grid>
				</Fade>
				<Grid
					item
					xs={12}
				>
					<BasicTextField
						required
						id="address1"
						name="address1"
						label="Address line 1"
						fullWidth
						variant="outlined"
						onChange={handleAddress1Change}
						onFocus={handleAddress1Focus}
						onBlur={handleAddress1Blur}
						autoComplete="shipping street-address"
						error={errors.addressLine1}
						helperText={errors.addressLine1 ? "Address is required" : ""}
						value={addressLine1}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<BasicTextField
						id="address2"
						name="address2"
						label="Address line 2"
						fullWidth
						variant="outlined"
						onChange={handleAddress2Change}
						autoComplete="shipping address-line2"
						value={addressLine2}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<FormControl fullWidth variant="outlined" required error={errors.region}>
						<InputLabel id="state-label" sx={{ color: theme.palette.text.secondary }}>State/Province/Region</InputLabel>
						<Select
							labelId="state-label"
							id="state"
							value={region}
							onChange={handleRegionChange}
							autoComplete="shipping address-level1"
							sx={{ color: theme.palette.text.primary }}
						>
							{
								states.filter(states => states.selectable).map(state => (
									<MenuItem key={state.name} value={state.name}>
										{state.name}
									</MenuItem>
								))
							}
						</Select>
						{errors.region && <FormHelperText>Region is required</FormHelperText>}
					</FormControl>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<BasicTextField
						required
						id="zip"
						name="zip"
						label="Zip / Postal code"
						fullWidth
						variant="outlined"
						onChange={handleZipChange}
						autoComplete="shipping postal-code"
						error={errors.zip}
						helperText={errors.zip ? "Postal code is required" : ""}
						value={zip}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextField
						id="country"
						name="country"
						label="Country"
						fullWidth
						variant="outlined"
						value="South Africa"
						disabled
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
});

export const ShippingForm = React.memo(ShippingFormComponent);
