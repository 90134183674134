// Base URL
// export const baseUrl = 'http://localhost:3000';
export const baseUrl = 'https://europe-west1-toefadeit.cloudfunctions.net/api';


// App prefix
export const prefix = "stayfaded://";

// App
export const CompanyName = "Stay Faded";
export const AppHeaderName = "StayFaded";
export const FooterAppName = "Faded";
export const AppLogo = "faded-logo.png";
export const CURRENCY = "R";

// Colors
type HexColor = string;
export const brandColor: HexColor = "#800080";
export const primaryColor = brandColor !== "#000000" && brandColor !== "#FFFFFF" ? brandColor : "#0096FF";
export const stateErrorColor = "#C41E3A";
export const stateSuccessColor = "#228B22";
export const stateWarningColor = "#FFA500";
export const stateInfoColor = "#0096FF";
export const stateDisabledColor = "#D3D3D3";

// Theme Background Colors
export const lightBackground = "#FFFFFF";
export const lightBackgroundSecondary = "#F9F9F9";
export const lightBackgroundTertiary = "#FAFAFA";

export const darkBackground = "#121212";
export const darkBackgroundSecondary = "#1E1E1E";
export const darkBackgroundTertiary = "#333333";

// Text Colors
export const lightTextPrimary = "#000000";
export const lightTextSecondary = "#666666";
export const lightTextMuted = "#999999";

export const darkTextPrimary = "#FFFFFF";
export const darkTextSecondary = "#CCCCCC";
export const darkTextMuted = "#999999";

// Theme Colors
export const themeColors = {
    light: {
        // Backgrounds
        background: lightBackgroundTertiary,
        backgroundSecondary: lightBackgroundSecondary,
        backgroundTertiary: lightBackgroundTertiary,
        
        // Text
        textPrimary: lightTextPrimary,
        textSecondary: lightTextSecondary,
        textMuted: lightTextMuted,
        
        // UI Elements
        border: "#E0E0E0",
        divider: "#EEEEEE",
        hover: "#F5F5F5",
        buttonOutline: primaryColor,
        
        // Functional
        cardBackground: lightBackground,
        navBackground: lightBackground,
        inputBackground: lightBackground,
    },
    dark: {
        // Backgrounds
        background: darkBackground,
        backgroundSecondary: darkBackgroundSecondary,
        backgroundTertiary: darkBackgroundTertiary,
        
        // Text
        textPrimary: darkTextPrimary,
        textSecondary: darkTextSecondary,
        textMuted: darkTextMuted,
        
        // UI Elements
        border: "#444444",
        divider: "#333333",
        hover: "#333333",
        buttonOutline: "#FFFFFF",
        
        // Functional
        cardBackground: darkBackgroundSecondary,
        navBackground: darkBackground,
        inputBackground: darkBackgroundTertiary,
    }
};

// Root paths
export const RootPaths = {
    Shop: "shop",
    Product: "product",
    How_It_Works: "tour",
    Bookings: "bookings",
    Orders: "orders",
    Contact_Us: "contactus",
    Cart: "cart",
    Register: "register",
    Login: "login",
    Portal: {
        path: "portal",
        screens: {
            Dashboard: "dashboard",
            Products: "products", 
            Orders: "orders",
            Reports: "reports",
        }
    },
    NotFound: "*",
}

export const VAT_PERCENTAGE = 15;

// API Endpoints
export const ApiEndpoints = {
    Auth: {
        SignIn: `${baseUrl}/auth/signin`,
        SignUp: `${baseUrl}/auth/signup`,
        SignOut: `${baseUrl}/auth/signout`,
    },
    Orders: {
        GetById: (id: string) => `${baseUrl}/orders/${id}`,
        Create: `${baseUrl}/orders`,
    },
    Products: {
        GetAll: `${baseUrl}/products`,
        GetById: (id: string) => `${baseUrl}/products/${id}`,
    },
    Admin: {
        CreateProduct: `${baseUrl}/admin/products`,
        UpdateProduct: `${baseUrl}/admin/products`,
        GetAllOrders: `${baseUrl}/admin/orders`,
        DeleteProduct: (id: string, code: string) => `${baseUrl}/admin/products/${id}/${code}`,
        UpdateOrder: `${baseUrl}/admin/orders`,
        SignIn: `${baseUrl}/admin/signin`,
        SignUp: `${baseUrl}/admin/signup`,
    },
    Dashboard: {
        GetOrderStatuses: `${baseUrl}/dashboard/order-statuses`,
        GetAvgOrderValue: (startDate: string, endDate: string) => 
            `${baseUrl}/dashboard/avg-order-value?start_date=${startDate}&end_date=${endDate}`,
        GetTotalOrders: (startDate: string, endDate: string) => 
            `${baseUrl}/dashboard/total-orders?start_date=${startDate}&end_date=${endDate}`,
        GetTotalSales: (startDate: string, endDate: string) => 
            `${baseUrl}/dashboard/total-sales?start_date=${startDate}&end_date=${endDate}`,
        GetRevenueData: `${baseUrl}/dashboard/revenue-data`,
    },
};

// Menu Items
export const MenuItems = {
    UserAccess: "User Access",
    Products: "Products",
    Orders: "Orders",
};