import { DoneRounded, Upload } from "@mui/icons-material"
import { TableRow, TableCell, Collapse, Box, Grid, Typography, Button, FormControlLabel, Switch, useTheme, InputAdornment } from "@mui/material"
import { BasicButton } from "../../../../../components/BasicButton"
import { Image } from "react-native"
import { Product } from "../../../../../models/DB"
import { useEffect, useState } from "react"
import { productStore } from "../../../../../app/stores/Product"
import { CURRENCY, primaryColor } from "../../../../../config/constants"
import PortalService from "../../../../../services/supabase/PortalService"
import { BasicTextField } from "../../../../../components/BasicTextField"
import { BasicAutocomplete } from "../../../../../components/BasicAutocomplete"
import { calculateDiscountedPrice } from "../../../../../utils/customFunctions"

interface ProductEditorProps {
    product: Product;
    open: boolean;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    setIsDeleted: (isDeleted: boolean) => void;
}

export const ProductEditor = ({
    product,
    open,
    isEditing,
    setIsEditing,
    setIsDeleted,
}: ProductEditorProps) => {
    const theme = useTheme();
    const portalService = PortalService();

    const { allProducts, updateProducts } = productStore();
    const [isLoading, setIsLoading] = useState(false);
    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string | null }>(
        Object.keys(product.images).reduce((acc, key) => ({ ...acc, [key]: product.images[key].url }), {})
    );
    const [productData, setProductData] = useState<Product>({
        ...product,
        variantType: product.variantType || "",
        discount: product.discount || 0,
        inStock: product.inStock || false,
        images: product.images || {},
        selectedVariant: product.selectedVariant || "",
        variantOptions: product.variantOptions || [],
        created: product.created || "",
        updated: product.updated || "",
        category: product.category || "",
    });

    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({});
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
    const [uniqueVariants, setUniqueVariants] = useState<string[]>([]);
    const [uniqueVariantOptions, setUniqueVariantOptions] = useState<string[]>([]);

    useEffect(() => {
        const categories = new Set<string>();
        const variants = new Set<string>();

        allProducts.forEach(product => {
            if (product.category) {
                categories.add(product.category);
            }
            if (product.variantType) {
                variants.add(product.variantType);
            }
        });

        setUniqueCategories(Array.from(categories));
        setUniqueVariants(Array.from(variants));
    }, [allProducts]);

    useEffect(() => {
        if (productData.variantType) {
            const options = new Set<string>();
            allProducts.forEach(product => {
                if (product.variantType === productData.variantType) {
                    product.variantOptions.forEach(option => options.add(option));
                }
            });
            setUniqueVariantOptions(Array.from(options));
        } else {
            setUniqueVariantOptions([]);
        }
    }, [productData.variantType, allProducts]);



    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        setIsLoading(true);
        console.log("🚀 ~ handleSave ~ productData:", JSON.stringify(productData, null, 2));
        const validImageFiles = Object.values(selectedFiles).filter((file): file is File => file !== null);
        const updatedProduct = await portalService.updateProduct(productData, validImageFiles);

        if (updatedProduct) {
            const updatedProducts = allProducts.map((product: Product) => {
                if (product.id !== updatedProduct.id) {
                    return product;
                }
                return updatedProduct;
            });

            updateProducts(updatedProducts);
        } else {
            console.log("Error updating product");
        }

        setIsLoading(false);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setProductData({
            ...product,
            variantType: product.variantType || "",
            discount: product.discount || 0,
            inStock: product.inStock || false,
            images: product.images || {},
            selectedVariant: product.selectedVariant || "",
            variantOptions: product.variantOptions || [],
            created: product.created || "",
            updated: product.updated || "",
            category: product.category || "",
        });
        setImagePreviews(Object.keys(product.images).reduce((acc, key) => ({ ...acc, [key]: product.images[key].url }), {}));
        setIsEditing(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        const deletedProduct = await portalService.deleteProduct(productData.id, productData.code);
        if (deletedProduct) {
            const updatedProducts = allProducts.filter((product: Product) => product.id !== deletedProduct.id);
            updateProducts(updatedProducts);
            setIsDeleted(true);
        }
        setIsLoading(false);
    };

    const handleImageUpload = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const renamedFile = new File([file], `${type}`, { type: file.type });

                setProductData({
                    ...productData,
                    images: {
                        ...productData.images,
                        [type]: { url: reader.result as string }
                    }
                });
                setImagePreviews({ ...imagePreviews, [type]: reader.result as string });
                setSelectedFiles({ ...selectedFiles, [type]: renamedFile });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <TableRow>
            <TableCell
                style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: theme.palette.mode === "dark"
                        ? theme.palette.background.default
                        : theme.palette.grey[100]
                }}
                colSpan={6}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ marginX: 2, marginY: 4, padding: 1 }}>
                        <Grid container spacing={2} justifyContent={"space-between"}>
                            <Grid item xs={3} sm={6}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Details
                                </Typography>
                            </Grid>
                            <Grid item container xs={9} sm={6} justifyContent={{ xs: "flex-end", sm: "flex-end" }}>
                                {isEditing ? (
                                    <Grid>
                                        <BasicButton
                                            title="Save"
                                            variant="contained"
                                            onClick={handleSave}
                                            startIcon={<DoneRounded />}
                                            sx={{ marginRight: 1, maxHeight: 30, fontSize: 9 }}
                                            style={{ backgroundColor: theme.palette.success.main }}
                                            isLoading={isLoading}
                                        />
                                        <BasicButton
                                            title="Cancel"
                                            variant="outlined"
                                            onClick={handleCancel}
                                            sx={{
                                                maxHeight: 30,
                                                fontSize: 9,
                                                backgroundColor: theme.palette.background.paper
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid>
                                        <BasicButton
                                            title="Edit"
                                            variant="outlined"
                                            onClick={handleEdit}
                                                sx={{
                                                    marginRight: 1,
                                                    maxHeight: 30,
                                                    fontSize: 9,
                                                    backgroundColor: theme.palette.background.paper
                                                }}
                                        />
                                        <BasicButton
                                            title="Delete"
                                            variant="contained"
                                            onClick={handleDelete}
                                            sx={{ maxHeight: 30, fontSize: 9 }}
                                                style={{ backgroundColor: theme.palette.error.main }}
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2.5} mt={{ xs: 6, sm: 0 }}>
                            <Grid item xs={12} md={4} mb={4}>
                                <Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            Product Images
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            mt={1}
                                            sx={{
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            {`Select an image to add or replace`}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            mb={3}
                                            sx={{
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            {`You can upload up to 3 images`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent={"center"}>
                                    {/* Product Images */}
                                    {["thumbnail", "image1", "image2"].map((type) => (
                                        <Grid item xs={4} key={type} sx={{ opacity: isEditing ? 1 : 0.3 }}>
                                            <Button
                                                fullWidth
                                                variant="text"
                                                component="label"
                                                disabled={!isEditing}
                                                sx={{
                                                    aspectRatio: "1 / 1",
                                                    padding: 0,
                                                    borderRadius: 6,
                                                    overflow: "hidden",
                                                    borderStyle: "solid",
                                                    borderWidth: 1.75,
                                                    borderColor: primaryColor,
                                                    backgroundColor: "white",
                                                    "&:hover": {
                                                        opacity: 0.8
                                                    }
                                                }}
                                            >
                                                <Image
                                                    source={
                                                        imagePreviews[type] ?
                                                            { uri: imagePreviews[type] } :
                                                            require("../../../../../assets/no-image.png")
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        aspectRatio: "1 / 1",
                                                        resizeMode: "contain",
                                                    }}
                                                />
                                                <input type="file" hidden disabled={!isEditing} onChange={handleImageUpload(type)} />
                                            </Button>
                                            <Typography
                                                variant="body2"
                                                mt={1.5}
                                                sx={{
                                                    fontFamily: "primaryFont",
                                                    letterSpacing: 0.5,
                                                    textAlign: "center"
                                                }}
                                            >
                                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-zA-Z])(\d)/g, "$1 $2")}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2} direction={{ xs: "column", md: "row" }}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <BasicTextField
                                                    disabled
                                                    sx={{ backgroundColor: theme.palette.background.paper }}
                                                    size="small"
                                                    label="SKU"
                                                    value={productData.code}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <BasicTextField
                                                    sx={{ backgroundColor: theme.palette.background.paper }}
                                                    size="small"
                                                    label="Name"
                                                    value={productData.name}
                                                    onChange={(e) => setProductData({ ...productData, name: e.target.value })}
                                                    disabled={!isEditing}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <BasicTextField
                                                    sx={{ backgroundColor: theme.palette.background.paper }}
                                                    size="small"
                                                    label="Price"
                                                    value={productData.price}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === "") {
                                                            setProductData({ ...productData, price: "" as any });
                                                        } else {
                                                            const numValue = parseFloat(value);
                                                            setProductData({ ...productData, price: isNaN(numValue) ? 0 : numValue });
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value === "" || isNaN(parseFloat(e.target.value))) {
                                                            setProductData({ ...productData, price: 0 });
                                                        }
                                                    }}
                                                    disabled={!isEditing}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <BasicTextField
                                                    sx={{ backgroundColor: theme.palette.background.paper }}
                                                    size="small"
                                                    label="Stock On Hand"
                                                    value={productData.stockOnHand}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === "") {
                                                            setProductData({ ...productData, stockOnHand: "" as any });
                                                        } else {
                                                            const intValue = parseInt(value);
                                                            setProductData({ ...productData, stockOnHand: isNaN(intValue) ? 0 : intValue });
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
                                                            setProductData({ ...productData, stockOnHand: 0 });
                                                        }
                                                    }}
                                                    disabled={!isEditing}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <BasicAutocomplete
                                                    name="category"
                                                    label="Category"
                                                    fullWidth
                                                    size="small"
                                                    options={uniqueCategories}
                                                    value={productData.category || ""}
                                                    onChange={(newValue) => {
                                                        setProductData({ ...productData, category: Array.isArray(newValue) ? newValue[0] : newValue });
                                                    }}
                                                    disabled={!isEditing}
                                                    freeSolo
                                                />
                                            </Grid>
                                            <Grid item>
                                                <BasicTextField
                                                    sx={{ backgroundColor: theme.palette.background.paper }}
                                                    size="small"
                                                    label="Discount"
                                                    value={productData.discount}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === "") {
                                                            setProductData({ ...productData, discount: "" as any });
                                                        } else {
                                                            const numValue = parseFloat(value);
                                                            setProductData({ ...productData, discount: isNaN(numValue) ? 0 : numValue });
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value === "" || isNaN(parseFloat(e.target.value))) {
                                                            setProductData({ ...productData, discount: 0 });
                                                        }
                                                    }}
                                                    disabled={!isEditing}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                    }}
                                                />
                                                {productData?.price && (productData?.discount ?? 0) > 0 && (
                                                    <Typography
                                                        variant="caption"
                                                        color="green"
                                                        sx={{
                                                            display: "block",
                                                            mt: 0.5,
                                                            fontFamily: "primaryFont",
                                                            letterSpacing: 0.5
                                                        }}
                                                    >
                                                        Price after discount: {CURRENCY} {calculateDiscountedPrice(productData.price, productData.discount)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <BasicAutocomplete
                                                    freeSolo
                                                    name="variantType"
                                                    label="Variant Type"
                                                    fullWidth
                                                    size="small"
                                                    options={uniqueVariants}
                                                    value={productData.variantType || ""}
                                                    onChange={(newValue) => setProductData({ ...productData, variantType: newValue.toString() })}
                                                    disabled={!isEditing}
                                                />
                                            </Grid>
                                            <Grid item mt={2}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    mb={1}
                                                    sx={{
                                                        fontFamily: "primaryFont",
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    {productData.variantType ? `Select the variant options for type: ${productData.variantType}` : "Select a variant type above to select its options."}
                                                </Typography>
                                                <BasicAutocomplete
                                                    freeSolo
                                                    required
                                                    multiple
                                                    name="variantOptions"
                                                    label="Variant Options"
                                                    fullWidth
                                                    size="medium"
                                                    options={uniqueVariantOptions}
                                                    value={productData.variantOptions || []}
                                                    onChange={(newValue) => setProductData({
                                                        ...productData,
                                                        variantOptions: Array.isArray(newValue) ? newValue : [newValue]
                                                    })}
                                                    disabled={!isEditing || !productData.variantType}
                                                />
                                            </Grid>
                                            <Grid item alignSelf="flex-end" mr={1}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={productData.inStock}
                                                            onChange={(e) => setProductData({ ...productData, inStock: e.target.checked })}
                                                            disabled={!isEditing}
                                                            color={"secondary"}
                                                        />
                                                    }
                                                    label="In Stock"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}
