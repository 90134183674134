import {
	Grid,
	Typography,
	useTheme,
	Skeleton,
	Fade
} from "@mui/material";
import { StyleSheet, Platform } from "react-native";
import { Product } from "../models/DB";
import { ProductItem } from "./ProductItem";
import { navigationStore } from "../app/stores/Navigation";
import { useCallback, useEffect, useState } from "react";
import { AutoLoadButton } from "./AutoLoadButton";

interface ProductItemProps {
	products: Product[];
	selectedCategory: string;
	navigation: any;
	onLoadingComplete?: () => void;
}

// ProductSkeleton component to show while products are loading
const ProductSkeleton = () => {
	const theme = useTheme();
	// Create an array of 12 items (same as BATCH_SIZE)
	const skeletonItems = Array.from({ length: 12 }, (_, i) => i);

	return (
		<>
			{skeletonItems.map((item) => (
				<Grid
					item
					key={`skeleton_${item}`}
					xs={5.35}
					sm={3.5}
					lg={2.5}
					sx={{
						backgroundColor: theme.customColors.cardBackground,
						flexDirection: "column",
					}}
					boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
					borderColor={theme.palette.divider}
					borderRadius={4}
					padding={1.5}
				>
					<Grid item marginBottom={2}>
						<Skeleton
							variant="rectangular"
							width="100%"
							height={0}
							sx={{
								paddingTop: "100%", // Creates a square aspect ratio
								borderRadius: 2,
							}}
						/>
					</Grid>
					<Grid item>
						<Skeleton variant="text" width="80%" height={24} />
						<Skeleton variant="text" width="40%" height={20} />
					</Grid>
				</Grid>
			))}
		</>
	);
};

export const ProductsLayout = ({
	products,
	navigation,
	onLoadingComplete,
	selectedCategory,
}: ProductItemProps) => {
	const { sideMenuIsExpanded } = navigationStore(state => state);
	const theme = useTheme();
	const [windowDimensions, setWindowDimensions] = useState({
		width: typeof window !== "undefined" ? window.innerWidth : 1024,
		height: typeof window !== "undefined" ? window.innerHeight : 768
	});
	const [visible, setVisible] = useState(true);

	// Function to handle window resize events
	useEffect(() => {
		// Only run this code on web platform
		if (Platform.OS === "web") {
			const handleResize = () => {
				setWindowDimensions({
					width: window.innerWidth,
					height: window.innerHeight
				});
			};

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}
	}, []);

	// Lazy load products to improve performance
	const [visibleProducts, setVisibleProducts] = useState<Product[]>([]);
	const [loadingMore, setLoadingMore] = useState(false);
	const BATCH_SIZE = 12;

	useEffect(() => {
		// Reset visible products when products list changes
		setVisibleProducts([]);
		loadMoreProducts(true);
	}, [products]);

	useEffect(() => {
		setVisible(false);
		const timeout = setTimeout(() => {
			setVisible(true);
		}, 300);

		return () => clearTimeout(timeout);
	}, [selectedCategory]);

	const loadMoreProducts = useCallback((isInitial = false) => {
		if (loadingMore && !isInitial) return;

		setLoadingMore(true);
		setTimeout(() => {
			const currentLength = visibleProducts.length;
			const newProducts = products.slice(0, currentLength + BATCH_SIZE);

			setVisibleProducts(newProducts);
			setLoadingMore(false);

			// Call onLoadingComplete callback if it exists and this is the initial load
			if (isInitial && onLoadingComplete) {
				onLoadingComplete();
			}
		}, 50); // Small timeout to prevent UI blocking
	}, [products, visibleProducts, loadingMore, onLoadingComplete]);

	// Empty list display
	if (products.length === 0) {
		// Call onLoadingComplete to notify parent that loading is done
		if (onLoadingComplete) {
			onLoadingComplete();
		}

		return (
			<Typography
				variant="body1"
				sx={{
					...styles.subtitle,
					color: theme.palette.text.primary,
				}}
			>
				{`No items available`}
			</Typography>
		);
	}

	return (
		<Fade in={visible} easing={{ enter: "ease-in-out", exit: "ease-in-out" }} timeout={400}>
			<Grid
				container
				gap={{ xs: 3, md: 4 }}
				justifyContent={{ xs: "center", md: "flex-start" }}
				marginLeft={{
					xs: 0,
					md: sideMenuIsExpanded ? 0 : 4
				}}
				sx={{
					flex: 1,
					transition: "all 0.3s ease-in-out",
				}}
				marginBottom={8}
			>
				{/* Show skeleton loaders when there are no visible products but loading is in progress */}
				{visibleProducts.length === 0 && loadingMore ? (
					<ProductSkeleton />
				) : (
					visibleProducts.map((product, index) => (
						<ProductItem
							key={`productItem_${index}_${product.id}`}
							product={product}
							navigation={navigation}
						/>
					))
				)}
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
					marginTop={4}
					marginBottom={2}
				>
					<AutoLoadButton
						onLoadMore={() => loadMoreProducts()}
						isLoading={loadingMore}
						isVisible={visibleProducts.length < products.length}
					/>
				</Grid>
			</Grid>
		</Fade>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	screenName: {
		margin: 16,
		alignSelf: "center",
		fontWeight: "500",
	},
	name: {
		fontSize: 16,
		fontWeight: "600",
		letterSpacing: 0.5,
	},
	price: {
		fontSize: 14,
		fontWeight: "600",
		letterSpacing: 0.5,
	},
	title: {
		flex: 1,
		fontSize: 26,
		fontWeight: "600",
		letterSpacing: 1,
		color: "black",
	},
	subtitle: {
		fontSize: 14,
		fontWeight: "400",
		letterSpacing: 0.5,
	},
	chip: {
		marginRight: 8,
	},
});
