import React, { useEffect, useState } from "react";
import { Animated, ScrollView, Image, View } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { BasicTextField } from "../../components/BasicTextField";
import { BasicPasswordField } from "../../components/BasicPasswordField";
import AuthService from "../../services/supabase/AuthService";
import { primaryColor, stateErrorColor, stateSuccessColor } from "../../config/constants";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { useIsFocused } from "@react-navigation/native";
import { appConfigStore } from "../../app/stores/AppConfig";

export const Register = () => {
	const isFocused = useIsFocused();
	const { createStandardUser } = AuthService();
	const { navigateToScreen } = useScreenComponent();
	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState<boolean | null>(null);
	const { darkMode } = appConfigStore((state) => state);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		if (isFocused) fadeIn();
	}, [isFocused]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const fadeOut = (doAfter: () => void) => {
		return Animated.timing(fadeAnimation, {
			toValue: 0,
			duration: 750,
			useNativeDriver: false,
		}).start(() => {
			doAfter();
		});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		const data = new FormData(event.currentTarget);
		const firstName = data.get("firstName") as string;
		const lastName = data.get("lastName") as string;
		const email = data.get("email") as string;
		const mobileNumber = data.get("mobileNumber") as string;
		const password = data.get("password") as string;

		const { success, message } = await createStandardUser(
			email,
			password,
			mobileNumber,
			firstName,
			lastName
		);
		setLoading(false);
		setSuccess(success);
		if (success) {
			setErrorMessage(message);
		} else {
			setErrorMessage(message);
		}
	};

	return (
		<Animated.View
			style={{
				flex: 1,
				opacity: fadeAnimation,
				backgroundColor: theme.customColors.background,
				position: "relative",
			}}>
			{/* Background image for all views */}
			<View style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				zIndex: 0
			}}>
				<Image
					source={require("../../assets/background-video.gif")}
					style={{
						width: "100%",
						height: "100%",
						resizeMode: "cover"
					}}
				/>
				{/* Darker overlay for desktop, lighter for mobile */}
				<View style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: darkMode ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.7)"
				}} />
			</View>

			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					height: "100%",
					width: "100%",
					position: "relative",
					zIndex: 1
				}}
			>
				{!isMobile && (
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							position: "relative",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-end",
							p: 4
						}}
					>
						<Box
							sx={{
								position: "relative",
								zIndex: 1,
								bgcolor: theme.customColors.cardBackground,
								p: 3,
								borderRadius: 2,
								maxWidth: "80%"
							}}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 700,
									color: theme.palette.text.primary,
									mb: 2
								}}
							>
								Shop Smarter, Not Harder!
							</Typography>
							<Typography
								variant="subtitle2"
								sx={{
									color: theme.palette.text.primary,
									mb: 2
								}}
							>
								Join our marketplace to discover exclusive deals, personalized recommendations, and seamless shopping experiences. Create an account today to track orders, save favorite items, and enjoy special member-only discounts on thousands of products.
							</Typography>
						</Box>
					</Box>
				)}

				<Box
					sx={{
						height: "100%",
						width: { xs: "100%", md: "50%" },
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						p: { xs: 3, sm: 5, md: 8 },
						backgroundColor: isMobile ? "transparent" : theme.customColors.cardBackground
					}}
				>
					<ScrollView
						contentContainerStyle={{
							flexGrow: 1,
							justifyContent: "center",
							maxWidth: 480,
							width: "100%"
						}}
						showsVerticalScrollIndicator={true}
					>
						<Image
							source={darkMode ? require("../../assets/icon-white-bg.svg") : require("../../assets/icon-black-bg.svg")}
							style={{
								width: 50,
								height: 50,
								resizeMode: "contain",
								alignSelf: "center",
							}}
						/>
						<Typography
							variant="h5"
							align="center"
							sx={{
								mt: 3,
								mb: 0.5,
								fontWeight: 600,
								color: theme.palette.text.primary
							}}
						>
							Create an account
						</Typography>
						<Typography
							variant="body1"
							align="center"
							sx={{
								mb: 6,
								color: theme.palette.text.secondary
							}}
						>
							By creating an account you will be able to join our workshop!
						</Typography>


						<Box
							component={"form"}
							onSubmit={handleSubmit}
							sx={{
								width: "100%",
								...(isMobile && {
									backgroundColor: theme.customColors.cardBackground,
									p: 3,
									borderRadius: 2,
									boxShadow: "0 4px 20px rgba(0,0,0,0.08)"
								})
							}}
						>
							<Grid
								container
								spacing={1.5}
							>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										fullWidth
										id="firstName"
										name="firstName"
										label="First Name"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<BasicTextField
										fullWidth
										id="lastName"
										name="lastName"
										label="Last Name"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<BasicTextField
										fullWidth
										id="email"
										name="email"
										label="Email"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<BasicTextField
										fullWidth
										id="mobileNumber"
										name="mobileNumber"
										label="Phone Number"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<BasicPasswordField 
										fullWidth
										id="password"
										name="password"
										label="Password"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
									<Typography
										variant="body2"
										align="center"
										sx={{
											mt: 1.25,
											color: success ? stateSuccessColor : stateErrorColor
										}}
									>
										{errorMessage}
									</Typography>
								</Grid>
							</Grid>

							<BasicButton
								title={"Create an account"}
								type={"submit"}
								style={{
									marginTop: 16,
									marginBottom: 16,
									width: "100%",
								}}
								isLoading={loading}
							/>

							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant="body2"
									sx={{
										display: "inline",
										color: theme.palette.text.secondary
									}}
								>
									Already have an account?{" "}
								</Typography>
								<BasicButton
									title={"Sign in"}
									variant={"text"}
									style={{
										display: "inline",
										padding: "0 4px",
										minWidth: "auto",
										color: theme.palette.text.primary,
										fontWeight: 600
									}}
									onClick={() => {
										fadeOut(() => {
											navigateToScreen(ScreenComponent.Login);
										});
									}}
								/>
							</Box>
						</Box>
					</ScrollView>
				</Box>
			</Box>
		</Animated.View >
	);
};
