import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { PortalScreenComponent, ScreenComponent } from "../../models/enums/ScreenComponents";
import { DefaultRoute } from "../../models/enums/DefaultRoute";
import { DefaultPortalRoute } from "../../models/enums/DefaultPortalRoute";
import { PortalRoutes } from "../../models/enums/PortalRoutes";

type State = {
	splashLoaded: boolean;
	showNavigationBar: boolean;
	splashVideoLoaded: boolean;
	cartDrawerOpen: boolean;
	defaultAuthRoute: DefaultRoute;
	defaultPortalAuthRoute: DefaultPortalRoute;
	currentScreen: ScreenComponent;
	portalCurrentScreen: PortalScreenComponent;
	screenList: ScreenComponent[];
	navBarItemList: ScreenComponent[];
	drawerItemList: ScreenComponent[];
	portalItemList: PortalScreenComponent[];
	currentPortalRoute: PortalRoutes;
	sideMenuIsExpanded: boolean;
};

type Action = {
	updateShowNavigationBar: (data: State["showNavigationBar"]) => void;
	updateDefaultRoute: (data: State["defaultAuthRoute"]) => void;
	updateDefaultPortalAuthRoute: (data: State["defaultPortalAuthRoute"]) => void;
	updateCurrentScreen: (data: State["currentScreen"]) => void;
	updatePortalCurrentScreen: (data: State["portalCurrentScreen"]) => void;
	updatescreenList: (data: State["screenList"]) => void;
	updateSplashLoaded: (data: State["splashLoaded"]) => void;
	updateSplashVideoLoaded: (data: State["splashVideoLoaded"]) => void;
	updateCartDrawerOpen: (data: State["cartDrawerOpen"]) => void;
	updateCurrentPortalRoute: (data: State["currentPortalRoute"]) => void;
	updateSideMenuIsExpanded: (data: State["sideMenuIsExpanded"]) => void;
};

export const navigationStore = create(
	persist<State & Action>(
		(set) => ({
			splashLoaded: true,
			splashVideoLoaded: false,
			cartDrawerOpen: false,
			showNavigationBar: true,
			sideMenuIsExpanded: true,
			defaultAuthRoute: DefaultRoute.Login,
			defaultPortalAuthRoute: DefaultPortalRoute.Login,
			currentPortalRoute: PortalRoutes.Products,
			currentScreen: ScreenComponent.Shop,
			portalCurrentScreen: PortalScreenComponent.Products,
			// non-persistent
			portalItemList: [
				PortalScreenComponent.Products,
				PortalScreenComponent.Orders,
				PortalScreenComponent.Wishlist,
			],
			screenList: [
				ScreenComponent.Splash,
				ScreenComponent.Shop,
				ScreenComponent.Product,
				// ScreenComponent.HowItWorks,
				ScreenComponent.Bookings,
				ScreenComponent.Orders,
				// ScreenComponent.Contact,
				// ScreenComponent.Cart,
				ScreenComponent.Register,
				ScreenComponent.Login,
				ScreenComponent.Portal,
			],
			navBarItemList: [
				ScreenComponent.Shop, 
				ScreenComponent.Bookings, 
				ScreenComponent.Orders,
			],
			drawerItemList: [
				ScreenComponent.Shop, 
				ScreenComponent.Bookings, 
				ScreenComponent.Orders, 
				ScreenComponent.Cart,
				ScreenComponent.Login,
			],

			updateSplashLoaded: (data) =>
				set(() => ({
					splashLoaded: data,
				})),

			updateSplashVideoLoaded: (data) =>
				set(() => ({
					splashVideoLoaded: data,
				})),

			updatescreenList: (data) =>
				set(() => ({
					screenList: data,
				})),

			updateCurrentScreen: (data) =>
				set(() => ({
					currentScreen: data,
				})),

			updatePortalCurrentScreen: (data) =>
				set(() => ({
					portalCurrentScreen: data,
				})),

			updateDefaultRoute: (data) =>
				set(() => ({
					defaultAuthRoute: data,
				})),

			updateDefaultPortalAuthRoute: (data) =>
				set(() => ({
					defaultPortalAuthRoute: data,
				})),

			updateShowNavigationBar: (data) =>
				set(() => ({
					showNavigationBar: data,
				})),
				
			updateCartDrawerOpen: (data) =>
				set(() => ({
					cartDrawerOpen: data,
				})),

			updateCurrentPortalRoute: (data) => {
				set(() => ({
					currentPortalRoute: data,
				}))
			},

			updateSideMenuIsExpanded: (data) =>
				set(() => ({
					sideMenuIsExpanded: data,
				})),
		}),
		{
			name: "navigation-storage",
			storage: createJSONStorage(() => AsyncStorage),
			// Using a type assertion to work around typing issues with partialize
			partialize: (state) => ({
				// Only persist these specific properties
				splashLoaded: state.splashLoaded,
				splashVideoLoaded: state.splashVideoLoaded,
				showNavigationBar: state.showNavigationBar,
				cartDrawerOpen: state.cartDrawerOpen,
				currentScreen: state.currentScreen,
				currentPortalRoute: state.currentPortalRoute,
				portalCurrentScreen: state.portalCurrentScreen,
				defaultAuthRoute: state.defaultAuthRoute,
				defaultPortalAuthRoute: state.defaultPortalAuthRoute,
				sideMenuIsExpanded: state.sideMenuIsExpanded,
			}) as any, // Use type assertion to avoid TypeScript errors
		}
	)
);
