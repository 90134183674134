import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type State = {
	darkMode: boolean;
	showOverlay: boolean;
};

type Action = {
	toggleDarkMode: () => void;
	setShowOverlay: (showOverlay: boolean) => void;
};

export const appConfigStore = create(
	persist<State & Action>(
		(set) => ({
			darkMode: false,
			showOverlay: false,
			toggleDarkMode: () => set((state) => ({ darkMode: !state.darkMode })),
			setShowOverlay: (showOverlay) => set(() => ({ showOverlay })),
		}),
		{
			name: "app-config-storage",
			storage: createJSONStorage(() => AsyncStorage),
			// Only persist the darkMode setting
			partialize: (state) => ({
				darkMode: state.darkMode,
				// We don't want to persist the overlay state between sessions
				// showOverlay: state.showOverlay,
			}) as any,
		}
	)
);
