import { create } from "zustand";
import { Product } from "../../models/DB";

export interface Categories {
	category: string;
	totalProducts: number;
	totalStock: number;
}

type State = {
	allProducts: Product[];
	allCategories: Categories[];
};

type Action = {
	updateProducts: (data: State[ "allProducts" ]) => void;
};

export const productStore = create<State & Action>((set) => ({
	allProducts: [],
	allCategories: [],
	updateProducts: (data: Product[]) => {

		// remove duplicate products 
		const uniqueProducts = data.filter(
			(product, index, self) => index === self.findIndex((p) => p.id === product.id)
		);

		const groupedProducts = uniqueProducts.reduce((acc, product) => {
			const { category } = product;
			if (!acc[ category ]) {
				acc[ category ] = [];
			}
			acc[ category ].push(product);
			return acc;
		}, {} as Record<string, Product[]>);

		const categories = Object.entries(groupedProducts).map(([ category, categoryProducts ]) => {

			const totalStock = categoryProducts.reduce(
				(total, product) => total + product.stockOnHand,
				0
			);

			const totalProducts = categoryProducts.length;
			return {
				category,
				totalProducts,
				totalStock,
			};
		});

		// add an "All" category to the list of categories at the first index
		const allStock = uniqueProducts.reduce((total, product) => total + product.stockOnHand, 0);
		categories.unshift({ category: "All", totalProducts: uniqueProducts.length, totalStock : allStock });

		set(() => ({
			allProducts: uniqueProducts,
			allCategories: categories,
		}));
	},
}));
