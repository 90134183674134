import { Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { ActivityIndicator, Image } from "react-native";

import { useState, MouseEvent, useCallback } from "react";
import AuthService from "../services/supabase/AuthService";
import { primaryColor } from "../config/constants";
import { ScreenComponent } from "../models/enums/ScreenComponents";
import { useScreenComponent } from "../app/hooks/custom/useScreenComponent";
import { AccessLevel } from "../models/enums/AccessLevel";
import _ from "lodash";
import { hasPortalAccess } from "../utils/customFunctions";

interface ProfileIconProps {
    isAuthenticated: boolean;
    firstName: string;
    role: AccessLevel;
    darkMode: boolean;
    onClick: () => void;
}

export const ProfileIcon = ({ isAuthenticated, firstName, role, darkMode, onClick }: ProfileIconProps) => {
    const { signOut } = AuthService();
    const { navigateToScreen } = useScreenComponent();
    const hasAccess = hasPortalAccess(role);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = useCallback(async () => {
        handleClose();
        setIsLoading(true);
        await signOut();
        setIsLoading(false);
    }, []);

    const handlePortal = useCallback(() => {
        handleClose();
        navigateToScreen(ScreenComponent.Portal);
    }, []);

    return (
        <>
            <IconButton
                sx={{
                    alignSelf: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    backgroundColor: darkMode ? "#333333" : "#f1f1f1",
                    paddingX: 2,
                    color: darkMode ? "white" : "black"
                }}
                onClick={(event) => {
                    if (isAuthenticated) {
                        handleClick(event);
                    } else {
                        onClick?.()
                    }
                }}
            >
                {
                    isLoading ?
                        <ActivityIndicator
                            size="small"
                            color={primaryColor}
                        />
                        :
                        <>
                            <Image
                                style={{
                                    resizeMode: "contain",
                                    width: 20,
                                    height: 20,
                                    tintColor: darkMode ? "white" : "black",
                                }}
                                source={require("../assets/profile-icon.png")}
                            />
                            {
                                isAuthenticated && firstName &&
                                <>
                                    <Divider
                                        color={darkMode ? "white" : "black"}
                                        orientation="vertical"
                                        style={{
                                            width: 2,
                                            marginLeft: 8,
                                            marginRight: 12,
                                            height: 16,
                                            alignSelf: "center",
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            fontFamily: "primaryFont",
                                            letterSpacing: 0.5,
                                            color: darkMode ? "white" : "black"
                                        }}
                                    >
                                        {`${firstName}`}
                                    </Typography>
                                </>
                            }
                            {
                                !isAuthenticated &&
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                            marginLeft: "8px",
                                            color: darkMode ? "white" : "black"
                                        }}
                                    >
                                    {`Sign In`}
                                    </Typography>
                            }
                        </>
                }
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {
                    hasAccess &&
                    <MenuItem onClick={handlePortal}>Portal</MenuItem>

                }
                <Divider orientation="horizontal" flexItem />
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    )
}
