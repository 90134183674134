import { Image, View } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { AppHeaderName } from "../../../config/constants";
import { Typography, useTheme } from "@mui/material";

export const NavBarLogo = () => {
    const darkMode = appConfigStore((state) => state.darkMode);
    const theme = useTheme();
    const logoImage = darkMode
        ? require("../../../assets/faded-logo.png")
        : require("../../../assets/faded-logo.png");

    return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
                style={{
                    width: 40,
                    height: 40,
                    resizeMode: "contain",
                }}
                source={logoImage}
            />
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    marginLeft: "12px",
                    alignSelf: "center",
                    fontSize: "28px",
                    letterSpacing: 2,
                    fontFamily: "logoFont",
                }}
            >
                {AppHeaderName}
            </Typography>
        </View>
    );
}; 