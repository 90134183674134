import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    FormControlLabel,
    Switch,
    Toolbar,
    Typography,
    IconButton,
    CircularProgress,
    useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { OrderDetails } from "../../../../../models/DB";
import { Refresh } from "@mui/icons-material";
import { OrderRow } from "./OrderRow";

export const OrdersTable = ({ orders, refreshOrders }: { orders: OrderDetails[], refreshOrders: () => Promise<void> }) => {
    const theme = useTheme();
    const [order, setOrder] = useState<"asc" | "desc">("desc");
    const [orderBy, setOrderBy] = useState<keyof OrderDetails>("deliveryType");
    const [page, setPage] = useState(0);


    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [dense, setDense] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ordersData, setOrdersData] = useState<OrderDetails[]>(orders);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0;
    const sortedOrders = ordersData?.slice().sort((a, b) => {
        // check for nulls

        if (a[orderBy] === null && b[orderBy] === null) {
            return 0;
        } else if (a[orderBy] === null) {
            return 1;
        } else if (b[orderBy] === null) {
            return -1;
        }

        if (orderBy === "deliveryType") {
            return (
                order === 'desc' ?
                    a[orderBy]!.localeCompare(b[orderBy]!)
                    :
                    b[orderBy]!.localeCompare(a[orderBy]!)
            );
        } else {
            return (
                order === 'asc' ?
                    (a[orderBy] as number) - (b[orderBy] as number)
                    :
                    (b[orderBy] as number) - (a[orderBy] as number)
            );
        }
    });

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof OrderDetails) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        await refreshOrders();
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                mb: 4,
                mt: 4,
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 12,
            }}
        >
            <Paper sx={{ width: '100%', backgroundColor: theme.palette.background.paper }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 }
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        textAlign={'left'}
                        id="tableTitle"
                        component="div"
                    >
                        Orders
                    </Typography>

                    <IconButton onClick={handleRefresh} disabled={isLoading} sx={{ mr: 2 }}>
                        {
                            isLoading ?
                                <CircularProgress
                                    size={24}
                                    sx={{ color: theme.palette.primary.main }}
                                />
                                :
                                <Refresh />
                        }
                    </IconButton>
                    <FormControlLabel
                        label="Densify"
                        control={
                            <Switch
                                checked={dense}
                                onChange={handleChangeDense}
                                color={"secondary"}
                            />
                        }
                    />
                </Toolbar>
                <TableContainer>
                    <Table stickyHeader size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ ml: 8, fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'reference'}
                                        direction={orderBy === 'reference' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'reference')}
                                    >
                                        Order Ref
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'status'}
                                        direction={orderBy === 'status' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'status')}
                                    >
                                        Order Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'deliveryType'}
                                        direction={orderBy === 'deliveryType' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'deliveryType')}
                                    >
                                        Delivery Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'email'}
                                        direction={orderBy === 'email' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" sx={{ display: { xs: "none", md: "table-cell" } }}>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'totalCost'}
                                        direction={orderBy === 'totalCost' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'totalCost')}
                                    >
                                        Total Cost
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" sx={{ display: { xs: "none", md: "table-cell" } }}>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'created'}
                                        direction={orderBy === 'created' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'created')}
                                    >
                                        Created
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedOrders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => {
                                return (
                                    <OrderRow
                                        key={order.id}
                                        order={order}
                                        orders={ordersData}
                                        setOrders={setOrdersData}
                                    />
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 7, 10, 25, 50, 100]}
                    component="div"
                    count={orders?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};