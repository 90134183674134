export enum ScreenComponent {
	Splash = "Splash",
	Shop = "Shop",
	Product = "Product",
	HowItWorks = "How_It_Works",
	Bookings = "Bookings",
	Orders = "Orders",
	Contact = "Contact_Us",
	Cart = "Cart",
	Register = "Register",
	Login = "Login",
	Portal = "Portal",
	ThemePreview = "Theme_Preview",
}

export enum PortalScreenComponent {
	Products = "Products",
	Orders = "Orders",
	Wishlist = "Wishlist",
	Users = "Users",
	Analytics = "Analytics",
}
