import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    FormControlLabel,
    Switch,
    Toolbar,
    Typography,
    IconButton,
    CircularProgress,
    useTheme
} from "@mui/material";
import { ProductRow } from "./ProductRow";
import { useState } from "react";
import { Product } from "../../../../../models/DB";
import { Refresh } from "@mui/icons-material";
import { ProductsService } from "../../../../../services/supabase/ProductsService";

export const ProductsTable = ({ products }: { products: Product[] }) => {
    const theme = useTheme();
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<keyof Product>('updated');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(8);
    const [dense, setDense] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { getAllProducts } = ProductsService();

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

    const sortedProducts = products.slice().sort((a, b) => {

        // check for nulls

        if (a[orderBy] === null && b[orderBy] === null) {
            return 0;
        } else if (a[orderBy] === null) {
            return 1;
        } else if (b[orderBy] === null) {
            return -1;
        }

        if (orderBy === 'name' || orderBy === 'category' || orderBy === 'updated' || orderBy === 'created') {
            return (
                order === 'asc' ?
                    a[orderBy].localeCompare(b[orderBy])
                    :
                    b[orderBy].localeCompare(a[orderBy])
            );
        } else {
            return (
                order === 'asc' ?
                    (a[orderBy] as number) - (b[orderBy] as number)
                    :
                    (b[orderBy] as number) - (a[orderBy] as number)
            );
        }
    });

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Product) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        await getAllProducts();
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                mb: 4,
                mt: 4,
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 12,
            }}
        >
            <Paper sx={{ width: '100%' }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        textAlign={'left'}
                        id="tableTitle"
                        component="div"
                    >
                        Products
                    </Typography>

                    <IconButton onClick={handleRefresh} disabled={isLoading} sx={{ mr: 2 }}>
                        {
                            isLoading ?
                                <CircularProgress
                                    size={24}
                                    sx={{ color: theme.palette.primary.main }}
                                />
                                :
                                <Refresh />
                        }
                    </IconButton>
                    <FormControlLabel
                        label="Densify"
                        control={
                            <Switch
                                checked={dense}
                                onChange={handleChangeDense}
                                color={"secondary"}
                            />
                        }
                    />
                </Toolbar>
                <TableContainer>
                    <Table stickyHeader size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ ml: 1, fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'name'}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'category'}
                                        direction={orderBy === 'category' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'category')}
                                    >
                                        Category
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'price'}
                                        direction={orderBy === 'price' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'price')}
                                    >
                                        Price
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'stockOnHand'}
                                        direction={orderBy === 'stockOnHand' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'stockOnHand')}
                                    >
                                        Stock Available
                                    </TableSortLabel>
                                </TableCell> 
                                {/* <TableCell align="right">
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'created'}
                                        direction={orderBy === 'created' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'created')}
                                    >
                                        Created
                                    </TableSortLabel>
                                </TableCell> */}
                                <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={orderBy === 'updated'}
                                        direction={orderBy === 'updated' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'updated')}
                                    >
                                        Last Updated
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
                                return (
                                    <ProductRow
                                        key={product.id}
                                        product={product}
                                    />
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[8, 10, 25, 50, 100]}
                    component="div"
                    count={products.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};