import { create, StoreApi, UseBoundStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Product } from "../../models/DB";

interface CartItem {
	product: Product;
	quantity: number;
	selectedVariant?: string;
}

type CartState = {
	cartItems: CartItem[];
	cartTotal: number;
};

type CartActions = {
	addToCart: (product: Product, selectedVariant?: string) => void;
	removeFromCart: (productId: string, selectedVariant?: string) => void;
	updateCartItemQuantity: (productId: string, quantity: number, selectedVariant?: string) => void;
	clearCart: () => void;
};

// Helper function to calculate actual price considering discount
const calculateItemPrice = (product: Product): number => {
	const price = Number(product.price);
	const discount = Number(product.discount || 0);
	return price - discount;
};

// Helper function to calculate total price for cart
const calculateCartTotal = (cartItems: CartItem[]): number => {
	return cartItems.reduce((total, cartItem) => {
		const itemPrice = calculateItemPrice(cartItem.product);
		return total + itemPrice * cartItem.quantity;
	}, 0);
};

export const cartStore = create(
	persist<CartState & CartActions>(
		(set) => ({
			cartItems: [],
			cartTotal: 0,
			addToCart: (product, selectedVariant) => {
				set((state) => {
					const existingCartItem = state.cartItems.find(
						(item) => item.product.code === product.code && item.selectedVariant === selectedVariant
					);

					let updatedCartItems;
					if (existingCartItem) {
						updatedCartItems = state.cartItems.map((item) =>
							item.product.code === product.code && item.selectedVariant === selectedVariant
								? { ...item, quantity: item.quantity + 1 }
								: item
						);
					} else {
						updatedCartItems = [...state.cartItems, { product, quantity: 1, selectedVariant }];
					}

					const cartTotal = calculateCartTotal(updatedCartItems);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			removeFromCart: (productId, selectedVariant) => {
				set((state) => {
					const updatedCartItems = state.cartItems.filter(
						(item) => !(item.product.code === productId && item.selectedVariant === selectedVariant)
					);

					const cartTotal = calculateCartTotal(updatedCartItems);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			updateCartItemQuantity: (productId, quantity, selectedVariant) => {
				set((state) => {
					const updatedCartItems = state.cartItems.map((item) =>
						item.product.code === productId && item.selectedVariant === selectedVariant
							? { ...item, quantity }
							: item
					);

					const cartTotal = calculateCartTotal(updatedCartItems);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			clearCart: () => {
				set(() => ({
					cartItems: [],
					cartTotal: 0,
				}));
			},
		}),
		{
			name: "cart-storage", 
			storage: createJSONStorage(() => AsyncStorage),
			// Only persist the cart data, not the action methods
			partialize: (state) => ({
				cartItems: state.cartItems,
				cartTotal: state.cartTotal,
			}) as any,
		}
	)
) as UseBoundStore<StoreApi<CartState & CartActions>>;
