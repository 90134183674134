import { useEffect, useRef, useMemo, memo, useState, useCallback } from "react";
import { ScrollView, TouchableOpacity, Text, Animated, View } from "react-native";
import { Chip, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { primaryColor } from "../../../../config/constants";
import { Categories } from "../../../../app/stores/Product";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { navigationStore } from "../../../../app/stores/Navigation";
import { appConfigStore } from "../../../../app/stores/AppConfig";


interface CategoriesFilterScrollerProps {
    scrollViewRef: React.RefObject<ScrollView>;
    allCategories: Categories[];
    selectedCategories: Categories[];
    setSelectedCategories: (categories: Categories[]) => void;
    getProductsLength: (category: string) => number;
}

export const CategoriesFilterScroller = memo(({
    allCategories,
    getProductsLength,
    scrollViewRef,
    selectedCategories,
    setSelectedCategories
}: CategoriesFilterScrollerProps) => {
    const theme = useTheme();
    const darkMode = appConfigStore((state) => state.darkMode);
    const scrollViewScrollerRef = useRef<ScrollView>(scrollViewRef as unknown as ScrollView);
    const scrollPosScrollerRef = useRef(0);
    const getIsSelected = (cat: string) => selectedCategories.includes(cat as unknown as Categories);

    useEffect(() => {
        // Restore scroll position
        if (scrollViewScrollerRef.current) {
            (scrollViewScrollerRef.current).scrollTo({ x: scrollPosScrollerRef.current, animated: false });
        }
    }, [scrollViewScrollerRef.current]);

    const categoryChips = useMemo(() => {
        // First sort the categories alphabetically
        const sortedCategories = [...allCategories].sort((a, b) =>
            a.category.localeCompare(b.category)
        );

        // Then move 'All' category to the beginning if it exists
        const allCategoryIndex = sortedCategories.findIndex(cat => cat.category === "All");
        if (allCategoryIndex !== -1) {
            const allCategory = sortedCategories.splice(allCategoryIndex, 1)[0];
            sortedCategories.unshift(allCategory);
        }

        return sortedCategories.map((cat, index) => {
            const isSelectedCategory = getIsSelected(cat.category);
            const label = isSelectedCategory ? `${cat.category} (${getProductsLength(cat.category)} Items)`
                : cat.category;

            return (
                <Chip
                    component="div"
                    key={`${cat}-${index}`}
                    label={label}
                    variant={"filled"}
                    size="medium"
                    sx={{
                        px: 0.5,
                    }}
                    style={{
                        letterSpacing: 0.5,
                        fontWeight: "600",
                        minHeight: 40,
                        marginRight: 8,
                        borderRadius: 20,
                        backgroundColor: isSelectedCategory ? primaryColor : darkMode ? "#333333" : "#ececec",
                        color: isSelectedCategory ? "white" : theme.palette.text.primary
                    }}
                    onClick={() =>
                        setSelectedCategories([cat.category as unknown as Categories])
                    }
                />
            );
        });
    }, [allCategories, selectedCategories, getProductsLength, darkMode, theme.palette.text.primary]);

    return (
        <Grid
            item
            xs={12}
            display={{ md: "none" }}
            sx={{
                backgroundColor: theme.customColors.background,
                boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                marginTop: -.5,
                overflow: "hidden",
            }}
        >
            <ScrollView
                horizontal
                contentContainerStyle={{
                    paddingTop: 28,
                    paddingBottom: 24,
                    paddingLeft: 15,
                }}
                ref={scrollViewScrollerRef}
                onScroll={(event) => {
                    scrollPosScrollerRef.current = event.nativeEvent.contentOffset.x;
                }}
                scrollEventThrottle={16}
            >
                {categoryChips}
            </ScrollView>
        </Grid>
    );
});


interface CategoriesFilterSideMenuProps {
    scrollViewRef: React.RefObject<ScrollView>;
    allCategories: Categories[];
    selectedCategories: Categories[];
    setSelectedCategories: (categories: Categories[]) => void;
    getProductsLength: (category: string) => number;
}

export const CategoriesFilterSideMenu = memo(({ scrollViewRef, allCategories, selectedCategories, setSelectedCategories, getProductsLength }: CategoriesFilterSideMenuProps) => {
    const theme = useTheme();
    const darkMode = appConfigStore((state) => state.darkMode);
    const { sideMenuIsExpanded, updateSideMenuIsExpanded } = navigationStore(state => state);
    const scrollViewSideRef = useRef<ScrollView>(scrollViewRef as unknown as ScrollView);
    const scrollPosSideRef = useRef(0);
    const animatedWidth = useRef(new Animated.Value(sideMenuIsExpanded ? 1 : 0)).current;

    const toggleMenu = useCallback(() => {
        updateSideMenuIsExpanded(!sideMenuIsExpanded);

        Animated.timing(animatedWidth, {
            toValue: !sideMenuIsExpanded ? 1 : 0,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, [sideMenuIsExpanded, updateSideMenuIsExpanded]);

    const getIsSelected = (cat: string) => selectedCategories.includes(cat as unknown as Categories);

    useEffect(() => {
        // Restore scroll position
        if (scrollViewSideRef.current) {
            const scrollView = scrollViewSideRef.current as unknown as ScrollView;
            scrollView.scrollTo({ y: scrollPosSideRef.current, animated: false });
        }
    }, [scrollViewSideRef.current]);

    const categoryItems = useMemo(() => {
        // First sort the categories alphabetically
        const sortedCategories = [...allCategories].sort((a, b) =>
            a.category.localeCompare(b.category)
        );

        // Then move 'All' category to the beginning if it exists
        const allCategoryIndex = sortedCategories.findIndex(cat => cat.category === "All");
        if (allCategoryIndex !== -1) {
            const allCategory = sortedCategories.splice(allCategoryIndex, 1)[0];
            sortedCategories.unshift(allCategory);
        }

        return sortedCategories.map((cat, index) => {
            const isSelectedCategory = getIsSelected(cat.category);
            const label = isSelectedCategory
                ? `${cat.category} (${getProductsLength(cat.category)} Items)`
                : cat.category;

            return (
                <TouchableOpacity
                    key={`${cat}-${index}`}
                    onPress={() => setSelectedCategories([cat.category as unknown as Categories])}
                    style={{
                        marginRight: 18,
                        marginBottom: 8,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            padding: 1,
                            borderRadius: 4,
                            backgroundColor: isSelectedCategory ? primaryColor : "transparent",
                            transition: "background-color 0.4s, opacity 0.4s ease-in-out",
                            ":hover": {
                                backgroundColor: darkMode ? "#333333" : "#ececec",
                            },
                            opacity: sideMenuIsExpanded ? 1 : 0,
                            marginLeft: 10,
                        }}
                    >
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{
                                letterSpacing: 0.5,
                                fontWeight: "600",
                                color: isSelectedCategory ? "white" : theme.palette.text.primary,
                                paddingX: "18px",
                            }}>
                            {label}
                        </Typography>
                    </Grid>
                </TouchableOpacity>
            );
        });
    }, [allCategories, selectedCategories, getProductsLength, sideMenuIsExpanded, darkMode, theme.palette.text.primary]);

    // Calculate width based on animation value
    const menuWidth = animatedWidth.interpolate({
        inputRange: [0, 1],
        outputRange: ["13%", "25%"] // From 0 to original width
    });


    return (
        <>
            <Animated.View
                // @ts-ignore
                style={{
                    width: menuWidth,
                    display: "flex",
                    flexShrink: 0,
                    position: "sticky", // never change this from sticky to anything else
                    top: 16,
                    alignSelf: "flex-start",
                    maxHeight: "calc(100vh - 150px)",
                    overflow: "visible",
                    zIndex: 100,
                }}
            >
                <Grid
                    item
                    display={{ xs: "none", md: "flex" }}
                    sx={{
                        overflowY: "auto",
                        position: "relative",
                        overflowX: "auto",
                    }}
                >
                    {/* Toggle button */}
                    <div
                        style={{
                            display: "flex",
                            position: "absolute",
                            right: 4,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                            backgroundColor: primaryColor,
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "visible",
                        }}>

                        <IconButton
                            onClick={toggleMenu}
                            size="small"
                            sx={{
                                color: "white"
                            }}
                        >
                            {sideMenuIsExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>

                    <ScrollView
                        contentContainerStyle={{
                            alignSelf: "flex-end",
                            marginTop: 18,
                            marginRight: 22,
                            borderRightWidth: 2,
                            borderColor: theme.palette.divider,
                            borderRadius: 10,
                            paddingVertical: 30,
                            backgroundColor: theme.customColors.background,
                        }}
                        ref={scrollViewSideRef}
                        onScroll={(event) => {
                            scrollPosSideRef.current = event.nativeEvent.contentOffset.y;
                        }}
                        scrollEventThrottle={16}
                        showsVerticalScrollIndicator={false}
                    >
                        {categoryItems}
                    </ScrollView>
                </Grid>
            </Animated.View>
        </>
    );
});