import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import { AppProvider, type Navigation } from "@toolpad/core/AppProvider";
import { DashboardLayout, SidebarFooterProps } from "@toolpad/core/DashboardLayout";
import Products from "./Screens/Products/Products";
import Orders from "./Screens/Orders/Orders";
import { ArrowBack, Inventory, Logout, SupervisedUserCircleRounded, DarkMode, LightMode } from "@mui/icons-material";
import { primaryColor } from "../../config/constants";
import { navigationStore } from "../../app/stores/Navigation";
import { useEffect, useMemo } from "react";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { useIsFocused } from "@react-navigation/native";
import AuthService from "../../services/supabase/AuthService";
import { isLarge } from "../../navigation/HomeStack";
import {
    Account,
    AccountPreview,
    AccountPopoverFooter,
    AccountPreviewProps,
} from "@toolpad/core/Account";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import { userStore } from "../../app/stores/User";
import { PortalRoutes } from "../../models/enums/PortalRoutes";
import { usePortalRouter } from "../../app/hooks/custom/usePortalRouter";
import { appConfigStore } from "../../app/stores/AppConfig";
import { themeColors } from "../../config/constants";
import { ThemeToggle } from "../../components/ThemeToggle";
import Dashboard from "./Screens/Dashboard/Dashboard";

const PageContent = ({ pathname }: { pathname: string }) => {
    const renderContent = () => {
        switch (pathname) {
            case PortalRoutes.Dashboard:
                return <Dashboard />;
            case PortalRoutes.Products:
                return <Products />;
            case PortalRoutes.Orders:
                return <Orders />;
            default:
                return <Typography sx={{ mt: 10, fontFamily: "primaryFont", fontSize: 22, fontWeight: 600 }}>Coming Soon!</Typography>;
        }
    };

    return (
        <Box
            sx={{
                py: 4,
                px: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            {renderContent()}
        </Box>
    );
}

export const Portal = ({ route }) => {
    const { router } = usePortalRouter(route.params);
    const { updateShowNavigationBar } = navigationStore((state) => state);
    const darkMode = appConfigStore((state) => state.darkMode);
    const { navigateToScreen } = useScreenComponent();
    const { signOut } = AuthService();
    const { user } = userStore((state) => state);

    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            updateShowNavigationBar(false);
        }
    }, [isFocused, updateShowNavigationBar]);

    const navConfig: Navigation = [
        {
            segment: "dashboard",
            title: "Dashboard",
            icon: <DashboardIcon />,
        },
        {
            kind: "header",
            title: "Management",
        },
        {
            segment: "products",
            title: "Products",
            icon: <Inventory />,
        },
        {
            segment: "orders",
            title: "Orders",
            icon: <ShoppingCartIcon />,
        },
        {
            kind: "divider",
        },
        {
            segment: "analytics",
            title: "Analytics",
            icon: <BarChartIcon />,
            children: [
                {
                    segment: "Sales",
                    title: "Sales",
                    icon: <DescriptionIcon />,
                },
                {
                    segment: "Users",
                    title: "Users",
                    icon: <SupervisedUserCircleRounded />,
                },
            ],
        }
    ];

    const themeConfig = useMemo(() => {
        const currentTheme = darkMode ? themeColors.dark : themeColors.light;

        return createTheme({
            palette: {
                mode: darkMode ? "dark" : "light",
                primary: {
                    main: primaryColor,
                },
                background: {
                    default: currentTheme.background,
                    paper: currentTheme.cardBackground,
                },
                text: {
                    primary: currentTheme.textPrimary,
                    secondary: currentTheme.textSecondary,
                },
                divider: currentTheme.divider,
            },
            components: {
                MuiListItemText: {
                    styleOverrides: {
                        primary: {
                            fontSize: "0.875rem",
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        subtitle2: {
                            fontSize: "0.75rem",
                        },
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 600,
                    lg: 1200,
                    xl: 1536,
                },
            },
            customColors: currentTheme,
        });
    }, []);

    // Account sidebar components
    function AccountSidebarPreview(props: AccountPreviewProps & { mini: boolean }) {
        const { handleClick, open, mini } = props;
        return (
            <Stack direction="column" p={0}>
                <Divider />
                <AccountPreview
                    variant={mini ? "condensed" : "expanded"}
                    handleClick={handleClick}
                    open={open}
                />
            </Stack>
        );
    }

    const accounts = [
        {
            id: 1,
            name: user.info.user_metadata?.firstName + " " + user.info.user_metadata?.lastName,
            email: user.info.email,
            image: user.info.user_metadata?.avatar_url,
            color: "#1976d2", // Primary color
        },
    ];

    const SidebarFooterAccountPopover = () => {
        return (
            <Stack direction="column">
                <Typography variant="body2" mx={2} mt={1}>
                    Accounts
                </Typography>
                <MenuList>
                    {accounts.map((account) => (
                        <MenuItem
                            key={account?.id ?? ""}
                            component="button"
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                columnGap: 2,
                            }}
                        >
                            <ListItemIcon>
                                <Avatar
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        fontSize: "0.95rem",
                                        bgcolor: primaryColor,
                                    }}
                                    src={account?.image ?? ""}
                                    alt={account?.name ?? ""}
                                >
                                    {account?.name?.[0] ?? ""}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    width: "100%",
                                }}
                                primary={account?.name ?? ""}
                                secondary={account?.email ?? ""}
                                primaryTypographyProps={{ variant: "body2" }}
                                secondaryTypographyProps={{ variant: "caption" }}
                            />
                        </MenuItem>
                    ))}
                </MenuList>
                <Divider />
                <AccountPopoverFooter sx={{ flexDirection: "column" }}>
                    <MenuItem
                        onClick={() => navigateToScreen(ScreenComponent.Shop)}
                        sx={{ justifyContent: "flex-start", width: "100%" }}
                    >
                        <ListItemIcon>
                            <ArrowBack fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Back to Store</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            signOut();
                        }}
                        sx={{ justifyContent: "flex-start", width: "100%" }}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Sign Out</ListItemText>
                    </MenuItem>
                </AccountPopoverFooter>
            </Stack>
        );
    }

    const createPreviewComponent = (mini: boolean) => {
        function PreviewComponent(props: AccountPreviewProps) {
            return <AccountSidebarPreview {...props} mini={mini} />;
        }
        return PreviewComponent;
    };

    const SidebarFooterAccount = ({ mini }: SidebarFooterProps) => {
        const PreviewComponent = useMemo(() => createPreviewComponent(mini), [mini]);
        return (
            <Account
                slots={{
                    preview: PreviewComponent,
                    popoverContent: SidebarFooterAccountPopover,
                }}
                slotProps={{
                    popover: {
                        transformOrigin: { horizontal: "left", vertical: "bottom" },
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                        disableAutoFocus: true,
                        slotProps: {
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: (theme) =>
                                        `drop-shadow(0px 2px 8px ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.10)" : "rgba(0,0,0,0.32)"})`,
                                    mt: 1,
                                    "&::before": {
                                        content: "\"\"",
                                        display: "block",
                                        position: "absolute",
                                        bottom: 10,
                                        left: 0,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translate(-50%, -50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            },
                        },
                    },
                }}
            />
        );
    }

    return (
        <AppProvider
            router={router}
            navigation={navConfig}
            theme={themeConfig}
            branding={{
                logo: <></>,
                title: "Portal",
            }}
            session={{
                user: {
                    name: user.info.user_metadata?.firstName + " " + user.info.user_metadata?.lastName,
                    email: user.info.email,
                    image: user.info.user_metadata?.avatar_url,
                }
            }}

            authentication={{
                signIn: () => { },
                signOut: () => {
                    signOut();
                },
            }}
        >
            <DashboardLayout
                defaultSidebarCollapsed={isLarge() ? false : true}
                sidebarExpandedWidth={isLarge() ? 300 : "85%"}
                slots={{
                    sidebarFooter: SidebarFooterAccount,
                    toolbarActions: ThemeToggle,
                }}
                sx={{
                    backgroundColor: themeConfig.customColors.background,
                }}
            >
                <PageContent pathname={router.pathname} />
            </DashboardLayout>
        </AppProvider>
    );
};