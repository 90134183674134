import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { appConfigStore } from "../app/stores/AppConfig";
import { themeColors } from "../config/constants";
import { AppLogo } from "./AppLogo";
import { isLarge } from "../navigation/HomeStack";

interface ThemeTransitionOverlayProps {
    onComplete: () => void;
}

export const ThemeTransitionOverlay = ({ onComplete }: ThemeTransitionOverlayProps) => {
    const { darkMode } = appConfigStore((state) => state);
    const [countdown, setCountdown] = useState(2);
    const [fade, setFade] = useState(true);
    const [transitioning, setTransitioning] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(timer);
                    // Start background transition to opposite theme
                    setTransitioning(true);

                    // Wait for background transition to complete before calling onComplete
                    setTimeout(() => {
                        onComplete();
                        // After theme changes, start fade out
                        setTimeout(() => {
                            setFade(false);
                        }, 300);
                    }, 1000); // Match this duration with transition in sx below
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [onComplete]);

    return (
        <Box
            sx={{
                flexDirection: "column",
                position: "fixed",
                top: 0,
                left: 0,
                p: 2,
                width: "100%",
                height: "100%",
                bgcolor: transitioning
                    ? (darkMode ? themeColors.light.background : themeColors.dark.background)
                    : (darkMode ? themeColors.dark.background : themeColors.light.background),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
                transition: "background-color 1s ease, opacity 0.5s ease",
                opacity: fade ? 1 : 0,
            }}
        >
            <AppLogo showText={false} />
            <Typography
                mt={2}
                variant={isLarge() ? "h6" : "subtitle1"}
                fontFamily={"secondaryFont"}
                textAlign="center"
                color={transitioning
                    ? (darkMode ? themeColors.light.textPrimary : themeColors.dark.textPrimary)
                    : (darkMode ? themeColors.dark.textPrimary : themeColors.light.textPrimary)}
                sx={{ transition: "color 1s ease" }}
            >
                {countdown > 0
                    ? darkMode
                        ? `Bringing the Light in`
                        : `Going Dark in`
                    : ""}
            </Typography>
            <Typography
                variant={isLarge() ? "subtitle2" : "body2"}
                fontFamily={"secondaryFont"}
                textAlign="center"
                color={transitioning
                    ? (darkMode ? themeColors.light.textPrimary : themeColors.dark.textPrimary)
                    : (darkMode ? themeColors.dark.textPrimary : themeColors.light.textPrimary)}
                sx={{ transition: "color 1s ease" }}
            >
                {countdown > 0 ? `${countdown} ${countdown === 1 ? "second" : "seconds"}` : ""}
            </Typography>
        </Box>
    );
}; 