import { DefaultTheme, LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { registerRootComponent } from "expo";
import { useFonts } from "expo-font";
import { useEffect, useState, useMemo } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { HomeStack } from "../navigation/HomeStack";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { appConfigStore } from "./stores/AppConfig";
import { prefix, RootPaths, primaryColor, themeColors, darkBackgroundSecondary, lightBackgroundSecondary } from "../config/constants";
import useAuthSession from "./hooks/custom/useAuthSession";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeTransitionOverlay } from "../components/ThemeTransitionOverlay";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Declare a module augmentation for the custom theme
declare module "@mui/material/styles" {
	interface Theme {
		customColors: {
			background: string;
			backgroundSecondary: string;
			backgroundTertiary: string;
			textPrimary: string;
			textSecondary: string;
			textMuted: string;
			border: string;
			divider: string;
			hover: string;
			buttonOutline: string;
			cardBackground: string;
			navBackground: string;
			inputBackground: string;
		};
	}

	interface ThemeOptions {
		customColors: {
			background: string;
			backgroundSecondary: string;
			backgroundTertiary: string;
			textPrimary: string;
			textSecondary: string;
			textMuted: string;
			border: string;
			divider: string;
			hover: string;
			buttonOutline: string;
			cardBackground: string;
			navBackground: string;
			inputBackground: string;
		};
	}
}

const STORAGE_CLEARED_KEY = "storage_cleared_v1";

const App = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const toggleDarkMode = appConfigStore((state) => state.toggleDarkMode);
	const showOverlay = appConfigStore((state) => state.showOverlay);
	const setShowOverlay = appConfigStore((state) => state.setShowOverlay);
	const [fadeAnimation] = useState(new Animated.Value(0));
	const { checkSession } = useAuthSession();
	const [fontsLoaded] = useFonts({
		primaryFont: require("../assets/fonts/neuehaas-roman.ttf"),
		logoFont: require("../assets/fonts/urban-starblues.otf"),
		secondaryFont: require("../assets/fonts/stalinist-one.ttf"),
	});

	useEffect(() => {
		const clearLocalStorageOnce = async () => {
			try {
				const storageCleared = await AsyncStorage.getItem(STORAGE_CLEARED_KEY);

				if (!storageCleared) {
					console.log("Clearing localStorage to reinitialize stores...");

					// Get all keys in AsyncStorage
					const keys = await AsyncStorage.getAllKeys();

					// Clear the specific stores
					if (keys.length > 0) {
						await AsyncStorage.multiRemove(keys);
						console.log("Successfully cleared persisted stores:", keys);
					} else {
						console.log("No persisted stores found to clear");
					}

					// Set flag to indicate we've cleared storage
					await AsyncStorage.setItem(STORAGE_CLEARED_KEY, "true");
					console.log("Storage cleared flag set - will not clear again");
				} else {
					console.log("Storage has already been cleared previously");
				}
			} catch (error) {
				console.error("Error clearing localStorage:", error);
			}
		};

		clearLocalStorageOnce();
	}, []);

	const theme = useMemo(
		() => {
			const currentTheme = darkMode ? themeColors.dark : themeColors.light;

			return createTheme({
				palette: {
					mode: darkMode ? "dark" : "light",
					primary: {
						main: primaryColor,
					},
					secondary: {
						main: "#f50057",
					},
					background: {
						default: currentTheme.background,
						paper: currentTheme.cardBackground,
					},
					text: {
						primary: currentTheme.textPrimary,
						secondary: currentTheme.textSecondary,
					},
					divider: currentTheme.divider,
				},
				typography: {
					fontFamily: "primaryFont, Arial, sans-serif",
					// Apply the font family to all typography variants
					allVariants: {
						fontFamily: "primaryFont, Arial, sans-serif",
					},
					h1: {
						fontWeight: 700,
						letterSpacing: 1,
					},
					h2: {
						fontWeight: 700,
						letterSpacing: 1,
					},
					h3: {
						fontWeight: 700,
						letterSpacing: 1,
					},
					h4: {
						fontWeight: 600,
						letterSpacing: 0.75,
					},
					h5: {
						fontWeight: 600,
						letterSpacing: 0.5,
					},
					h6: {
						fontWeight: 600,
						letterSpacing: 0.5,
					},
					subtitle1: {
						fontWeight: 500,
						letterSpacing: 0.5,
					},
					subtitle2: {
						fontWeight: 500,
						letterSpacing: 0.25,
					},
					body1: {
						letterSpacing: 0.5,
					},
					body2: {
						letterSpacing: 0.25,
					},
					button: {
						letterSpacing: 0.75,
						textTransform: "none",
					},
					caption: {
						letterSpacing: 0.4,
					},
				},
				components: {
					MuiButton: {
						styleOverrides: {
							root: {
								borderRadius: 8,
								textTransform: "none",
							},
						},
					},
					MuiTypography: {
						styleOverrides: {
							root: {
								// Any global typography overrides
							},
						},
					},
				},
				// Add our custom colors to the theme for easy access
				customColors: currentTheme,
			});
		},
		[darkMode]
	);

	useEffect(() => {
		if (fontsLoaded) {
			console.log("App Loaded");
			checkSession();
			fadeIn();
		}
	}, [fontsLoaded]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 1000,
			delay: 0,
			useNativeDriver: false,
		}).start();
	};

	const handleOverlayComplete = () => {
		toggleDarkMode();
		setShowOverlay(false);
	};

	const Container = () => {
		const linking: LinkingOptions<ReactNavigation.RootParamList> = {
			config: {
				screens: RootPaths
			},
			prefixes: [prefix],
		};

		const navigatorTheme = {
			...DefaultTheme,
			colors: {
				...DefaultTheme.colors,
				background: darkMode ? darkBackgroundSecondary : lightBackgroundSecondary,
			},
		};

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<NavigationContainer
					linking={linking}
					theme={navigatorTheme}
				>
					{fontsLoaded && (
						<Animated.View
							style={{
								...styles.container,
								...{ opacity: fadeAnimation },
							}}
						>
							<HomeStack />
							{/* <AuthStack /> */}
						</Animated.View>
					)}
				</NavigationContainer>
			</ThemeProvider>
		);
	};

	return (
		<View style={{ width: "100%", height: "100%" }}>
			{showOverlay && <ThemeTransitionOverlay onComplete={handleOverlayComplete} />}
			{/* <VideoBackground /> */}
			<Container />
		</View>
	);
};

registerRootComponent(App);
serviceWorkerRegistration.register();

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		backgroundColor: "rgba(0, 0, 0, 0.80)",
	},
	video: {
		position: "absolute",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
});
