import React, { useRef, useState } from "react";
import { TextField, InputAdornment, IconButton, TextFieldProps as MuiTextFieldProps, SxProps, Theme } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { primaryColor } from "../config/constants";

export interface BasicPasswordFieldProps extends Omit<MuiTextFieldProps, "type" | "onChange"> {
    onChange?: MuiTextFieldProps['onChange'];
}


export const BasicPasswordField: React.FC<BasicPasswordFieldProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClickShowPassword = () => {
        // Store cursor position
        const cursorPosition = inputRef.current?.selectionStart || 0;

        // Toggle password visibility
        setShowPassword(!showPassword);

        // Use setTimeout to restore cursor position after re-render
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.selectionStart = cursorPosition;
                inputRef.current.selectionEnd = cursorPosition;
            }
        }, 0);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    // Only apply custom autofill styling if primaryColor is not black
    const autofillStyles: SxProps<Theme> = primaryColor !== "#000000" ? {
        "& .MuiInputBase-input:-webkit-autofill": {
            "-webkit-box-shadow": `0 0 0 100px ${primaryColor}25 inset !important`,
            "-webkit-text-fill-color": "inherit",
            caretColor: "inherit",
        }
    } : {};

    return (
        <TextField
            {...props}
            type={showPassword ? "text" : "password"}
            inputRef={inputRef}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            sx={{
                "& .MuiInputLabel-root": {
                    color: "grey",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                    color: "grey",
                },
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "grey",
                    },
                    "&:hover fieldset": {
                        borderColor: "grey",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "grey",
                    },
                },
                "& .MuiInputBase-input": {
                    letterSpacing: showPassword ? 1 : 6,
                },
                ...(props.sx || {}),
                ...autofillStyles,
            }}
        />
    );
}; 