import { Grid, Typography, useTheme } from "@mui/material";
import { ScreenComponent } from "../models/enums/ScreenComponents";
import { Product } from "../models/DB";
import { Image, View } from "react-native";
import { getBadgeText } from "../utils/customFunctions";
import { memo } from "react";
import { ProductPrice } from "./ProductPrice";

interface ProductItemProps {
    product: Product;
    navigation: any;
    darkMode?: boolean;
}

export const ProductItem = memo(({ product, navigation, darkMode = false }: ProductItemProps) => {
    const theme = useTheme();

    const handleProductPress = () => {
        navigation.navigate(ScreenComponent.Product, { id: product.id });
    };

    const isOnSale = product.discount > 0;
    const inStock = product.inStock;
    const discountAmount = product.discount;

    const { text: badgeText, textColor, backgroundColor } = getBadgeText({ inStock, isOnSale, discountAmount });

    return (
        <Grid
            item
            xs={5.35}
            sm={3.5}
            lg={2.5}
            sx={{
                backgroundColor: theme.customColors.cardBackground,
                flexDirection: "column",
            }}
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
            borderColor={theme.palette.divider}
            borderRadius={4}
            padding={1.5}
            onClick={handleProductPress}
        >
            <Grid
                item
                marginBottom={2}
                style={{ position: "relative" }}
            >
                <View style={{ backgroundColor: "white", overflow: "hidden", borderRadius: 10 }}>
                    <Image
                        source={
                            product.images["thumbnail"]?.url
                                ? { uri: product.images["thumbnail"].url }
                                : require("../assets/no-image.png")
                        }
                        style={{
                            resizeMode: "contain",
                            aspectRatio: 1 / 1,
                        }}
                    />
                </View>
                <Grid
                    item
                    display={badgeText ? "flex" : "none"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    boxShadow={"0px 2px 2px rgba(0, 0, 0, 0.1)"}
                    sx={{
                        position: "absolute",
                        top: 12,
                        right: -12,
                        backgroundColor: backgroundColor,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 1.5,
                        paddingY: 0.5,
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: textColor,
                            fontSize: "10px",
                            fontWeight: "bold",
                        }}
                    >
                        {badgeText} {inStock && `-${discountAmount}%`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        letterSpacing: 0.5,
                        color: theme.palette.text.primary,
                    }}
                >
                    {product.name}
                </Typography>

                <ProductPrice
                    price={Number(product.price)}
                    discount={Number(product.discount)}
                />
            </Grid>
        </Grid>
    );
});

// We can remove the StyleSheet since we're using MUI styling with the theme in the component
