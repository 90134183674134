import React, { useEffect, useState } from "react";
import { Text, StyleSheet, Animated, Image, Platform } from "react-native";
import { BasicButton } from "../../../components/BasicButton";
import { BasicTextField } from "../../../components/BasicTextField";
import { Container, Box, Grid, useTheme, Typography } from "@mui/material";
import PortalService from "../../../services/supabase/PortalService";
import * as _ from "lodash";
import { primaryColor, stateErrorColor } from "../../../config/constants";
import { appConfigStore } from "../../../app/stores/AppConfig";

export const PortalLogin = () => {
	const { signInWithEmailAndPassword } = PortalService();
	const { darkMode } = appConfigStore();
	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const theme = useTheme();

	useEffect(() => {
		fadeIn();
	}, []);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setErrorMessage(null);
		const data = new FormData(event.currentTarget);
		const email = data.get("email") as string;
		const password = data.get("password") as string;

		const { success, message } = await signInWithEmailAndPassword(email, password);
		setLoading(false);
		if (!success) setErrorMessage(message);
	};

	return (
		<Animated.View
			style={{
				opacity: fadeAnimation,
			}}
		>
			<Container
				component={"main"}
				maxWidth={"xs"}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						backgroundColor: theme.customColors.cardBackground,
						borderRadius: 2,
					}}
					boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.1)"}
				>
					<Box
						component={"form"}
						onSubmit={handleSubmit}
						sx={{ mt: 2, mx: 4 }}
					>
						<Grid
							container
							mb={2}
							mt={1}
							flexDirection={"column"}
							spacing={1}
						>
							<Grid item style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
								<img
									src={darkMode ? require("../../../assets/icon-white-bg.svg") : require("../../../assets/icon-black-bg.svg")}
									style={{
										width: 60,
										height: 60,
										objectFit: "contain",
									}}
									alt="Company Logo"
								/>
							</Grid>
							<Grid item>
								<Typography
									variant="h5"
									sx={{
										fontSize: 22,
										fontWeight: 600,
										fontFamily: "primaryFont",
										textAlign: "center",
										color: theme.palette.text.primary,
									}}
								>
									Admin Portal
								</Typography>
							</Grid>
							<Grid
								item
								sx={{ mb: 1 }}
							>
								<Typography
									variant="body1"
									sx={{
										fontSize: 16,
										fontWeight: 400,
										fontFamily: "primaryFont",
										textAlign: "center",
										color: theme.palette.text.secondary,
									}}
								>
									Restricted to authorized users only
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"email"}
									name={"email"}
									label={"Email"}
									margin={"normal"}
									inputProps={{ style: { color: theme.palette.text.primary } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"password"}
									name={"password"}
									label={"Password"}
									type={"password"}
									inputProps={{ style: { color: theme.palette.text.primary } }}
								/>
							</Grid>
							<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
								<Typography
									variant="body2"
									sx={{
										color: stateErrorColor,
										textAlign: "center",
										marginTop: 1.25,
										fontFamily: "primaryFont",
									}}
								>
									{errorMessage}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							mt={1}
							mb={4}
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							spacing={2}
						>
							<Grid item>
								<BasicButton
									title={"Login"}
									type={"submit"}
									style={{ width: 130, backgroundColor: primaryColor }}
									isLoading={loading}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	title: {
		fontSize: 22,
		fontWeight: "600",
		fontFamily: "primaryFont",
		marginBottom: 4,
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 16,
		fontWeight: "400",
		fontFamily: "primaryFont",
		textAlign: "center",
		color: "black",
	},
	errorMessage: {
		color: stateErrorColor,
		textAlign: "center",
		marginTop: 10,
		fontFamily: "primaryFont",
	},
});
