import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { NavBarItem } from "./NavBarItem";
import { NavBarLogo } from "./NavBarLogo";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { BecomeMember } from "../../BecomeMember";
import { CartIcon } from "../../CartIcon";
import { ProfileIcon } from "../../ProfileIcon";
import { userStore } from "../../../app/stores/User";
import { ThemeToggle } from "../../ThemeToggle";
import { useTheme } from "@mui/material";

interface NavBarProps {
	screens: ScreenComponent[];
	align?: "center" | "flex-start" | "flex-end";
}

export const NavBar = ({ align = "center" }: NavBarProps) => {
	const { navigateToScreen } = useScreenComponent();
	const theme = useTheme();

	const { darkMode } = appConfigStore((state) => state);
	const { showNavigationBar, navBarItemList, updateDefaultRoute } = navigationStore((state) => state);
	const { user } = userStore((state) => state);

	const [navHeightAnimation] = useState(new Animated.Value(0));
	const navHeight = 85;

	useEffect(() => {
		if (showNavigationBar) {
			slideDown();
		} else {
			slideUp();
		}

	}, [showNavigationBar]);


	const slideDown = () => {
		Animated.timing(navHeightAnimation, {
			toValue: navHeight,
			duration: 750,
			delay: 250,
			useNativeDriver: false,
		}).start();
	};

	const slideUp = () => {
		Animated.timing(navHeightAnimation, {
			toValue: 0,
			duration: 750,
			delay: 250,
			useNativeDriver: false,
		}).start();
	};

	return (
		<Animated.View
			style={{
				flex: 1,
				height: navHeightAnimation,
				maxHeight: navHeightAnimation,
				flexDirection: "row",
				justifyContent: align,
				paddingHorizontal: 40,
				backgroundColor: darkMode ? "#1c1c1c" : "white",
				transform: [{
					translateY: navHeightAnimation.interpolate({
						inputRange: [0, navHeight],
						outputRange: [-navHeight, 0],
					}),
				}],
				zIndex: 1,
				elevation: 1,
				shadowRadius: 8,
				shadowOpacity: 0.1,
				shadowOffset: {
					width: 0,
					height: 6,
				},
			}}
		>
			<NavBarLogo />
			<Divider
				color={darkMode ? "white" : "black"}
				orientation="vertical"
				style={{
					width: 2,
					marginLeft: 22,
					marginRight: 14,
					height: 16,
					alignSelf: "center",
				}}
			/>
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					marginLeft: 40,
					alignItems: "center",
					justifyContent: align,
				}}
			>
				{
					navBarItemList.map((text, index) => {
						return (
							<NavBarItem
								key={index}
								screen={text}
							/>
						);
					})
				}
			</View>
			<View style={{ flexDirection: "row", gap: 14 }}>
				{
					!user.authenticated &&
					<BecomeMember
						darkMode={darkMode}
						onClick={() => {
							updateDefaultRoute(DefaultRoute.Register);
							navigateToScreen(ScreenComponent.Register);
						}}
					/>
				}
				<ThemeToggle />
				<ProfileIcon
					isAuthenticated={user.authenticated}
					firstName={user?.info?.user_metadata?.firstName ?? ""}
					darkMode={darkMode}
					role={user.role}
					onClick={() => {
						updateDefaultRoute(DefaultRoute.Login);
						navigateToScreen(ScreenComponent.Login);
					}}
				/>
				<CartIcon />
			</View>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 50,
		aspectRatio: 1,
		resizeMode: "contain",
		alignSelf: "center",
		borderRadius: 25,
	},
	title: {
		marginLeft: 12,
		alignSelf: "center",
		fontSize: 20,
		fontWeight: "900",
		letterSpacing: 0.5,
		color: "white",
	},
});
