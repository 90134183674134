import Divider from "@mui/material/Divider";
import { StyleSheet, View } from "react-native";
import { appConfigStore } from "../app/stores/AppConfig";
import { AppLogo } from "./AppLogo";

interface Props {
	enableDivider?: boolean;
	showLogoText?: boolean;
}

export const Header = ({ enableDivider = true, showLogoText = true }: Props) => {
	const darkMode = appConfigStore((state) => state.darkMode);

	return (
		<View style={{ marginBottom: 24 }}>
			<View style={styles.heroContainer}>
				<AppLogo showText={showLogoText} size={50} />
			</View>
			{enableDivider && (
				<Divider
					color={darkMode ? "white" : "black"}
					style={styles.divider}
					orientation={"horizontal"}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	heroContainer: {
		justifyContent: "center",
		alignItems: "center",
		margin: 10,
	},
	divider: {
		marginTop: 12,
		width: 300,
	},
});
