import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { primaryColor } from "../config/constants";
import { isLarge } from "../navigation/HomeStack";

interface ImagePreviewerProps {
    images: { [key: string]: { url: string } };
}

export const ImagePreviewer: React.FC<ImagePreviewerProps> = ({ images }) => {
    const imageArray = Object.values(images);
    const [selectedImage, setSelectedImage] = useState(imageArray[0]?.url);

    return (
        <Grid container spacing={1} justifyContent={"center"} >
            <Image source={{ uri: selectedImage }} style={[styles.mainImage, { width: isLarge() ? "80%" : "50%" }]} />
            <Grid container spacing={1} justifyContent={"center"} mt={2}>
                {imageArray.map((image, index) => (
                    <TouchableOpacity
                        key={index}
                        onPress={() => setSelectedImage(image.url)}
                    >
                        <Image
                            source={{ uri: image.url }}
                            style={[
                                styles.thumbnail,
                                selectedImage === image.url && { borderColor: primaryColor }
                            ]}
                        />
                    </TouchableOpacity>
                ))}
            </Grid>
        </Grid>
    );
};

const styles = StyleSheet.create({
    mainImage: {
        aspectRatio: 1 / 1,
        resizeMode: "contain",
        borderRadius: 32,
        alignSelf: "center",
        borderWidth: 2,
        borderColor: "#ececec",
        backgroundColor: "white",
    },
    thumbnail: {
        width: 60,
        height: 60,
        borderRadius: 12,
        margin: 5,
        borderWidth: 1,
        borderColor: "#ececec",
        resizeMode: "contain",
        backgroundColor: "white",
    },
});
