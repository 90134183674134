import React, { useEffect, useState } from "react";
import { Animated, ScrollView, Image, View } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { BasicTextField } from "../../components/BasicTextField";
import { BasicPasswordField } from "../../components/BasicPasswordField";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import AuthService from "../../services/supabase/AuthService";
import { RouteProp, useIsFocused, useRoute } from "@react-navigation/native";
import * as _ from "lodash";
import { primaryColor, stateErrorColor } from "../../config/constants";
import { appConfigStore } from "../../app/stores/AppConfig";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";

// Define the type for route parameters
type LoginRouteParams = {
	firstName?: string;
	error?: string;
	confirmationURL?: string;
};

type LoginScreenRouteProp = RouteProp<{ Login: LoginRouteParams }, 'Login'>;

export const Login = () => {
	const route = useRoute<LoginScreenRouteProp>();
	const isFocused = useIsFocused();
	const { navigateToScreen } = useScreenComponent();
	const [fadeAnimation] = useState(new Animated.Value(0));
	const { signInWithEmailAndPassword } = AuthService();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [firstName, setFirstName] = useState<string | null>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const { darkMode } = appConfigStore((state) => state);

	useEffect(() => {
		if (isFocused) {
			fadeIn();

			const hasParams = _.has(route, 'params');
			const hasFirstName = _.has(route.params, 'firstName');
			const hasErrorMessage = _.has(route.params, 'error');

			if (hasParams && hasFirstName) {
				setFirstName(route.params.firstName ?? "Sign In");
			}

			if (hasParams && hasErrorMessage) {
				setErrorMessage(route.params.error ?? "An error occurred");
			}
		}
	}, [isFocused]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const fadeOut = (doAfter: () => void) => {
		return Animated.timing(fadeAnimation, {
			toValue: 0,
			duration: 750,
			useNativeDriver: false,
		}).start(() => {
			doAfter();
		});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setErrorMessage(null);
		const data = new FormData(event.currentTarget);
		const email = data.get("email") as string;
		const password = data.get("password") as string;

		const { success, message } = await signInWithEmailAndPassword(email, password);

		if (success) navigateToScreen(ScreenComponent.Shop);
		else setErrorMessage(message);

		setLoading(false);
	};

	return (
		<Animated.View
			style={{
				opacity: fadeAnimation,
				backgroundColor: theme.customColors.background,
				flex: 1,
				position: "relative",
			}}
		>
			{/* Background image for all views */}
			<View style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				zIndex: 0
			}}>
				<Image
					source={require("../../assets/background-video.gif")}
					style={{
						width: "100%",
						height: "100%",
						resizeMode: "cover"
					}}
				/>
				{/* Darker overlay for desktop, lighter for mobile */}
				<View style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: darkMode ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.7)"
				}} />
			</View>

			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					height: "100%",
					width: "100%",
					position: "relative",
					zIndex: 1
				}}
			>
				{!isMobile && (
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							position: "relative",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-end",
							p: 4
						}}
					>
						<Box
							sx={{
								position: "relative",
								zIndex: 1,
								bgcolor: theme.customColors.cardBackground,
								p: 3,
								borderRadius: 2,
								maxWidth: "80%"
							}}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 700,
									color: theme.palette.text.primary,
									mb: 2
								}}
							>
								Welcome Back, Shopper!
							</Typography>
							<Typography
								variant="subtitle2"
								sx={{
									color: theme.palette.text.primary,
									mb: 2
								}}
							>
								Sign in to access your personalized shopping experience. View your order history, unlock saved items in your cart, and continue enjoying member-exclusive pricing on our entire collection. Your next
							</Typography>
						</Box>
					</Box>
				)}

				<Box
					sx={{
						height: "100%",
						width: { xs: "100%", md: "50%" },
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						p: { xs: 3, sm: 5, md: 8 },
						backgroundColor: isMobile ? "transparent" : theme.customColors.cardBackground
					}}
				>
					<ScrollView
						contentContainerStyle={{
							flexGrow: 1,
							justifyContent: "center",
							maxWidth: 480,
							width: "100%"
						}}
						showsVerticalScrollIndicator={true}
					>
						<Image
							source={darkMode ? require("../../assets/icon-white-bg.svg") : require("../../assets/icon-black-bg.svg")}
							style={{
								width: 50,
								height: 50,
								resizeMode: "contain",
								alignSelf: "center",
							}}
						/>
						{
							firstName ?
								(
									<>
										<Typography
											variant="h5"
											align="center"
											sx={{
												mt: 3,
												mb: 0.5,
												fontWeight: 600,
												color: isMobile ? theme.palette.text.primary : theme.palette.text.primary
											}}
										>
											Welcome, {firstName}
										</Typography>
										<Typography
											variant="subtitle1"
											align="center"
											sx={{
												mb: 3,
												fontWeight: 600,
												color: isMobile ? theme.palette.text.primary : theme.palette.text.primary
											}}
										>
											Sign in to continue
										</Typography>
									</>
								)
								:
								(
									<>
										<Typography
											variant="h5"
											align="center"
											sx={{
												mt: 3,
												mb: 0.5,
												fontWeight: 600,
												color: isMobile ? theme.palette.text.primary : theme.palette.text.primary
											}}
										>
											Sign In
										</Typography>
										<Typography
											variant="body1"
											align="center"
											sx={{
												mb: 6,
												color: isMobile ? theme.palette.text.primary : theme.palette.text.primary
											}}
										>
											Your personalised experience
										</Typography>
									</>
								)
						}

						<Box
							component={"form"}
							onSubmit={handleSubmit}
							sx={{
								width: "100%",
								...(isMobile && {
									backgroundColor: theme.customColors.cardBackground,
									p: 3,
									borderRadius: 2,
									boxShadow: "0 4px 20px rgba(0,0,0,0.08)"
								})
							}}
						>
							<Grid
								container
								spacing={1.5}
							>
								<Grid
									item
									xs={12}
								>
									<BasicTextField
										fullWidth
										id="email"
										name="email"
										label="Email"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									>
									<BasicPasswordField
										fullWidth
										id="password"
										name="password"
										label="Password"
										InputProps={{ style: { color: theme.palette.text.primary } }}
									/>
								</Grid>
								<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
									<Typography
										variant="body2"
										align="center"
										sx={{
											mt: 1.25,
											color: stateErrorColor
										}}
									>
										{errorMessage}
									</Typography>
								</Grid>
							</Grid>

							<BasicButton
								title={"Sign In"}
								type={"submit"}
								style={{
									marginTop: 16,
									marginBottom: 16,
									width: "100%",
									backgroundColor: primaryColor
								}}
								isLoading={loading}
							/>

							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant="body2"
									sx={{
										display: "inline",
										color: theme.palette.text.secondary
									}}
								>
									Don't have an account?{" "}
								</Typography>
								<BasicButton
									title={"Create an account"}
									variant={"text"}
									style={{
										display: "inline",
										padding: "0 4px",
										minWidth: "auto",
										color: theme.palette.text.primary,
										fontWeight: 600
									}}
									onClick={() => {
										fadeOut(() => {
											navigateToScreen(ScreenComponent.Register);
										});
									}}
								/>
							</Box>
						</Box>
					</ScrollView>
				</Box>
			</Box>
		</Animated.View>
	);
};
