import { DrawerNavigation } from "../components/navigation/Drawer/DrawerNavigation";
import { BarNavigation } from "../components/navigation/Bar/BarNavigation";
import { useWindowDimensions, Animated } from "react-native";
import Cart from "../screens/Main/Cart";
import { navigationStore } from "../app/stores/Navigation";
import { Drawer } from "@mui/material";
import { useEffect, useState, useRef, useMemo } from "react";
import { appConfigStore } from "../app/stores/AppConfig";

export const isLarge = () => {
	const windowDimensions = useWindowDimensions();
	const screenWidth = windowDimensions.width;
	return screenWidth > 870;
};

export const HomeStack = () => {
	const { cartDrawerOpen, updateCartDrawerOpen } = navigationStore((state) => state);
	const { darkMode } = appConfigStore((state) => state);
	const [darkModeDrawerOpen, setDarkModeDrawerOpen] = useState(false);
	const [transitionText, setTransitionText] = useState("");
	const [targetMode, setTargetMode] = useState(false);
	const [animationComplete, setAnimationComplete] = useState(true);
	const contentFadeAnim = useRef(new Animated.Value(0)).current;
	const scaleAnim = useRef(new Animated.Value(0.8)).current;
	const spinnerRotateAnim = useRef(new Animated.Value(0)).current;
	const prevDarkMode = useRef(darkMode);

	// Create a rotation interpolation for the spinner effect
	const spinnerRotation = spinnerRotateAnim.interpolate({
		inputRange: [0, 1],
		outputRange: ["0deg", "360deg"]
	});

	// Function to handle theme transition
	const handleThemeTransition = () => {
		// Start transition
		setTargetMode(darkMode);
		setTransitionText(darkMode ? "Going Dark" : "Lightening the Mood");
		setAnimationComplete(false);

		// Reset animations
		contentFadeAnim.setValue(0);
		scaleAnim.setValue(0.8);
		spinnerRotateAnim.setValue(0);

		// Open drawer immediately
		setDarkModeDrawerOpen(true);

		// Create sequential animation
		Animated.sequence([
			// Allow drawer to open fully
			Animated.delay(800),

			// Start content fade-in and scale-up together
			Animated.parallel([
				// Fade in content
				Animated.timing(contentFadeAnim, {
					toValue: 1,
					duration: 1200,
					useNativeDriver: true,
				}),

				// Scale up content
				Animated.timing(scaleAnim, {
					toValue: 1,
					duration: 1500,
					useNativeDriver: true,
				}),

				// Rotate spinner
				Animated.timing(spinnerRotateAnim, {
					toValue: 2,  // Two full rotations
					duration: 2000,
					useNativeDriver: true,
				})
			]),

			// Keep animation visible for longer
			Animated.delay(3000),

			// Fade out content before closing
			Animated.timing(contentFadeAnim, {
				toValue: 0,
				duration: 800,
				useNativeDriver: true,
			})
		]).start(() => {
			// Animation complete - close drawer
			setDarkModeDrawerOpen(false);
			setAnimationComplete(true);
		});
	};

	// Detect darkMode changes and trigger transition drawer
	useEffect(() => {
		if (darkMode !== prevDarkMode.current && animationComplete) {
			handleThemeTransition();
		}

		prevDarkMode.current = darkMode;
	}, [darkMode]);

	const styleBasedOnScreenSize = () => {
		const radius = 14;
		if (isLarge()) {
			return {
				minWidth: "45%",
				maxWidth: "45%",
				borderTopLeftRadius: radius,
				borderBottomLeftRadius: radius,
			};
		} else {
			return {
				minHeight: "85%",
				maxHeight: "85%",
				borderTopLeftRadius: radius,
				borderTopRightRadius: radius,
			};
		}
	};

	// Memoize the Cart component to prevent unnecessary re-renders
	const MemoizedCart = useMemo(() => <Cart />, []);

	return (
		<>
			{isLarge() ? <BarNavigation /> : <DrawerNavigation />}

			{/* Cart Drawer */}
			<Drawer
				variant="temporary"
				open={cartDrawerOpen}
				onClose={() => updateCartDrawerOpen(false)}
				anchor={isLarge() ? "right" : "bottom"}
				PaperProps={{
					sx: styleBasedOnScreenSize()
				}}
			>
				{MemoizedCart}
			</Drawer>
		</>
	);
};
