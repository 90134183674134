import { TouchableOpacity } from "react-native";
import { Grid, Typography, Box, TextField, InputAdornment } from "@mui/material";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { AppHeaderName } from "../../config/constants";
import { useState } from "react";
import { BasicButton } from "../BasicButton";

// Brand/Logo Section
const BrandSection = () => {
    return (
        <Grid item xs={12} md={5.5} sx={{ mb: { xs: 4, md: 0 } }}>
            <Typography
                variant="h3"
                gutterBottom
                sx={{
                    color: "#fff",
                    fontWeight: 700,
                    fontFamily: "logoFont",
                    letterSpacing: "1px",
                    mb: 3,
                    textAlign: { xs: "center", md: "center" }
                }}>
                {AppHeaderName}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: "#fff", opacity: 0.8, maxWidth: { xs: "100%", md: "100%" }, textAlign: { xs: "center", md: "center" } }}>
                {"Where precision meets style. Experience signature cuts, and expert beard sculpting from master barber who elevate grooming to an art form."}
            </Typography>
        </Grid>
    );
};

// Quick Info Section
const QuickInfoSection = () => {
    const { navigateToScreen } = useScreenComponent();

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
                mb: { xs: 4, md: 0 },
                textAlign: { xs: "center", md: "left" },
            }}
        >
            <Typography variant="subtitle2" gutterBottom sx={{
                color: "#fff",
                fontWeight: 600,
                letterSpacing: "0.5px",
                mb: 3
            }}>
                QUICK INFO
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TouchableOpacity onPress={() => navigateToScreen(ScreenComponent.Shop)}>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>AFFILIATES</Typography>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigateToScreen(ScreenComponent.Orders)}>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>PRIVACY POLICY</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>TERMS & CONDITIONS</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>REFUND POLICY</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>AFFILIATES POLICY</Typography>
                </TouchableOpacity>
            </Box>
        </Grid>
    );
};

// Our Products Section
const ProductsSection = () => {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
                mb: { xs: 4, md: 0 },
                textAlign: { xs: "center", md: "left" }
            }}
        >
            <Typography variant="subtitle2" gutterBottom sx={{
                color: "#fff",
                fontWeight: 600,
                letterSpacing: "0.5px",
                mb: 3
            }}>
                OUR PRODUCTS
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>POSTX</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>PRODUCTX</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>WHOLESALEX</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>COBLOG</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>COBLOG</Typography>
                </TouchableOpacity>
            </Box>
        </Grid>
    );
};

// Resources Section
const ResourcesSection = () => {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
                mb: { xs: 4, md: 0 },
                textAlign: { xs: "center", md: "left" },
            }}
        >
            <Typography variant="subtitle2" gutterBottom sx={{
                color: "#fff",
                fontWeight: 600,
                letterSpacing: "0.5px",
                mb: 3,
            }}>
                RESOURCES
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>BLOG</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>DOCUMENTATION</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>FAQ</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>CONTACT</Typography>
                </TouchableOpacity>
                <TouchableOpacity>
                    <Typography variant="caption" sx={{
                        color: "#fff",
                        opacity: 0.7,
                        mb: 1.5,
                        transition: "opacity 0.3s ease",
                        "&:hover": { opacity: 1, fontWeight: 600 }
                    }}>ABOUT</Typography>
                </TouchableOpacity>
            </Box>
        </Grid>
    );
};

// Newsletter Section
const NewsletterSection = () => {
    const [email, setEmail] = useState("");

    const handleSubscribe = () => {
        // Handle subscription logic here
        console.log("Subscribe with email:", email);
        setEmail("");
    };

    return (
        <Grid
            item
            xs={12}
            md={3}
            sx={{
                display: { xs: "flex", md: "block" },
                justifyContent: { xs: "center", md: "flex-start" },
                width: "100%",
                alignSelf: "center",
            }}
        >
            <Box
                sx={{
                    p: 3,
                    borderRadius: 8,
                    backgroundColor: "#212121",
                    width: { xs: "90%", sm: "80%", md: "100%" },
                    maxWidth: { xs: "400px", md: "none" },
                    alignSelf: { md: "center" }
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        color: "#fff",
                        fontWeight: 600,
                        letterSpacing: "0.5px",
                    }}>
                    Subscribe to our Newsletter
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        color: "#fff",
                        opacity: 0.7,
                    }}>
                    Get the latest news and updates
                </Typography>

                <Box sx={{ display: "flex", flexDirection: { xs: "row", sm: "row", md: "column", xl: "row" }, gap: 1, alignItems: "center", mb: 1, mt: 1 }}>
                    <TextField
                        fullWidth
                        placeholder="Email"
                        variant="outlined"
                        size="small"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            backgroundColor: "#333",
                            input: { color: "#fff" },
                            fontSize: 8,
                            borderRadius: 12,
                            "& .MuiOutlinedInput-root": {
                                borderRadius: 12,
                                "& fieldset": {
                                    borderColor: "#444",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#555",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#666",
                                },
                            },
                        }}
                    />
                    <BasicButton
                        title="Subscribe"
                        variant="contained"
                        sx={{
                            width: { xs: 160, md: "100%" },
                            // ml: 1,
                            backgroundColor: "#fff",
                            color: "#000",
                            fontWeight: 600,
                            "&:hover": {
                                backgroundColor: "#eee",
                            },
                            height: 36 // Matching "small" size
                        }}
                        onClick={handleSubscribe}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

export const FooterTopSection = () => {
    return (
        <Grid
            container
            justifyContent={{ xs: "center", md: "center" }}
        >
            <BrandSection />
            {/* <Grid
                item
                xs={12}
                md={6}
                gap={1}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: { xs: "center", sm: "flex-start" },
                }}
            > */}
            {/* <QuickInfoSection /> */}
                {/* <ProductsSection /> */}
            {/* <ResourcesSection /> */}
            {/* </Grid> */}
            {/* <NewsletterSection /> */}
        </Grid>
    );
}; 