import { DoneRounded, ExpandMore, ExpandLess } from "@mui/icons-material";
import { TableRow, TableCell, Collapse, Box, Grid, Typography, Select, MenuItem, Divider, useTheme } from "@mui/material";
import { BasicButton } from "../../../../../components/BasicButton";
import { OrderDetails } from "../../../../../models/DB";
import { useState } from "react";
import { VAT_PERCENTAGE } from "../../../../../config/constants";
import { getDeliveryTypeSetup, getOrderPaymentDetails } from "../../../../../utils/customFunctions";
import { OrderItemCard } from "../../../../../components/OrderItemCard";
import PortalService from "../../../../../services/supabase/PortalService";
import { OrderStatusBadgeCompact } from "../../../../../components/OrderStatusBadge";

interface OrderEditorProps {
    order: OrderDetails;
    open: boolean;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    orders: OrderDetails[];
    setOrders: (orders: OrderDetails[]) => void;
}

export const OrderEditor = ({
    order,
    open,
    isEditing,
    setIsEditing,
    orders,
    setOrders
}: OrderEditorProps) => {
    const theme = useTheme();
    const { updateOrder } = PortalService();
    const [isLoading, setIsLoading] = useState(false);
    const [orderData, setOrderData] = useState<OrderDetails>({
        ...order,
    });
    const [expandItems, setExpandItems] = useState(false);


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        setIsLoading(true);
        // Update order status logic here
        const updatedOrder = await updateOrder(orderData);
        if (updatedOrder) {
            // Handle array response - take the first item since we expect a single order
            const orderItem = Array.isArray(updatedOrder) ? updatedOrder[0] : updatedOrder;

            // Transform the order data to match our model structure
            const transformedOrder: OrderDetails = {
                ...orderItem,
                order: orderItem.order.map(item => ({
                    ...item,
                    product: {
                        ...item.product,
                        // Convert string values to numbers where needed
                        discount: typeof item.product.discount === "string" ?
                            parseFloat(item.product.discount) : item.product.discount,
                        stockOnHand: typeof item.product.stockOnHand === "string" ?
                            parseFloat(item.product.stockOnHand) : item.product.stockOnHand,
                        // Transform images structure if needed
                        images: item.product.images && typeof item.product.images === "object" ?
                            // Handle case where images is an object like {thumbnail: {url: "..."}}
                            Object.entries(item.product.images as Record<string, { url: string }>)
                                .map(([type, data]) => ({
                                    type,
                                    url: data.url
                                }))
                            : (item.product.images || []) // Default to empty array if undefined
                    }
                }))
            };

            setOrderData(transformedOrder);
            setOrders(orders.map(order => order.id === orderItem.id ? transformedOrder : order));
        }
        else {
            console.log("Error updating order");
        }

        setIsLoading(false);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setOrderData({ ...order });
        setIsEditing(false);
    };

    const handleStatusChange = (event) => {
        setOrderData({ ...orderData, status: event.target.value });
    };

    const DeliverTypeBlock = ({ deliveryType }) => {
        const { backgroundColor, borderColor, textColor, text } = getDeliveryTypeSetup(deliveryType, theme);

        return (
            <Grid
                item
                sx={{
                    display: "inline-block",
                    border: 1,
                    borderColor: borderColor,
                    backgroundColor: backgroundColor,
                    borderRadius: 2,
                    padding: 1.5,
                    paddingY: 0.5,
                    marginTop: 1,
                }}
            >
                <Typography
                    color={textColor}
                    fontSize={11}
                    fontWeight={"bold"}
                    letterSpacing={0.5}
                    textAlign={"center"}
                    fontFamily={"primaryFont"}
                >{`${text}`}</Typography>
            </Grid>
        );
    };

    const OrderItems = ({ orderItems }) => {
        return (
            <Grid
                item
                xs={12}
                marginY={{ lg: 4 }}
            >
                {/* Render order items here */}
                {orderItems.map((item, index) => (
                    // convert to a component called OrderItemCard
                    <OrderItemCard
                        key={`orderItemCard_${index}`}
                        item={item}
                    />
                ))}
            </Grid>
        );
    };

    const OrderSummary = ({ orderItems, deliveryFee }) => {
        const { baseCost, vat, totalCost } = getOrderPaymentDetails(orderItems, deliveryFee);

        return (
            <Grid item xs={12} mb={2}>
                <Typography
                    letterSpacing={0.5}
                    fontFamily={"primaryFont"}
                    fontSize={16}
                    fontWeight={"bold"}
                >
                    Order Summary
                </Typography>
                <Grid container justifyContent="space-between" mt={1}>
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        Order Cost:
                    </Typography>
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        R {baseCost.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        Delivery Fee:
                    </Typography>
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        R {deliveryFee}.00
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        VAT ({VAT_PERCENTAGE}%):
                    </Typography>
                    <Typography color={theme.palette.text.secondary} fontSize={12} letterSpacing={0.5} fontFamily={"primaryFont"}>
                        R {vat.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between" mt={1} mb={2}>
                    <Typography
                        color={theme.palette.text.primary}
                        fontSize={16}
                        letterSpacing={0.5}
                        fontFamily={"primaryFont"}
                        fontWeight="bold"
                    >
                        Total:
                    </Typography>
                    <Typography
                        color={theme.palette.text.primary}
                        fontSize={16}
                        letterSpacing={0.5}
                        fontFamily={"primaryFont"}
                        fontWeight="bold"
                    >
                        R {totalCost.toFixed(2)}
                    </Typography>
                </Grid>
                <Divider />
            </Grid>
        );
    };

    return (
        <TableRow>
            <TableCell
                style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: theme.palette.mode === "dark"
                        ? theme.palette.background.default
                        : theme.palette.grey[100]
                }}
                colSpan={6}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ marginX: 2, marginY: 4, padding: 1 }}>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid
                                item
                            >
                                <Grid item container xs={9} sm={6} justifyContent={{ xs: "flex-end", sm: "flex-end" }}>
                                    {isEditing ? (
                                        <Grid>
                                            <BasicButton
                                                title="Save"
                                                variant="contained"
                                                onClick={handleSave}
                                                startIcon={<DoneRounded />}
                                                sx={{ marginRight: 1, maxHeight: 30, fontSize: 9 }}
                                                style={{ backgroundColor: theme.palette.success.main }}
                                                isLoading={isLoading}
                                            />
                                            <BasicButton
                                                title="Cancel"
                                                variant="outlined"
                                                onClick={handleCancel}
                                                sx={{
                                                    maxHeight: 30,
                                                    fontSize: 9,
                                                    backgroundColor: theme.palette.background.paper
                                                }}
                                            />
                                        </Grid>
                                    ) : (
                                            <Grid>
                                                <BasicButton
                                                    title="Edit"
                                                    variant="outlined"
                                                    onClick={handleEdit}
                                                sx={{
                                                    marginRight: 1,
                                                    maxHeight: 30,
                                                    fontSize: 9,
                                                    backgroundColor: theme.palette.background.paper
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2.5} mt={{ xs: 6, sm: 0 }}>
                            {/*  Left-Side  */}
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={2.5}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={16} fontWeight={"bold"} fontFamily={"primaryFont"}>
                                            Order Status
                                        </Typography>
                                        <Select
                                            sx={{ paddingX: 2, fontSize: 12, marginTop: 2 }}
                                            value={orderData?.status}
                                            onChange={handleStatusChange}
                                            variant="outlined"
                                            disabled={!isEditing}
                                            size="small"
                                        >
                                            <MenuItem value={1} sx={{ fontSize: 12 }}>
                                                <OrderStatusBadgeCompact status={1} />
                                            </MenuItem>
                                            <MenuItem value={2} sx={{ fontSize: 12 }}>
                                                <OrderStatusBadgeCompact status={2} />
                                            </MenuItem>
                                            <MenuItem value={3} sx={{ fontSize: 12 }}>
                                                <OrderStatusBadgeCompact status={3} />
                                            </MenuItem>
                                            <MenuItem value={4} sx={{ fontSize: 12 }}>
                                                <OrderStatusBadgeCompact status={4} />
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={16} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={0.5}>
                                            Delivery Details
                                        </Typography>
                                        <DeliverTypeBlock deliveryType={orderData?.deliveryType} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={16} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={0.5}>
                                            Shipping Details
                                        </Typography>
                                        {orderData?.shippingDetails &&
                                            Object.entries(orderData?.shippingDetails).map(([key, value]) => (
                                                <Typography
                                                    color={theme.palette.text.secondary}
                                                    fontSize={14}
                                                    key={key}
                                                >
                                                    {`${value}`}
                                                </Typography>
                                            ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={16} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={0.5}>
                                            Contact Details
                                        </Typography>
                                        {/* <Typography color={theme.palette.text.secondary} fontSize={14}>
                                            {orderData?.contactDetails?.firstName} {orderData?.contactDetails?.lastName}
                                        </Typography> */}
                                        {Object.entries(orderData?.contactDetails)
                                            .filter(([key]) => key !== "firstName" && key !== "lastName")
                                            .map(([key, value]) => (
                                                <Typography
                                                    color={theme.palette.text.secondary}
                                                    fontSize={14}
                                                    key={key}
                                                >
                                                    {`${value}`}
                                                </Typography>
                                            ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*  Right-Side  */}
                            <Grid item xs={12} md={7}>
                                <OrderSummary
                                    orderItems={orderData?.order}
                                    deliveryFee={orderData?.deliveryFee}
                                />
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 2
                                }}>
                                    <Typography
                                        fontSize={16}
                                        fontWeight={"bold"}
                                        letterSpacing={0.5}
                                        fontFamily={"primaryFont"}
                                        pr={1}
                                    >
                                        {`${orderData?.order?.length || 0} Items Purchased`}
                                    </Typography>
                                    <BasicButton
                                        onClick={() => setExpandItems(!expandItems)}
                                        startIcon={expandItems ? <ExpandLess /> : <ExpandMore />}
                                        title={expandItems ? "Collapse" : "Expand"}
                                        variant="text"
                                    />
                                </Box>
                                <Box sx={{
                                    display: expandItems ? "flex" : "none",
                                    maxHeight: { xs: "300px", md: "340px" },
                                    overflow: "auto",
                                    transition: "max-height 5s ease-in-out",
                                    paddingRight: 1,
                                    paddingLeft: 1,
                                    //show scroll bar below
                                    "&::-webkit-scrollbar": {
                                        width: 6,
                                        borderRadius: 3
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: theme.palette.mode === "dark"
                                            ? theme.palette.grey[700]
                                            : theme.palette.grey[400],
                                    },
                                }}>
                                    <OrderItems orderItems={orderData?.order} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
