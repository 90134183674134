import { Container, Grid, CircularProgress, useTheme } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { OrdersTable } from "./components/OrdersTable";
import PortalService from "../../../../services/supabase/PortalService";
import { OrderDetails } from "../../../../models/DB";

const Orders = () => {
    const theme = useTheme();
    const portalService = PortalService();
    const [isLoading, setIsLoading] = useState(false);
    const [allOrders, setAllOrders] = useState<OrderDetails[]>([]);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        setIsLoading(true);
        const orders = await portalService.getAllOrders();
        setAllOrders(orders);
        setIsLoading(false);
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ paddingX: { xs: 0, md: 2 } }}>
            {
                isLoading ?
                    <Grid container justifyContent="center" mt={20}>
                        <Grid item>
                            <CircularProgress
                                size={40}
                                sx={{ color: theme.palette.primary.main }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <OrdersTable
                        orders={allOrders}
                        refreshOrders={fetchOrders}
                    />
            }
        </Container>
    );
};

export default Orders;
