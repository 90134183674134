import { Grid, Typography, useTheme, IconButton, Box } from "@mui/material";
import { OrderItem } from "../models/DB";
import { Image, View, Animated, Easing } from "react-native";
import { Swipeable } from "react-native-gesture-handler";
import { useRef, useEffect, useState } from "react";
import { isLarge } from "../navigation/HomeStack";
import CloseIcon from "@mui/icons-material/Close";

interface OrderItemCardProps {
    item: OrderItem;
    onRemove?: (item: OrderItem) => void;
    autoOpen?: boolean;
    index?: number;
}

export const OrderItemCard = ({
    item,
    onRemove,
    autoOpen = false,
    index = 0
}: OrderItemCardProps) => {
    const theme = useTheme();
    const swipeableRef = useRef<Swipeable>(null);
    const isDesktop = isLarge();

    // Animation values
    const [isRemoving, setIsRemoving] = useState(false);
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const heightAnim = useRef(new Animated.Value(0)).current;

    // Fade in when component mounts
    useEffect(() => {
        // Start with 0 opacity and animate to 1
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 300,
            delay: index * 100, // Stagger the animations based on index
            useNativeDriver: true,
            easing: Easing.ease
        }).start();
    }, []);

    // Handle the remove animation
    const handleRemoveWithAnimation = () => {
        if (onRemove) {
            setIsRemoving(true);

            // Measure the current height to animate from
            const cardHeight = isDesktop ? 100 : 90; // Approximate height
            heightAnim.setValue(cardHeight);

            // Animate opacity to 0 (fade out)
            Animated.parallel([
                Animated.timing(fadeAnim, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: true,
                    easing: Easing.ease
                }),
                Animated.timing(heightAnim, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: false,
                    easing: Easing.ease
                })
            ]).start(() => {
                // Call the actual remove function after animation completes
                onRemove(item);
            });
        }
    };

    // Auto open the first item when the component mounts
    useEffect(() => {
        if (autoOpen && index === 0 && !isDesktop) {
            const timer = setTimeout(() => {
                if (swipeableRef.current) {
                    swipeableRef.current.openLeft();

                    // Auto close after showing the gesture
                    const closeTimer = setTimeout(() => {
                        if (swipeableRef.current) {
                            swipeableRef.current.close();
                        }
                    }, 1500);

                    return () => clearTimeout(closeTimer);
                }
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [autoOpen, index, isDesktop]);

    const renderRightActions = (progress: Animated.AnimatedInterpolation<number>) => {
        const trans = progress.interpolate({
            inputRange: [0, 1],
            outputRange: [100, 0],
        });

        return (
            <View style={{
                width: 100,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10
            }}>
                <Animated.Text
                    style={{
                        backgroundColor: theme.palette.error.main,
                        borderRadius: 14,
                        color: "white",
                        fontWeight: "bold",
                        transform: [{ translateX: trans }],
                        padding: 16
                    }}
                    onPress={handleRemoveWithAnimation}
                >
                    Remove
                </Animated.Text>
            </View>
        );
    };

    // The main card content
    const CardContent = () => (
        <Grid
            container
            direction="row"
            marginBottom={1}
            border={2}
            padding={1.5}
            paddingRight={2}
            alignItems={"center"}
            overflow={"visible"}
            boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
            borderColor={theme.palette.divider}
            borderRadius={4}
            sx={{
                borderColor: "transparent",
                backgroundColor: theme.customColors.cardBackground,
                position: "relative",
                flex: 1 // Take up all available space in the row
            }}
        >
            {/* Product Image */}
            <Grid item>
                <Image
                    source={
                        item.product?.images?.["thumbnail"]?.url
                            ? { uri: item.product.images["thumbnail"].url }
                            : require("../assets/no-image.png")
                    }
                    style={{
                        width: 65,
                        height: 65,
                        resizeMode: "contain",
                        borderRadius: 10,
                    }}
                />
            </Grid>

            {/* Product Details */}
            <Grid
                item
                style={{ marginLeft: 16, flex: 1 }}
            >
                <Typography
                    letterSpacing={0.5}
                    fontWeight={"bold"}
                    color={theme.palette.text.primary}
                >
                    {item.product.name}
                </Typography>
                <Typography
                    letterSpacing={0.5}
                    fontWeight={"bold"}
                    variant="caption"
                    color={theme.palette.text.secondary}
                >
                    Code: {item.product.code.substring(0, 6).toUpperCase()}
                </Typography>
                <Typography letterSpacing={0.5} />
                {
                    item.product.variantType && (
                        <Typography
                            letterSpacing={0.5}
                            fontWeight={"bold"}
                            variant="caption"
                            color={theme.palette.text.secondary}
                        >
                            {`${item.product.variantType}: ${item.selectedVariant}`}
                        </Typography>
                    )
                }
                <Typography letterSpacing={0.5} />
            </Grid>

            {/* Product Quantity */}
            <Grid item>
                {
                    item.quantity > 1 && (
                        <Typography
                            letterSpacing={0.5}
                            variant="caption"
                            fontWeight={"bold"}
                            color={theme.palette.text.secondary}
                            textAlign={"right"}
                        >
                            {`R ${item.product.price}  \t ${item.quantity > 1 ? "x" : ""} ${item.quantity > 1 ? `${item.quantity}` : ""
                                }`}
                        </Typography>
                    )
                }
                <Typography letterSpacing={0.5} />
                <Typography
                    letterSpacing={1}
                    fontWeight={"bold"}
                    variant="subtitle2"
                    textAlign={"right"}
                    color={theme.palette.text.primary}
                >
                    {`R ${(item.product.price * item.quantity).toFixed(2)}`}
                </Typography>
            </Grid>
        </Grid>
    );

    // If we're in the process of removing, wrap content in animated view for fade-out
    if (isRemoving) {
        return (
            <Animated.View
                style={{
                    opacity: fadeAnim,
                    height: heightAnim,
                    overflow: "hidden",
                    marginBottom: isDesktop ? 8 : 0
                }}
            >
                {isDesktop ? (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1.5,
                        position: "relative"
                    }}>
                        <CardContent />
                        <IconButton
                            size="small"
                            onClick={handleRemoveWithAnimation}
                            sx={{
                                backgroundColor: theme.palette.error.main,
                                color: "white",
                                width: 36,
                                height: 36,
                                flexShrink: 0,
                                transition: "transform 0.2s ease-in-out",
                                '&:hover': {
                                    backgroundColor: theme.palette.error.dark,
                                    transform: "scale(1.1)"
                                }
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ) : (
                    <CardContent />
                )}
            </Animated.View>
        );
    }

    // Regular rendering with fade-in animation
    const animatedStyle = {
        opacity: fadeAnim,
    };

    // Desktop layout with card and remove button side by side
    if (isDesktop) {
        return (
            <Animated.View style={animatedStyle}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 1,
                    gap: 1.5,
                    position: "relative"
                }}>
                    <CardContent />
                    <IconButton
                        size="small"
                        onClick={handleRemoveWithAnimation}
                        sx={{
                            backgroundColor: theme.palette.error.main,
                            color: "white",
                            width: 36,
                            height: 36,
                            flexShrink: 0,
                            transition: "transform 0.2s ease-in-out",
                            '&:hover': {
                                backgroundColor: theme.palette.error.dark,
                                transform: "scale(1.1)"
                            }
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Animated.View>
        );
    }

    // Mobile layout with swipeable
    return (
        <Animated.View style={animatedStyle}>
            <Swipeable
                ref={swipeableRef}
                renderRightActions={renderRightActions}
                friction={2.5}
                overshootRight={true}
                containerStyle={{
                    padding: 8,
                    overflow: "visible"
                }}
            >
                <CardContent />
            </Swipeable>
        </Animated.View>
    );
};