import { useEffect } from "react";
import { useDemoRouter } from "@toolpad/core/internal";
import { navigationStore } from "../../stores/Navigation";
import { PortalRoutes } from "../../../models/enums/PortalRoutes";

/**
 * Custom hook for handling portal routing
 * @param routeParams - Route parameters from React Navigation
 * @returns Object containing the router and navigation methods
 */
export const usePortalRouter = (routeParams?: any) => {
  const { currentPortalRoute, updateCurrentPortalRoute } = navigationStore((state) => state);
  const initialPath = currentPortalRoute || PortalRoutes.Products;
  const router = useDemoRouter(initialPath);

  // Handle route parameter changes and URL subdomain detection
  useEffect(() => {
    if (routeParams?.subdomain) {
      const subdomain = routeParams.subdomain;
      const portalRoute = `/${subdomain}` as PortalRoutes;
      if (Object.values(PortalRoutes).includes(portalRoute)) {
        navigateToPortalRoute(portalRoute);
      } else {
        navigateToPortalRoute(PortalRoutes.Dashboard);
      }
    } else {
      // Use cached route if available
      if (router.pathname && Object.values(PortalRoutes).includes(router.pathname as PortalRoutes)) {
        navigateToPortalRoute(router.pathname as PortalRoutes);
      }
    }
  }, [routeParams]);

  // Add a listener to update the store when the router pathname changes
  useEffect(() => {
    if (router.pathname && Object.values(PortalRoutes).includes(router.pathname as PortalRoutes)) {
      if (router.pathname !== currentPortalRoute) {
        updateCurrentPortalRoute(router.pathname as PortalRoutes);
      }
    }
  }, [router.pathname]);

  /**
   * Navigate to a specific portal route
   */
  const navigateToPortalRoute = (route: PortalRoutes) => {
    updateCurrentPortalRoute(route);
    router.navigate(route);
  };

  return {
    router,
    currentPortalRoute,
    navigateToPortalRoute
  };
}; 