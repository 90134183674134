import { Grid } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import StatsCard from "./StatsCard";
import { CURRENCY } from "../../../../../config/constants";
import { dashboardStore } from "../../../../../app/stores/Dashboard";
import moment from "moment";

const StatsTilesRow = () => {
    const { avgOrderValue, totalOrders, totalSales } = dashboardStore(state => state);

    // Format data for display
    const formatAvgOrderValue = () => {
        return avgOrderValue?.avg_order_value
            ? CURRENCY + avgOrderValue.avg_order_value.toFixed(2)
            : CURRENCY + "0.00";
    };

    const formatTotalSales = () => {
        return totalSales !== null
            ? CURRENCY + totalSales.toFixed(2)
            : CURRENCY + "0.00";
    };

    const formatTotalOrders = () => {
        return totalOrders?.total_orders?.toString() || "0";
    };

    // Static props for items without real data yet
    const visitorStats = {
        title: "Total Visitors",
        value: "820",
        changePercentage: 8,
        changeValue: "120",
    };

    return (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={3} >
                <StatsCard
                    icon={<AttachMoneyIcon sx={{ color: "white", fontSize: 18 }} />}
                    title="Total Sales"
                    value={formatTotalSales()}
                    changePercentage={10}
                    changeValue={CURRENCY + "150"}
                    iconBgColor="#3366FF"
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <StatsCard
                    icon={<ShoppingCartIcon sx={{ color: "white", fontSize: 18 }} />}
                    title="Avg Order Value"
                    value={formatAvgOrderValue()}
                    changePercentage={-4}
                    changeValue={CURRENCY + "290"}
                    iconBgColor="#50B5FF"
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <StatsCard
                    icon={<PersonIcon sx={{ color: "white", fontSize: 18 }} />}
                    title={visitorStats.title}
                    value={"-"}
                    changePercentage={visitorStats.changePercentage}
                    changeValue={visitorStats.changeValue}
                    iconBgColor="#FF9066"
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <StatsCard
                    icon={<AssignmentReturnIcon sx={{ color: "white", fontSize: 18 }} />}
                    title={moment().format("MMMM") + " Orders"}
                    value={formatTotalOrders()}
                    changePercentage={-4}
                    changeValue="31"
                    iconBgColor="#FF6B6B"
                />
            </Grid>
        </Grid>
    );
};

export default StatsTilesRow; 