import { Divider } from "@mui/material";
import { BasicButton } from "./BasicButton";
import { StarRounded } from "@mui/icons-material";

export const BecomeMember = ({
    darkMode,
    onClick
}) => {
    return (
        <>
            <BasicButton
                title={"Sign up"}
                style={{
                    alignSelf: "center",
                    height: 35,
                    fontSize: 9,
                    letterSpacing: 0.5,
                }}
                startIcon={<StarRounded />}
                onClick={() => onClick && onClick()}
            />
            <Divider
                color={darkMode ? "white" : "black"}
                orientation="vertical"
                style={{
                    width: 2,
                    marginLeft: 14,
                    marginRight: 14,
                    height: 16,
                    alignSelf: "center",
                }}
            />
        </>
    )
}