import {
	DrawerContentScrollView,
	DrawerItem,
	createDrawerNavigator,
} from "@react-navigation/drawer";
import { View } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { cartStore } from "../../../app/stores/Cart";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { Header } from "../../Header";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { BecomeMember } from "../../BecomeMember";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { CartIcon } from "../../CartIcon";
import { ProfileIcon } from "../../ProfileIcon";
import { userStore } from "../../../app/stores/User";
import { primaryColor } from "../../../config/constants";
import { ThemeToggle } from "../../ThemeToggle";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { AppLogo } from "../../AppLogo";

const Drawer = createDrawerNavigator();

export const DrawerNavigation = () => {
	const theme = useTheme();
	const {
		splashLoaded,
		currentScreen,
		screenList,
		drawerItemList,
	} = navigationStore((state) => state);

	const { getScreenComponent, navigateToScreen } = useScreenComponent();
	const { updateDefaultRoute, showNavigationBar, updateCartDrawerOpen } = navigationStore((state) => state);
	const cartItems = cartStore((state) => state.cartItems);
	const { darkMode } = appConfigStore((state) => state);
	const { info, authenticated, role } = userStore((state) => state.user);

	const CustomDrawerContent = (props) => {
		return (
			<View style={{ flex: 1 }}>
				<DrawerContentScrollView {...props}>
					<Header enableDivider={false} showLogoText={true} />

					{
						drawerItemList.map((screen, index) => {
							let isCurrentScreen = currentScreen === screen;

							if (authenticated && screen === "Login")
								return null;

							return (
								<DrawerItem
									key={"item_" + index}
									label={screen.replaceAll("_", " ")}
									labelStyle={{
										paddingHorizontal: 16,
										fontFamily: "primaryFont",
										fontSize: 16,
										fontWeight: "600",
										color: isCurrentScreen
											? "white"
											: theme.palette.text.primary
									}}
									style={{
										backgroundColor: isCurrentScreen
											? primaryColor
											: theme.palette.background.paper
									}}
									onPress={() => navigateToScreen(screen)}
								/>
							);
						})
					}
				</DrawerContentScrollView>

				{/* Bottom Panel with Theme Toggle */}
				<Box sx={{
					borderTopWidth: 1,
					borderTopStyle: "solid",
					borderTopColor: "divider",
					paddingX: 2.5,
					paddingY: 2,
					backgroundColor: theme.palette.background.paper,
				}}>
					<ThemeToggle variant="drawer" />
				</Box>
			</View>
		);
	};

	return (
		<Drawer.Navigator
			useLegacyImplementation
			defaultStatus="closed"
			initialRouteName={currentScreen.toString()}
			drawerContent={(props) => <CustomDrawerContent {...props} />}
			screenOptions={{
				drawerType: "slide",
				headerShown: (!splashLoaded || !showNavigationBar) ? false : true,
				headerTintColor: theme.palette.text.primary,
				drawerStyle: {
					width: "60%",
					backgroundColor: theme.palette.background.paper,
				},
				headerStyle: {
					minHeight: 85,
					backgroundColor: theme.palette.background.paper,
					borderBottomWidth: 0,
					// @ts-ignore
					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
				},
				headerTitle: ({ children }) => (
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						<AppLogo showText={false} size={38} sx={{ marginTop: 0, marginRight: 1.5, marginLeft: -1.5 }} />
						<Typography
							variant="subtitle1"
							sx={{
								color: theme.palette.text.primary,
								fontWeight: 600
							}}
						>
							{children}
						</Typography>
					</View>
				),
				headerRight: () => {
					return (
						<View style={{ flexDirection: "row", marginRight: 7, gap: 14 }}>
							<ProfileIcon
								isAuthenticated={authenticated}
								firstName={info?.user_metadata?.firstName ?? ""}
								darkMode={darkMode}
								onClick={() => {
									updateDefaultRoute(DefaultRoute.Login);
									navigateToScreen(ScreenComponent.Login);
								}}
								role={role}
							/>
							<CartIcon />
						</View>
					)
				},
			}}
		>
			{
				screenList.map((screen, index) => {
				return (
					<Drawer.Screen
						key={"screen_" + index}
						name={screen.toString()}
						component={getScreenComponent(screen)}
						options={{
							title: screen.replaceAll("_", " ").toString(),
							drawerLabel: screen.replaceAll("_", " ").toString(),
						}}
					/>
				)
			})}
		</Drawer.Navigator>
	);
};
