import { BasicButton } from "./BasicButton";
import { Grid } from "@mui/material";

interface AutoLoadButtonProps {
    onLoadMore: () => void;
    isLoading: boolean;
    isVisible: boolean;
}

export const AutoLoadButton = ({ onLoadMore, isLoading, isVisible }: AutoLoadButtonProps) => {
    if (!isVisible) return null;

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
        >
            <Grid item>
                <BasicButton
                    title="Load More"
                    variant="contained"
                    onClick={onLoadMore}
                    isLoading={isLoading}
                    sx={{ minWidth: 200 }}
                />
            </Grid>
        </Grid>
    );
}; 