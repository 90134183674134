import { TableRow, TableCell, IconButton, Box, Typography, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import { OrderDetails } from "../../../../../models/DB";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { formatTimestamp, getDeliveryTypeSetup, getReferenceValue } from "../../../../../utils/customFunctions";
import { OrderEditor } from "./OrderEditor";
import { OrderStatusBadgeCompact } from "../../../../../components/OrderStatusBadge";
import { isLarge } from "../../../../../navigation/HomeStack";

interface OrderRowProps {
    order: OrderDetails;
    orders: OrderDetails[];
    setOrders: (orders: OrderDetails[]) => void;
}

export const OrderRow = ({ order, orders, setOrders }: OrderRowProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const IconToShow = () => {
        return open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    };

    const DeliverTypeBlock = ({ deliveryType }: { deliveryType: string }) => {
        const { backgroundColor, borderColor, textColor, text } = getDeliveryTypeSetup(deliveryType, theme, isLarge());

        return (
            <Grid
                item
                sx={{
                    display: "inline-block",
                    border: 1,
                    borderColor: borderColor,
                    backgroundColor: backgroundColor,
                    borderRadius: 2,
                    padding: 1.5,
                    paddingY: 0.5
                }}
            >
                <Typography
                    color={textColor}
                    fontSize={11}
                    fontWeight={"bold"}
                    letterSpacing={0.5}
                    textAlign={"center"}
                    fontFamily={"primaryFont"}
                >{`${text}`}</Typography>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": { borderBottom: "unset" },
                    backgroundColor: theme.palette.background.paper,
                    '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark'
                            ? theme.palette.action.hover
                            : theme.palette.grey[50]
                    }
                }}
            >
                <TableCell component="th" scope="order">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            size="small"
                            onClick={() => setOpen(!open)}
                            sx={{ flexDirection: 'column' }}
                        >
                            <IconToShow />
                        </IconButton>
                        <Typography variant="body2"
                            sx={{
                                marginLeft: 4,
                                fontSize: 12,
                                fontFamily: "primaryFont",
                                letterSpacing: 0.5,
                                textTransform: 'capitalize'
                            }}>
                            #{order.orderId}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <OrderStatusBadgeCompact status={order.status || 1} />
                </TableCell>
                <TableCell>
                    <DeliverTypeBlock deliveryType={order.deliveryType} />
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>{order.email}</TableCell>
                <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5, display: { xs: "none", md: "table-cell" } }}>
                    {order.totalCost ? `R ${order.totalCost.toFixed(2)}` : "N/A"}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5, display: { xs: "none", md: "table-cell" } }}>
                    {order.created ? formatTimestamp(order.created) : "N/A"}
                </TableCell>
            </TableRow>
            {
                <OrderEditor
                    order={order}
                    open={open}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    orders={orders}
                    setOrders={setOrders}
                />
            }
        </React.Fragment>
    );
};