import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import moment, { Moment } from "moment";
import { useState } from "react";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/models";
import { DateValidationError } from "@mui/x-date-pickers";

interface DashboardHeaderProps {
    userName?: string;
    onDateChange?: (date: Date) => void;
    initialDate?: Date;
    onAddProduct?: () => void;
    onChangeFilter?: (filter: string) => void;
}

const DashboardHeader = ({
    userName = "User",
    onDateChange,
    initialDate = new Date(),
    onAddProduct,
    onChangeFilter,
}: DashboardHeaderProps) => {
    const theme = useTheme();
    const [selectedDate, setSelectedDate] = useState<Moment | null>(moment(initialDate));

    const handleDateChange = (date: Moment | null, context?: PickerChangeHandlerContext<DateValidationError>) => {
        setSelectedDate(date);
        if (date && onDateChange) {
            onDateChange(date.toDate());
        }
    };

    return (
        <Box sx={{ mb: 4 }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-start", sm: "center" },
                    flexDirection: { xs: "column", sm: "row" },
                    mb: 1
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Typography textAlign="left" variant="h4" fontWeight="bold" fontFamily="primaryFont">
                        {`Welcome ${userName}`}
                    </Typography>
                    <Typography textAlign="left" variant="subtitle1" color="text.secondary" fontFamily="primaryFont">
                        {`Here's what happening with your store today`}
                    </Typography>
                </Grid>

                <Box sx={{
                    display: "flex",
                    mt: { xs: 2, sm: 0 },
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "flex-start", sm: "center" },
                    gap: { xs: 2, sm: 2 }
                }}>
                    <Typography textAlign="left" variant="subtitle1" color="text.secondary" fontFamily="primaryFont">
                        {`Filter by date`}
                    </Typography>
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"

                        slotProps={{
                            textField: {
                                size: "medium",
                                fullWidth: false,
                                sx: {
                                    minWidth: "150px",
                                    backgroundColor: theme.customColors.cardBackground,
                                    fontFamily: "primaryFont",
                                    fontSize: "0.875rem",
                                    "& .MuiInputBase-root": {
                                        fontFamily: "primaryFont",
                                        fontSize: "0.875rem"
                                    }
                                },
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardHeader; 