import { userStore } from "../../app/stores/User";
import { Portal } from "./Portal";
import { PortalAuthStack } from "./Auth/PortalAuthStack";
import { RouteProp } from "@react-navigation/native";
import { ParamListBase } from "@react-navigation/routers";

export const PortalRoutes = ({ route }: { route: RouteProp<ParamListBase, string> }) => {
    const { authenticated } = userStore(state => state.user);

    return (
        authenticated ?
            <Portal route={route} />
            :
            <PortalAuthStack />
    );
};