/**
 * Enum representing the different order statuses
 */
export enum OrderStatus {
    PENDING = 1,
    PROCESSING = 2,
    SHIPPED = 3,
    DELIVERED = 4
}

/**
 * Get the display text for an order status
 * @param status - The status code
 * @param compact - Whether to return a compact version of the status text
 * @returns The display text for the status
 */
export const getOrderStatusText = (status: OrderStatus, compact = false): string => {
    switch (status) {
        case OrderStatus.PENDING:
            return compact ? "Pending" : "Pending";
        case OrderStatus.PROCESSING:
            return compact ? "Processing" : "Processing";
        case OrderStatus.SHIPPED:
            return compact ? "Shipped" : "Out for Delivery";
        case OrderStatus.DELIVERED:
            return compact ? "Delivered" : "Delivered";
        default:
            return "Unknown";
    }
};

/**
 * Get the color for an order status
 * @param status - The status code
 * @param theme - The theme object from MUI
 * @returns The color for the status
 */
export const getOrderStatusColor = (status: OrderStatus, theme: any): string => {
    switch (status) {
        case OrderStatus.PENDING:
            return theme.palette.grey[500];
        case OrderStatus.PROCESSING:
            return theme.palette.warning.main;
        case OrderStatus.SHIPPED:
            return theme.palette.primary.main;
        case OrderStatus.DELIVERED:
            return theme.palette.success.main;
        default:
            return theme.palette.grey[500];
    }
}; 