import React, { useRef, useState, useEffect } from "react";
import { TextField, TextFieldProps as MuiTextFieldProps, SxProps, Theme } from "@mui/material";
import { primaryColor } from "../config/constants";
import { Platform, TextInput } from "react-native";

export interface BasicTextFieldProps extends Omit<MuiTextFieldProps, 'onChange'> {
	numberOnly?: boolean;
	onChange?: MuiTextFieldProps['onChange'];
}

const BasicTextFieldComponent: React.FC<BasicTextFieldProps> = ({
	numberOnly,
	onChange,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement | TextInput>(null);
	const [internalValue, setInternalValue] = useState<string>(props.value as string || "");
	const [isFocused, setIsFocused] = useState(false);

	// Keep internalValue in sync with external value if controlled
	useEffect(() => {
		if (props.value !== undefined) {
			setInternalValue(props.value as string);

			// If we're currently focused, make sure we maintain focus
			if (isFocused && Platform.OS !== "web") {
				setTimeout(() => {
					if (inputRef.current) {
						// @ts-ignore - Handle both web and RN refs
						if (Platform.OS === "ios" || Platform.OS === "android") {
							(inputRef.current as TextInput).focus();
						} else {
							(inputRef.current as HTMLInputElement).focus();
						}
					}
				}, 0);
			}
		}
	}, [props.value, isFocused]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (numberOnly) {
			// Allow only numbers, backspace, delete, tab, enter, decimal point
			const allowedKeys = ["Backspace", "Delete", "Tab", "Enter", ".", "ArrowLeft", "ArrowRight"];
			const isNumber = /[0-9]/.test(event.key);

			// For stockOnHand, don't allow decimal point
			if (props.name === "stockOnHand" && event.key === ".") {
				event.preventDefault();
				return;
			}

			if (!isNumber && !allowedKeys.includes(event.key)) {
				event.preventDefault();
			}
		}
	};

	// Handle our own onChange to update internal state
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setInternalValue(newValue);

		// Call external onChange if provided
		if (onChange) {
			onChange(event);
		}
	};

	// Handle focus events carefully
	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);

		// Combine with any existing onFocus handler
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	// Handle blur events carefully to prevent value clearing
	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(false);

		// Store current value to prevent loss
		const currentValue = event.target.value;

		// Call external onBlur if provided
		if (props.onBlur) {
			// We need to preserve the value through the blur event
			const preservedEvent = {
				...event,
				target: {
					...event.target,
					value: currentValue
				}
			};
			props.onBlur(preservedEvent);
		}
	};

	// Only apply custom autofill styling if primaryColor is not black
	const autofillStyles: SxProps<Theme> = {
		"& .MuiInputBase-input:-webkit-autofill": {
			WebkitBoxShadow: `0 0 0 100px ${primaryColor}25 inset !important`,
			WebkitTextFillColor: props.inputProps?.style?.color || "inherit",
			caretColor: props.inputProps?.style?.color || "inherit",
		}
	};

	// Combine all styles properly
	const combinedStyles: SxProps<Theme> = {
		"& .MuiInputLabel-root": {
			color: "grey",
		},
		"& .MuiInputLabel-root.Mui-focused": {
			color: "grey",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "grey",
			},
			"&.Mui-focused fieldset": {
				borderColor: "grey",
			},
		},
		...(props.sx as object || {}),
		...autofillStyles,
	};

	// Check if the component is controlled (has a value prop)
	const isControlled = props.value !== undefined;

	return (
		<TextField
			{...props}
			value={isControlled ? props.value : internalValue}
			onChange={handleChange}
			onKeyPress={handleKeyPress}
			onFocus={handleFocus}
			onBlur={handleBlur}
			inputRef={inputRef}
			sx={combinedStyles}
		/>
	);
};

// Apply React.memo to prevent unnecessary re-renders
export const BasicTextField = React.memo(BasicTextFieldComponent);
