import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ContactDetails } from "../../../models/DB";
import { Box, useTheme } from "@mui/material";

interface ContactFormProps {
	ref: React.Ref<any>;
}

export const ContactForm = forwardRef(({ }: ContactFormProps, ref) => {
	const theme = useTheme();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNo, setContactNo] = useState("");

	// Error states
	const [errors, setErrors] = useState({
		firstName: false,
		lastName: false,
		email: false,
		contactNo: false
	});

	useImperativeHandle(ref, () => ({
		getFormData: () => {
			// Validate all fields
			const newErrors = {
				firstName: !firstName,
				lastName: !lastName,
				email: !email,
				contactNo: !contactNo
			};

			setErrors(newErrors);

			// Check if there are any errors
			if (Object.values(newErrors).some(error => error)) {
				return null;
			}

			const details: ContactDetails = {
				firstName,
				lastName,
				email,
				contactNo,
			};
			return details;
		}
	}));

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(event.target.value);
		setErrors({ ...errors, firstName: false });
	};

	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(event.target.value);
		setErrors({ ...errors, lastName: false });
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setErrors({ ...errors, email: false });
	};

	const handleContactNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContactNo(event.target.value);
		setErrors({ ...errors, contactNo: false });
	};

	return (
		<Box>
			<Typography
				variant="h6"
				gutterBottom
				mt={2}
				mb={4}
				textAlign="center"
				color={theme.palette.text.primary}
			>
				Contact Details
			</Typography>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={12}
				>
					<TextField
						required
						fullWidth
						id="firstName"
						label="First Name"
						variant="outlined"
						autoComplete="given-name"
						onChange={handleFirstNameChange}
						error={errors.firstName}
						helperText={errors.firstName ? "First name is required" : ""}
						value={firstName}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<TextField
						required
						fullWidth
						id="lastName"
						label="Last Name"
						variant="outlined"
						autoComplete="family-name"
						onChange={handleLastNameChange}
						error={errors.lastName}
						helperText={errors.lastName ? "Last name is required" : ""}
						value={lastName}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<TextField
						required
						fullWidth
						id="email"
						label="Email Address"
						variant="outlined"
						autoComplete="email"
						onChange={handleEmailChange}
						error={errors.email}
						helperText={errors.email ? "Email is required" : ""}
						value={email}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<TextField
						required
						fullWidth
						id="contactNo"
						label="Contact Number"
						variant="outlined"
						autoComplete="tel"
						onChange={handleContactNoChange}
						error={errors.contactNo}
						helperText={errors.contactNo ? "Contact number is required" : ""}
						value={contactNo}
						InputLabelProps={{
							style: { color: theme.palette.text.secondary }
						}}
						InputProps={{
							style: { color: theme.palette.text.primary }
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
});
