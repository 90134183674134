import { Grid, Box, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState, useRef, useCallback, useMemo } from "react";
import { cartStore } from "../../../app/stores/Cart";
import { ContactDetails, DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import OrdersService from "../../../services/supabase/OrdersService";
import { BasicButton } from "../../../components/BasicButton";
import { ContactForm } from "./ContactForm";
import { Review } from "./Review";
import { ShippingForm } from "./ShippingForm";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { userStore } from "../../../app/stores/User";
import { navigationStore } from "../../../app/stores/Navigation";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { primaryColor } from "../../../config/constants";
import { isLarge } from "../../../navigation/HomeStack";

// Define interfaces for the form refs
interface ShippingFormRef {
	getFormData: () => Promise<(ShippingDetails & DeliveryDetails) | null>;
}

interface ContactFormRef {
	getFormData: () => Promise<ContactDetails | null>;
}

const CheckoutComponent = ({ cartItems, onComplete, onReset }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(1);
	const [invoiceId, setInvoiceId] = useState<string>("");
	const [contactDetails, setContactDetails] = useState<ContactDetails>(
		{
			firstName: "",
			lastName: "",
			email: "",
			contactNo: "",
		}
	);
	const [shippingDetails, setShippingDetails] = useState<ShippingDetails & DeliveryDetails>(
		{
			addressLine1: "",
			addressLine2: "",
			country: "",
			city: "",
			region: "",
			zip: "",
			deliveryType: "",
			deliveryFee: 0,
			suburb: "",
		}
	);
	const [loading, setLoading] = useState(false);
	const clearCart = cartStore((state) => state.clearCart);
	const { updateShowNavigationBar, updateDefaultRoute } = navigationStore((state) => state);
	const { user: { info: { email } } } = userStore((state) => state);
	const steps = ["Shipping Address", "Contact Details", "Review your order"];
	const { createOrder } = OrdersService();
	const { navigateToScreen } = useScreenComponent();

	// References for form submission
	const shippingFormRef = useRef<ShippingFormRef>(null);
	const contactFormRef = useRef<ContactFormRef>(null);
	const reviewFormRef = useRef(null);

	const userEmail = email ?? contactDetails?.email;

	const confirmOrder = useCallback(async () => {
		try {
			setLoading(true);
			if (!userEmail) {
				console.log("Email is required");
				updateShowNavigationBar(true);
				updateDefaultRoute(DefaultRoute.Login);
				navigateToScreen(ScreenComponent.Login);
				setLoading(false);
				return;
			}

			const invoiceId = await createOrder(
				cartItems,
				contactDetails,
				shippingDetails,
				userEmail
			);

			setInvoiceId(invoiceId);
			setActiveStep(activeStep + 1);
			setLoading(false);
		} catch (error) {
			console.error("Error confirming order:", error);
			setLoading(false);
		}
	}, [cartItems, contactDetails, shippingDetails, userEmail, activeStep, createOrder, navigateToScreen, updateDefaultRoute, updateShowNavigationBar]);

	const handleBack = useCallback(() => {
		if (activeStep === 1) {
			onReset();
		} else {
			setActiveStep(activeStep - 1);
		}
	}, [activeStep, onReset]);

	const handleNext = useCallback(async () => {
		// Trigger form validation based on current step
		if (activeStep === 1 && shippingFormRef.current) {
			const details = await shippingFormRef.current.getFormData();
			if (details) {
				setShippingDetails(details as ShippingDetails & DeliveryDetails);
				setActiveStep(activeStep + 1);
			}
		} else if (activeStep === 2 && contactFormRef.current) {
			const details = await contactFormRef.current.getFormData();
			if (details) {
				setContactDetails(details);
				setActiveStep(activeStep + 1);
			}
		} else if (activeStep === 3) {
			await confirmOrder();
		}
	}, [activeStep, confirmOrder]);

	const handleContinueShopping = useCallback(() => {
		clearCart();
		navigateToScreen(ScreenComponent.Shop);
		if (onComplete) onComplete();
	}, [clearCart, navigateToScreen, onComplete]);

	const ThankYou = useMemo(() => {
		return (
			<Fragment>
				<Typography
					variant="h5"
					gutterBottom
					textAlign="center"
					color={theme.palette.text.primary}
				>
					Thank you for your order.
				</Typography>
				<Typography
					variant="subtitle1"
					fontWeight={600}
					textAlign="center"
					color={theme.palette.text.primary}
				>
					{`Your order number is #${invoiceId}`}
				</Typography>
				<Typography
					variant="subtitle2"
					textAlign="center"
					color={theme.palette.text.secondary}
				>
					{`We have emailed your order confirmation, and will send you an update when your order has shipped.`}
				</Typography>
				<Grid
					container
					justifyContent="center"
					mt={4}
					padding={2}
				>
					<BasicButton
						sx={{ flex: 0.5, height: 45, minHeight: 45 }}
						title="Continue Shopping"
						onClick={handleContinueShopping}
					/>
				</Grid>
			</Fragment>
		);
	}, [invoiceId, theme, handleContinueShopping]);

	const Form = useMemo(() => {
		switch (activeStep) {
			case 1:
				return (
					<ShippingForm ref={shippingFormRef} />
				);
			case 2:
				return (
					<ContactForm ref={contactFormRef} />
				);
			case 3:
				return (
					<Review
						shippingDetails={shippingDetails}
						contactDetails={contactDetails}
						ref={reviewFormRef}
					/>
				);
			case 4:
				return ThankYou;
			default:
				return null;
		}
	}, [activeStep, shippingDetails, contactDetails, ThankYou]);

	// Determine button text and visibility based on active step
	const nextButtonText = activeStep === 3 ? "Confirm Order" : "Next";
	const showBackButton = activeStep < 4;
	const showNextButton = activeStep < 4;
	const backButtonText = activeStep === 1 ? "Cancel" : "Back";

	// Use the isLarge function to determine responsiveness
	const largeScreen = isLarge();

	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
				backgroundColor: theme.customColors.background
			}}
		>
			<Container
				component="main"
				maxWidth="sm"
				sx={{ 
					display: "flex",
					flexDirection: "column",
					height: "100%",
					overflow: "hidden",
					pt: 3,
					pb: showBackButton || showNextButton ? 14 : 2
				}}
			>
				{/* Fixed header with title and stepper */}
				<Box sx={{ mb: 2 }}>
					<Typography
						component="h1"
						variant="h4"
						align="center"
						color={theme.palette.text.primary}
					>
						Checkout
					</Typography>
					<Stepper
						activeStep={activeStep - 1}
						sx={{
							py: 3,
							// completed icon color
							"& .MuiStepIcon-root.Mui-completed": {
								color: primaryColor,
							},
							// active icon color
							"& .MuiStepIcon-root.Mui-active": {
								color: primaryColor,
							},
							// Stepper label text color
							"& .MuiStepLabel-label": {
								color: theme.palette.text.primary,
							}
						}}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>

				{/* Scrollable form area */}
				<Box
					sx={{
						flex: 1,
						overflow: "auto",
						p: 1
					}}
				>
					{Form}
				</Box>
			</Container>

			{/* Sticky footer with navigation buttons */}
			{(showBackButton || showNextButton) && (
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						padding: 2,
						px: 4,
						backgroundColor: theme.customColors.cardBackground,
						zIndex: 5,
						display: "flex",
						justifyContent: "space-between",
						marginLeft: "auto",
						marginRight: "auto"
					}}
				>
					{showBackButton && (
						<BasicButton
							title={backButtonText}
							variant="outlined"
							onClick={handleBack}
							disabled={loading}
						/>
					)}
					{showNextButton && (
						<BasicButton
							title={nextButtonText}
							variant="contained"
							onClick={handleNext}
							isLoading={loading}
						/>
					)}
				</Box>
			)}
		</Box>
	);
};

// Export memoized component
export const Checkout = React.memo(CheckoutComponent);
