import { IconButton, Typography } from "@mui/material";
import { Image, Animated } from "react-native";
import { cartStore } from "../app/stores/Cart";
import { navigationStore } from "../app/stores/Navigation";
import { appConfigStore } from "../app/stores/AppConfig";
import { useEffect, useRef } from "react";

export const CartIcon = () => {
    const cartItems = cartStore((state) => state.cartItems);
    const darkMode = appConfigStore((state) => state.darkMode);
    const { updateCartDrawerOpen } = navigationStore((state) => state);

    // Animation values
    const scaleAnim = useRef(new Animated.Value(1)).current;
    const colorAnim = useRef(new Animated.Value(0)).current;

    // Previous cart items count to detect changes
    const prevCountRef = useRef(cartItems.length);

    // Run animation when cart items change
    useEffect(() => {
        // Only animate when cart count increases
        if (cartItems.length > prevCountRef.current) {
            // Reset animation values
            scaleAnim.setValue(1);
            colorAnim.setValue(0);

            // Run grow animation
            Animated.sequence([
                // Scale up
                Animated.timing(scaleAnim, {
                    toValue: 1.1,
                    duration: 150,
                    useNativeDriver: true,
                }),
                // Scale back down
                Animated.timing(scaleAnim, {
                    toValue: 1,
                    duration: 150,
                    useNativeDriver: true,
                })
            ]).start();

            // Run color animation
            Animated.sequence([
                // Glow red
                Animated.timing(colorAnim, {
                    toValue: 1,
                    duration: 200,
                    useNativeDriver: false,
                }),
                // Fade back
                Animated.timing(colorAnim, {
                    toValue: 0,
                    delay: 100,
                    duration: 300,
                    useNativeDriver: false,
                })
            ]).start();
        }

        // Update previous count
        prevCountRef.current = cartItems.length;
    }, [cartItems.length]);

    // Interpolate color animation
    const backgroundColor = colorAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [darkMode ? "#333333" : "#f1f1f1", "#ffdddd"]
    });

    // Interpolate text color animation
    const textColor = colorAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [darkMode ? "white" : "black", "#ff0000"]
    });

    // Set the default icon color based on dark mode
    const defaultIconColor = darkMode ? "white" : "black";

    return (
        <Animated.View
            style={{
                borderRadius: 18,
                height: 36,
                transform: [{ scale: scaleAnim }],
                backgroundColor,
                marginRight: 1,
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
            }}
        >
            <IconButton
                sx={{
                    paddingX: 2,
                    color: defaultIconColor
                }}
                onClick={() => {
                    if (cartItems.length > 0) {
                        updateCartDrawerOpen(true);
                    }
                }}
            >
                <Image
                    style={{
                        resizeMode: "contain",
                        width: 20,
                        height: 20,
                        tintColor: defaultIconColor,
                    }}
                    source={require("../assets/cart-icon.png")}
                />
                <Typography
                    variant="body2"
                    sx={{
                        color: defaultIconColor,
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "primaryFont",
                        marginLeft: "4px",
                        letterSpacing: 0.5,
                    }}
                >
                    {cartItems && cartItems.length}
                </Typography>
            </IconButton>
        </Animated.View>
    );
};